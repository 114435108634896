import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { InStockOrderItem } from '@app/models';
import { IShippingRequestStrings, Strings } from '@app/modules/shipping-request/shipping-request.string';
import {
  setHasOldSalesOrderAlert,
  setOpenShippingRequestSuggestionModal,
  setTableRows,
} from '@app/modules/shipping-request/store/shipping-request.store';
import { Button } from '@atomic/atm.button';
import { FlexRow } from '@atomic/obj.box';

export const ShippingRequestSuggestionModalFooter: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { data } = useSelector((state: RootState) => state.orderTabStock);
  const { suggestionOptions } = useSelector((state: RootState) => state.shippingRequest);
  const strings: IShippingRequestStrings = Strings[userInfo.language];
  const dispatch = useDispatch();

  const handleConfirm = () => {
    const selectedOptionRow = [];

    if (suggestionOptions.length === 1) {
      selectedOptionRow.push(suggestionOptions[0].item);
    } else {
      suggestionOptions
        ?.filter(option => option.selected)
        .forEach(option => {
          selectedOptionRow.push(option.item);
        });
    }

    const selectedInStockOrderItem: InStockOrderItem[] = [];

    selectedOptionRow.forEach(row => {
      data.find(item => {
        if (item.salesDocument === row.salesOrder) {
          selectedInStockOrderItem.push(item);
        }
      });
    });

    dispatch(setHasOldSalesOrderAlert(false));
    dispatch(setTableRows({ selectedRows: selectedInStockOrderItem, isGKN: false }));
    dispatch(setOpenShippingRequestSuggestionModal(null));
  };

  const handleDisabled = () => {
    let isDisabled = true;

    if (suggestionOptions.length === 1 || suggestionOptions[0]?.selected) {
      isDisabled = false;
    }

    return isDisabled;
  };

  return (
    <FlexRow pb pr pt hAlign='flex-end'>
      <Button disabled={handleDisabled()} onClick={() => handleConfirm()}>
        <FlexRow>{strings.suggestionModal.confirmButton}</FlexRow>
      </Button>
      <Button kind='secondary' onClick={() => dispatch(setOpenShippingRequestSuggestionModal(null))}>
        {strings.suggestionModal.cancelButton}
      </Button>
    </FlexRow>
  );
};
