export interface Tab {
  id: string;
  tag: string;
}

export interface IAdminUserList {
  users: IAdminUser[];
  pagination: IAdminPagination;
}

export interface IAdminUser {
  _id: string;
  oktaUid: string;
  name: string;
  email: string;
  created: {
    by: {
      name: string;
      email: string;
    };
    at: {
      date: string;
      dateFormat: string;
    };
  };
  lastLogin: {
    date: string;
    dateFormat: string;
  };
  profile: string;
  tabs: string[];
  clients: [
    {
      name: string;
      type: string;
      bp: string;
    },
  ];
}

export interface IAdminPagination {
  count: number;
  limit: number;
  skip: number;
}

export interface IAdminBPList {
  bps: IAdminBP[];
  pagination: IAdminPagination;
}

export interface IAdminBPUser {
  _id: string;
  oktaUid: string;
  name: string;
  email: string;
  profile: string;
  lastLogin: {
    date: string;
    dateFormat: string;
  };
  created: {
    by: {
      name: string;
      email: string;
    };
    at: {
      date: string;
      dateFormat: string;
    };
  };
}

export interface IAdminBP {
  _id: string;
  name: string;
  type: string;
  bp: string;
  users: IAdminBPUser[];
  corporateGroup: string;
  corporateGroupName: string;
}

export interface IRole {
  _id?: string;
  profile: string;
  tabs: Tab[];
}

export interface Admin {
  userFilterOption: string;
  userTextSearch: string;
  bpFilterOption: string;
  bpTextSearch: string;
  isEditing: boolean;
  users: any[];
  bps: any[];
  roles: IRole[];
}

export interface IAdminTab {
  _id: string;
  description: string;
  name: string;
  tag: string;
  order?: number;
}

export interface IBpOption {
  code: string;
  name: string;
  id?: string;
  selected?: boolean;
}

export interface IFormBpOption {
  code: string;
  name: string;
  id?: string;
  corpGroup: string;
  corpGroupName: string;
  selected?: boolean;
}

export interface ISelectOption {
  value: string;
  label: string;
}

export enum AdminUserFilterOptionsId {
  Name = 'name',
  Id = 'uid',
  Mail = 'email',
  Profile = 'profile',
  Tab = 'tab',
}

export enum AdminUserFilterOptionsLabel {
  Name = 'Nome',
  Id = 'ID',
  Mail = 'Email',
  Profile = 'Perfil',
  Tab = 'Abas',
}

export enum AdminBPsFilterOptionsId {
  Name = 'name',
  Bp = 'bp',
  CorpGroup = 'corpgroup',
  SalesOrg = 'salesorg',
}

export enum AdminBPsFilterOptionsLabel {
  Name = 'Nome',
  Bp = 'BP',
  CorpGroup = 'CorpGroup',
  SalesOrg = 'Organização de Vendas',
}

export enum LanguageOptionsId {
  Portuguese = 'por',
  English = 'eng',
  Spanish = 'spa',
}

export enum LanguageOptionsLabel {
  Portuguese = 'Português',
  English = 'Inglês',
  Spanish = 'Espanhol',
}

export enum ProfileTypesId {
  Client = 'client',
  Epp = 'epp',
  Ped = 'ped',
  Rtc = 'rtc',
  Gq = 'gq',
  Admin = 'admin',
  Seller = 'seller',
  Scm = 'scm',
  Squad = 'squad',
}

export enum ProfileTypes {
  Client = 'Cliente',
  Epp = 'EPP',
  Ped = 'P&D',
  Rtc = 'RTC',
  Gq = 'GQ',
  Admin = 'Admin',
  Seller = 'Vendedor',
  Scm = 'Supply Chain',
  Squad = 'Squad',
}

export enum RoleInternalName {
  Epp = 'epp',
  Ped = 'ped',
  Rtc = 'rtc',
  Gq = 'gq',
  Admin = 'admin',
  Seller = 'seller',
  Scm = 'scm',
  Squad = 'squad',
}

export enum RoleDisplayName {
  Epp = 'EPP',
  Ped = 'P&D',
  Rtc = 'RTC',
  Gq = 'GQ',
  Admin = 'Admin',
  Seller = 'Seller',
  Scm = 'Supply Chain',
  Squad = 'Squad',
}

export enum TabInternalName {
  Order = 'carteira',
  Finance = 'financas',
  Quotation = 'cotacao',
  Manifest = 'manifestacao',
  IsaManager = 'isa-manager',
  Dashboard = 'painel-de-gestao',
  OrderInput = 'implantacao',
  Crm = 'crm',
  Surrogate = 'surrogate',
  LackOfLoad = 'lack-of-load',
  SchedulingCompleted = 'scheduling-completed',
}

export enum TabDisplayName {
  Order = 'Carteira',
  Finance = 'Finanças',
  Quotation = 'Cotação',
  Manifest = 'Manifestação',
  IsaManager = 'ISA Manager',
  Dashboard = 'Painel de Gestao',
  OrderInput = 'Implantação',
  Crm = 'CRM',
  Surrogate = 'Surrogate',
  LackOfLoad = 'Falta de carga',
  SchedulingCompleted = 'Aprazamento concluído',
}

export enum ExcelType {
  Users = 'users',
  Bps = 'bps',
}
