import * as React from 'react';
import { Button } from '@atomic/atm.button';
import { TextField } from '@atomic/atm.text-field';
import { BodySecondary, H1, InputLabel } from '@atomic/atm.typography';
import { Form, Validators } from '@atomic/obj.form';
import { VSeparator } from '@atomic/obj.grid';
import { AuthStrings } from '../../auth.string';

interface ForgotPasswordFormProps {
  onSubmit: (email: string) => void;
  disabled: boolean;
  loading: boolean;
}

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = props => {
  const strings = AuthStrings.pt;

  const handleSubmit = formData => {
    if (!(Object.keys(formData.error).length > 0)) {
      props.onSubmit(formData.data.email);
    }
  };

  return (
    <>
      <H1>Esqueci minha senha</H1>
      <BodySecondary>Informe seu e-mail do sistema Gerdau Mais</BodySecondary>
      <VSeparator />

      <Form onSubmit={handleSubmit}>
        <InputLabel>Email</InputLabel>
        <VSeparator small />

        <Form.Field
          name={'email'}
          validators={[Validators.EmailRegex('Email inválido'), Validators.Required('Obrigatório')]}
        >
          <TextField type='email' placeholder='Digite seu email' />
        </Form.Field>
        <VSeparator />

        {props.children}

        <Button type='submit' disabled={props.disabled} loading={props.loading}>
          Enviar
        </Button>
      </Form>
      <VSeparator />

      <BodySecondary>{`© Gerdau ${new Date().getFullYear()} ${strings.copyright}`}</BodySecondary>
    </>
  );
};
