import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { AdminConfirmModal } from './partials/admin-confirm-modal/admin-confirm-modal.component';
import { AdminDeleteModal } from './partials/admin-delete-modal/admin-delete-modal.component';
import { AdminTabs } from './partials/admin-tabs/admin-tabs.component';
import { setBpOptions } from './store/admin.store';
import { getBPs } from './store/thunk/bps-get.thunk';
import { getRoles } from './store/thunk/profile-get.thunk';
import { getTabs } from './store/thunk/tabs-get.thunk';
import { getUsers } from './store/thunk/users-get.thunk';
import { LIMIT_ITEMS_PER_PAGE } from './utils/utils';

export const AdminPage: React.FC = () => {
  const { showConfirmModal, isEditing, showDeleteModal } = useSelector((state: RootState) => state.admin);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppThunkDispatch>();

  const getAllQueryObj = {
    skip: 0,
    limit: LIMIT_ITEMS_PER_PAGE,
    filterType: '',
    filterValue: '',
  };

  useEffect(() => {
    dispatch(getUsers(getAllQueryObj));
    dispatch(getBPs(getAllQueryObj));
    dispatch(getTabs());
    dispatch(getRoles());
    dispatch(setBpOptions(userInfo.bps));
  }, []);

  return (
    <>
      <AdminTabs />
      {showConfirmModal && isEditing && <AdminConfirmModal />}
      {showDeleteModal && <AdminDeleteModal />}
    </>
  );
};
