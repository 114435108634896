import { IMultipleMaterialParams, MaterialParams } from '@app/data/http';
import { IMultipleValidation } from '@app/data/http/order-input.dto';
import { Customer, Material } from '@app/models';
import { CustomerSelection } from '@app/providers';
import { dateParser } from '@app/utils/date-parser';
import { parseFloatComma } from '@app/utils/number-utils';
import { formatQtyField } from '@app/utils/string-utils';
import {
  HANDLE_REGEX_NUMBER,
  MUSASHI_CUSTOMER_ID,
  MUSASHI_REPLACE_CUSTOMER_ID,
  MaterialUnity,
  SearchByMaterial,
} from './order-input.constants';
import { isM2DateRule } from './phases/order-input-amount/partials/amount-table/utils';

export const mapMaterialToMaterialsParams = (selectedMaterials: Material[]): MaterialParams[] => {
  const materialsParams: MaterialParams[] = selectedMaterials.map(
    (material: Material): MaterialParams => ({
      orderItemRef: material.orderItem,
      material: material.materialName,
      materialDesctiption: material.materialDescription,
      materialClientNumber: material.materialCode,
      weight: material.multipleValidation ? material.multipleValidation.quantity : material.amount,
      UM: material.unity,
      shippingDate: dateParser({ unformattedDate: new Date(material.date).toString(), hideHours: true, utc: true }),
      orderNumber: material.orderNumber,
      isM2Date: isM2DateRule(new Date(material.date)),
    }),
  );

  return materialsParams;
};

export const handleNumberInput = () => {
  const numberInputElements = document.getElementsByClassName('numberInputBox');

  if (numberInputElements?.length) {
    Array.from(numberInputElements).forEach((numberInputElement: HTMLInputElement) => {
      setInputFilter(numberInputElement, (value: string) => HANDLE_REGEX_NUMBER.test(value));
    });
  }
};

export const setInputFilter = (textbox: HTMLInputElement, inputFilter: (value: string) => boolean) => {
  const events = ['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop', 'focusout'];

  events.forEach(event => {
    textbox.addEventListener(event, e => {
      let oldValue;
      let oldSelectionStart;
      let oldSelectionEnd;

      if (inputFilter(textbox.value)) {
        if (['keydown', 'mousedown', 'focusout'].indexOf(e.type) >= 0) {
          textbox.setCustomValidity('');
        }

        oldValue = textbox.value;
        oldSelectionStart = textbox.selectionStart;
        oldSelectionEnd = textbox.selectionEnd;
      } else if (oldValue) {
        textbox.reportValidity();
        textbox.value = oldValue;
        textbox.setSelectionRange(oldSelectionStart, oldSelectionEnd);
      } else {
        textbox.value = '';
      }
    });
  });
};

export const formatMaterialDescription = (material: Material, searchBy: string): string => {
  if (searchBy === SearchByMaterial.CUSTOMER_MATERIAL_CODE) {
    return formatMaterialDescriptionByCode(material);
  } else {
    return formatMaterialDescriptionByText(material);
  }
};

export const formatMaterialDescriptionByCode = (material: Material): string => {
  const { materialCode, materialDescription, minMaxLenght, multipleLength, standardGrade } = material;
  const emptyValue = ' N/A ';

  const concatMaterialFields = `${materialCode ? materialCode : emptyValue} - ${
    materialDescription ? materialDescription : emptyValue
  } /
  ${minMaxLenght ? minMaxLenght + ' mm' : emptyValue} /
  ${multipleLength ? multipleLength + ' mm' : emptyValue} /
  ${standardGrade ? standardGrade : emptyValue}`;

  return concatMaterialFields;
};

export const formatMaterialDescriptionByText = (material: Material): string => {
  const { materialDescription, minMaxLenght, multipleLength, standardGrade } = material;
  const emptyValue = ' N/A ';

  const concatMaterialFields = `${materialDescription ? materialDescription : emptyValue} /
  ${minMaxLenght ? minMaxLenght + ' mm' : emptyValue} /
  ${multipleLength ? multipleLength + ' mm' : emptyValue} /
  ${standardGrade ? standardGrade : emptyValue}`;

  return concatMaterialFields;
};

export const filterAvailableMaterialsForMultiple = (materials: Material[]): Material[] => {
  const availableMaterialsForMultiple = materials.filter((material: Material) => {
    const hasMoreThanOneMaterialCode = material.materialCode?.indexOf('/') !== -1;
    const hasMoreThanOnePlant = material?.plant?.indexOf('/') !== -1;
    const hasPlant = !!material?.plant?.length;

    if (hasMoreThanOneMaterialCode || !hasPlant || hasMoreThanOnePlant) {
      return false;
    }

    return true;
  });

  return availableMaterialsForMultiple;
};

export const mapMaterialsToMultipleParams = (materials: Material[]): IMultipleMaterialParams[] => {
  return materials.map((material: Material) => ({
    material: material.materialName,
    quantity: material.amount,
    unitOfMeasure: material.unity,
    plant: material.plant,
  }));
};

export const fillMaterialsValidation = (
  materials: Material[],
  multipleValidations: IMultipleValidation[],
): Material[] => {
  return materials.map((material: Material) => {
    const multiple: IMultipleValidation = getMultiple(material, multipleValidations);
    if (multiple) {
      return fillSuggestMultipleValue(material, multiple);
    }
    return material;
  });
};

export const formatMaterialAmount = amount => {
  let result = amount;

  if (amount) {
    if (amount.toString().indexOf('.') > -1) {
      result = amount.replace('.', ',');
    }
  }

  return result;
};

export const getBpsReplacingMusashi = (
  customers: Customer[] = [],
  clientBpsSelected: CustomerSelection[] = [],
): CustomerSelection[] => {
  const bps: CustomerSelection[] = [...clientBpsSelected];

  const index = clientBpsSelected.findIndex(bp => bp.customerId === MUSASHI_CUSTOMER_ID);

  if (index !== -1) {
    const customer = customers.find(bp => bp.customerId === MUSASHI_REPLACE_CUSTOMER_ID);
    bps[index] = customer;
  }
  return bps;
};

export const getMultiple = (material: Material, multipleValidations: IMultipleValidation[]): IMultipleValidation => {
  const multiple: IMultipleValidation = multipleValidations.find(
    multipleValidation =>
      material.materialName === multipleValidation.material &&
      parseFloatComma(material.amount) === parseFloatComma(multipleValidation.quantity) &&
      (multipleValidation.quantityMultipleDown !== '0,0' || multipleValidation.quantityMultipleUp !== '0,0') &&
      multipleValidation.plant !== '' &&
      multipleValidation.material?.indexOf('/') === -1,
  );

  return multiple;
};

export const fillSuggestMultipleValue = (material: Material, multiple: IMultipleValidation): Material => {
  const floatAmount: number = parseFloatComma(multiple.quantity);
  const floatDown: number = parseFloatComma(multiple.quantityMultipleDown);
  const floatUp: number = parseFloatComma(multiple.quantityMultipleUp);

  let suggestedValue: number;
  if (
    (material.unity === MaterialUnity.Ton && floatAmount > 10) ||
    (material.unity === MaterialUnity.Kg && floatAmount > 10000) ||
    floatDown === 0
  ) {
    suggestedValue = floatUp;
  } else {
    suggestedValue = suggestMultipleValue(floatAmount, floatDown, floatUp);
  }
  multiple.quantity = formatQtyField(suggestedValue);

  return { ...material, multipleValidation: multiple };
};

export const suggestMultipleValue = (value: number, valueDown: number, valueUp: number): number => {
  let suggestedValue = valueUp;

  const diffUp = valueUp - value;
  const diffDown = value - valueDown;

  if (diffDown < diffUp) {
    suggestedValue = valueDown;
  }

  return suggestedValue;
};
