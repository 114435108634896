import { PayloadAction } from '@reduxjs/toolkit';
import { Material } from '@app/models';
import { CustomerSelection } from '@app/providers';
import { getAmountError, removeMaterialError, validateAmount } from '../order-input-validation';
import {
  MaterialCell,
  OrderInputImplantationTypeEnum,
  OrderInputPhaseEnum,
  SLICE_NAME,
  SearchByMaterial,
} from '../order-input.constants';
import { IOrderInputState, SelectOption } from '../order-input.interfaces';

const RESET_ORDER_INPUT_ACTION_TYPE = `${SLICE_NAME}/reset`;
export const RESET_ORDER_INPUT_ACTION = { type: RESET_ORDER_INPUT_ACTION_TYPE };

const REMOVE_MATERIAL_OPTION_ARRAY_ACTION_TYPE = `${SLICE_NAME}/removeMaterialOptionArray`;
export const REMOVE_MATERIAL_OPTION_ARRAY_ACTION = { type: REMOVE_MATERIAL_OPTION_ARRAY_ACTION_TYPE };

const HANDLE_APPLY_TEMPLATE_ACTION_TYPE = `${SLICE_NAME}/handleApplyTemplate`;
export const HANDLE_APPLY_TEMPLATE_ACTION = { type: HANDLE_APPLY_TEMPLATE_ACTION_TYPE };

export const reducers = {
  setPhase: (state: IOrderInputState, { payload }: PayloadAction<OrderInputPhaseEnum>) => {
    state.phase = payload;
  },
  setIssuerOptions: (state: IOrderInputState, { payload }: PayloadAction<CustomerSelection[]>) => {
    state.issuerOptions = payload;
  },
  setReceiverOptions: (state: IOrderInputState, { payload }: PayloadAction<SelectOption[]>) => {
    state.receiverOptions = payload;
  },
  setMaterialOptions: (state: IOrderInputState, { payload }: PayloadAction<Material[]>) => {
    state.materialOptions = payload;
  },
  setOpenMaterials: (state: IOrderInputState, { payload }: PayloadAction<boolean>) => {
    state.openMaterials = payload;
  },
  setSelectedMaterials: (state: IOrderInputState, { payload }: PayloadAction<Material[]>) => {
    state.selectedMaterials = payload;
  },
  removeSelectedMaterialOption: (state: IOrderInputState, { payload }: PayloadAction<string>) => {
    const newSelectedMaterials: Material[] = [...state.selectedMaterials];
    newSelectedMaterials.forEach((item: Material, index: number, array: Material[]) => {
      if (item.tempId === payload) {
        array.splice(index, 1);
      }
    });
    state.selectedMaterials = newSelectedMaterials;
    state.materialDelete = null;
  },
  setSelectedReceiver: (state: IOrderInputState, { payload }: PayloadAction<string>) => {
    state.selectedReceiver = payload;
  },
  setSelectedIssuer: (state: IOrderInputState, { payload }: PayloadAction<SelectOption>) => {
    state.selectedIssuer = payload;
  },
  setSearchBy: (state: IOrderInputState, { payload }: PayloadAction<SearchByMaterial>) => {
    state.searchBy = payload;
  },
  setQuery: (state: IOrderInputState, { payload }: PayloadAction<string>) => {
    state.query = payload;
  },
  setPdfBase64: (state: IOrderInputState, { payload }: PayloadAction<string>) => {
    state.pdfBase64 = payload;
  },
  updateMaterial: (state: IOrderInputState, { payload }: PayloadAction<Material>) => {
    const material = payload;
    const selectedMaterials = [...state.selectedMaterials];
    const selectedMaterial = selectedMaterials.find(item => material.tempId === item.tempId);

    selectedMaterial.amount = material.amount;
    selectedMaterial.date = material.date;
    selectedMaterial.orderItem = material.orderItem;
    selectedMaterial.orderNumber = material.orderNumber;
    selectedMaterial.unity = material.unity;
    selectedMaterial.selected = material.selected;
    selectedMaterial.materialCode = material.materialCode;
    selectedMaterial.materialDescription = material.materialDescription;
    selectedMaterial.materialName = material.materialName;
    selectedMaterial.minMaxLenght = material.minMaxLenght;
    selectedMaterial.multipleLength = material.multipleLength;
    selectedMaterial.standardGrade = material.standardGrade;
    selectedMaterial.formatDescription = material.formatDescription;
    selectedMaterial.messages = material.messages;

    state.selectedMaterials = selectedMaterials;
  },
  setConfigColumnsSpreadsheet: (state: IOrderInputState, { payload }: PayloadAction<any>) => {
    state.configColumnsSpreadsheet = payload;
  },
  setEditNewLine: (state: IOrderInputState, { payload }: PayloadAction<boolean>) => {
    state.editedNewline = payload;
  },
  setSpreadSheetFileName: (state: IOrderInputState, { payload }: PayloadAction<string>) => {
    state.spreadSheetFileName = payload;
  },
  setSpreadSheetFileRequestItems: (state: IOrderInputState, { payload }: PayloadAction<any[]>) => {
    state.spreadSheetFileRequestItems = payload;
  },
  setMaterialFieldID: (state: IOrderInputState, { payload }: PayloadAction<string>) => {
    state.materialFieldID = payload;
  },
  setMaterialTemplate: (state: IOrderInputState, { payload }: PayloadAction<Material>) => {
    state.materialTemplate = payload;
  },
  setImplantationType: (state: IOrderInputState, { payload }: PayloadAction<OrderInputImplantationTypeEnum>) => {
    state.implantationType = payload;
  },
  setShowM2CalendarModal: (state: IOrderInputState, { payload }: PayloadAction<boolean>) => {
    state.showM2CalendarModal = payload;
  },
  setSelectAllMaterials: (state: IOrderInputState, { payload }: PayloadAction<boolean>) => {
    const materials = [...state.selectedMaterials];

    materials.forEach(material => {
      material.selected = payload;
    });

    state.selectedMaterials = materials;
  },
  removeMaterialOptionArray: (state: IOrderInputState) => {
    const selectedMaterials = state.selectedMaterials;
    const newSelectedMaterials: Material[] = [];

    selectedMaterials.forEach((item: Material, index: number) => {
      if (!item.selected) {
        item.tempId = new Date().getTime().toString() + index;
        newSelectedMaterials.push(item);
      }
    });
    state.selectedMaterials = newSelectedMaterials;
  },
  handleApplyTemplate: (state: IOrderInputState) => {
    const { selectedMaterials, materialTemplate } = state;
    const newSelectedMaterials: Material[] = [];

    selectedMaterials.forEach((item: Material, index: number) => {
      if (item.selected) {
        item.orderNumber = materialTemplate.orderNumber ? materialTemplate.orderNumber : item.orderNumber;
        item.amount = materialTemplate.amount ? materialTemplate.amount : item.amount;
        item.unity = materialTemplate.unity ? materialTemplate.unity : item.unity;

        if (materialTemplate.date) {
          item.date = materialTemplate.date;
          item.messages = removeMaterialError(item.messages, MaterialCell.Date);
        }

        if (validateAmount(item)) {
          item.messages = removeMaterialError(item.messages, MaterialCell.Amount);
          item.messages = removeMaterialError(item.messages, MaterialCell.Unity);
        } else {
          item.messages = [...item.messages, getAmountError(item)];
        }
      }

      item.tempId = new Date().getTime().toString() + index;

      newSelectedMaterials.push(item);
    });

    state.selectedMaterials = [...newSelectedMaterials];
    state.materialTemplate = {};
  },
  reset: (state: IOrderInputState) => {
    state.selectedMaterials = [];
    state.selectedReceiver = null;
    state.searchBy = null;
    state.materialOptions = [];
    state.query = '';
    state.phase = OrderInputPhaseEnum.Options;
  },

  setDeleteMaterialModalOpened: (state: IOrderInputState, { payload }: PayloadAction<boolean>) => {
    state.deleteMaterialModalOpened = payload;
  },
  setUpdateMaterialModalOpened: (state: IOrderInputState, { payload }: PayloadAction<boolean>) => {
    state.updateMaterialModalOpened = payload;
  },
  setSelectAll: (state: IOrderInputState, { payload }: PayloadAction<boolean>) => {
    state.selectAll = payload;
  },
  setMaterialDelete: (state: IOrderInputState, { payload }: PayloadAction<Material>) => {
    state.materialDelete = payload;
  },
  setOpenMultipleAlertModal: (state: IOrderInputState, { payload }: PayloadAction<boolean>) => {
    state.openMultipleAlertModal = payload;
  },
  setMultipleUpQuantity: (state: IOrderInputState, { payload }: PayloadAction<string>) => {
    const selectedMaterials = state.selectedMaterials;
    const selectedMaterial = selectedMaterials.find(item => payload === item.tempId);
    const { quantityMultipleUp } = selectedMaterial.multipleValidation;
    selectedMaterial.multipleValidation.quantity = quantityMultipleUp;
  },
  setMultipleDownQuantity: (state: IOrderInputState, { payload }: PayloadAction<string>) => {
    const selectedMaterials = state.selectedMaterials;
    const selectedMaterial = selectedMaterials.find(item => payload === item.tempId);
    const { quantityMultipleDown } = selectedMaterial.multipleValidation;
    selectedMaterial.multipleValidation.quantity = quantityMultipleDown;
  },
  setOpenConfigColumnSpreadsheet: (state: IOrderInputState, { payload }: PayloadAction<boolean>) => {
    state.openConfigColumnSpreadsheet = payload;
  },
  setMaterialCodeColumn: (state: IOrderInputState, { payload }: PayloadAction<string>) => {
    state.configColumnsSpreadsheet.materialCodeColumn = payload;
  },
  setAmountColumn: (state: IOrderInputState, { payload }: PayloadAction<string>) => {
    state.configColumnsSpreadsheet.amountColumn = payload;
  },
  setUnityAmountColumn: (state: IOrderInputState, { payload }: PayloadAction<string>) => {
    state.configColumnsSpreadsheet.unityAmountColumn = payload;
  },
  setDateWishedColumn: (state: IOrderInputState, { payload }: PayloadAction<string>) => {
    state.configColumnsSpreadsheet.dateWishedColumn = payload;
  },
  setItemIdentifierColumn: (state: IOrderInputState, { payload }: PayloadAction<string>) => {
    state.configColumnsSpreadsheet.itemIdentifierColumn = payload;
  },
  setOrderNumberColumn: (state: IOrderInputState, { payload }: PayloadAction<string>) => {
    state.configColumnsSpreadsheet.orderNumberColumn = payload;
  },
  setInitRow: (state: IOrderInputState, { payload }: PayloadAction<string>) => {
    state.configColumnsSpreadsheet.initRow = payload;
  },
  setIdentifier: (state: IOrderInputState, { payload }: PayloadAction<string>) => {
    state.configColumnsSpreadsheet.identifier = payload;
  },
};
