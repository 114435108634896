import { IShippingRequestRowParams } from '@app/data/http/shipping-request.dto';
import { InStockOrderItem } from '@app/models/sales-order-item.model';
import { localToInternationalDate } from '@app/utils/date-parser';
import { formatQtyField } from '@app/utils/string-utils';
import { tableColumnsDefinitions, tableColumnsDefinitionsGKN } from './shipping-request.constants';
import { IShippingRequestColumns, IShippingRequestRow } from './shipping-request.interfaces';

export const filterSelectedRow = (selectedRow: InStockOrderItem[], plantName: string) => {
  return selectedRow.filter(row => row.plant === plantName);
};

export const getStockQuantityTotal = (rows: IShippingRequestRow[]): number => {
  return rows.reduce((sum, row) => sum + row.stockQuantity, 0);
};

export const mapShippingRequestTableRows = (
  selectedRows: InStockOrderItem[],
  isGKN: boolean,
): IShippingRequestRow[] => {
  return selectedRows?.map((row: InStockOrderItem, index: number) => {
    return {
      index,
      customerId: completeZeroReceiver(row.soldToParty),
      material: row.material,
      materialDescription: row.materialName,
      plantCode: row.plant,
      plantName: row.plantName,
      salesOrder: row.salesDocument,
      salesOrderItem: row.salesDocumentItem,
      purchaseOrder: row.customerPurchaseOrderNumber.toString(),
      customerProductCode: row.customerMaterialCode.toString(),
      stockQuantity: isGKN
        ? subtractShipmentQuantityAndPickingQuantity(row.stockQuantity, row.deliveryQuantity, row.pickingQuantity)
        : row.stockQuantity,
      shipmentQuantity: row.deliveryQuantity,
      batch: row.batch,
      receiver: completeZeroReceiver(row.soldToParty),
      newReceiver: null,
      observations: '',
      requestedShippingQuantity: 0,
      heat: row.heat,
      lastHeat: row.lastHeat,
      breakHeat: row.breakHeat,
      hasQuantityLastHeat: row.hasQuantityLastHeat,
      qtyStockLastHeat: row.qtyStockLastHeat,
      customerAgreedDate: row.customerAgreedDate,
    };
  });
};

export const subtractShipmentQuantityAndPickingQuantity = (
  stockQuantity: number,
  deliveryQuantity: number,
  pickingQuantity: number,
): number => {
  const total = stockQuantity - deliveryQuantity - pickingQuantity;
  return total;
};

export const completeZeroReceiver = (receiver: string) => {
  if (receiver.length >= 10) {
    return receiver;
  }

  return completeZeroReceiver('0' + receiver);
};

export const mapTableRowsToParams = (tableRows: IShippingRequestRow[]): IShippingRequestRowParams[] => {
  return tableRows.map((row: IShippingRequestRow) => ({
    customerId: row.customerId,
    receiver: row.newReceiver || row.receiver,
    material: row.material,
    materialDescription: row.materialDescription,
    plantCode: row.plantCode,
    plantName: row.plantName,
    salesOrder: row.salesOrder,
    salesOrderItem: row.salesOrderItem,
    purchaseOrder: row.purchaseOrder,
    customerProductCode: row.customerProductCode,
    stockQuantity: formatQtyField(row.stockQuantity),
    batch: row.batch,
    observations: row.observations,
    shipmentQuantity: formatQtyField(row.shipmentQuantity),
    heat: row.heat,
    lastHeat: row.lastHeat,
    breakHeat: row.breakHeat,
    hasQuantityLastHeat: row.hasQuantityLastHeat,
    confirmedMaterialDate: row.customerAgreedDate ? row.customerAgreedDate : '',
    lastHeatQuantity: row.qtyStockLastHeat ? row.qtyStockLastHeat.toString().replace('.', ',') : null,
    requestedShippingQuantity: !row.requestedShippingQuantity
      ? formatQtyField(row.stockQuantity)
      : formatQtyField(row.requestedShippingQuantity),
  }));
};

export const getTableColumnsDefinitions = (isGKN: boolean) => {
  let filteredColumns: IShippingRequestColumns[];
  if (isGKN) {
    filteredColumns = tableColumnsDefinitionsGKN;
  } else {
    filteredColumns = tableColumnsDefinitions;
  }
  return filteredColumns;
};

export const getOldestSuggestions = (selectedRows: InStockOrderItem[]) => {
  const mappedSuggestionOptions = mapShippingRequestTableRows(selectedRows, false);

  return mappedSuggestionOptions.sort(
    (a, b) =>
      new Date(handleDate(a.customerAgreedDate)).getTime() - new Date(handleDate(b.customerAgreedDate)).getTime(),
  );
};

const handleDate = (dateString: string): Date => {
  const [day, month, year] = dateString.split('/');
  return new Date(`${year}/${month}/${day}`);
};

export const handleHasOldSalesOrderAlert = (data: InStockOrderItem[], tableRows: IShippingRequestRow[]) => {
  let hasAlert = false;

  tableRows.forEach(item => {
    const oldOVInData = data.filter(d => d.material === item.material && d.salesDocument !== item.salesOrder);
    const oldShippingItem = getOldShippingItem(item, oldOVInData, tableRows);

    if (oldShippingItem.length > 0) {
      hasAlert = true;
    }
  });

  return hasAlert;
};

export const getOldShippingItem = (
  currentItem: IShippingRequestRow,
  oldOVInData: InStockOrderItem[],
  tableRows: IShippingRequestRow[],
) => {
  const oldShippingItem = [];

  if (!oldOVInData.length) {
    return oldShippingItem;
  }

  oldOVInData.forEach(item => {
    const hasOldShippingItemAlreadySelected = tableRows.find(
      row => row.material === item.material && row.salesOrder === item.salesDocument,
    );

    if (
      new Date(localToInternationalDate(item.customerAgreedDate)) <
        new Date(localToInternationalDate(currentItem.customerAgreedDate)) &&
      item.deliveryQuantity + item.pickingQuantity < item.stockQuantity &&
      !hasOldShippingItemAlreadySelected
    ) {
      oldShippingItem.push(item);
    }
  });

  return oldShippingItem;
};
