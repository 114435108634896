import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { endpoint, CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from '@app/data/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export const deleteImageDerogate = async (params?: any) => {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    client_id: CXP_KEY_CLIENT_ID,
    client_secret: CXP_KEY_CLIENT_SECRET,
    Authorization: 'Bearer ' + user.accessToken,
    access_token: user.accessToken,
    'Content-Type': 'application/json',
    Accept: '*/*',
    language: params.language,
    'sso-access': user.ssoAccess,
  };

  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
    endpoint.ANALYSIS_PLANT
  }/${params.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}/${params.attributeKey}/${params.image}`;

  await axios.delete(url, { headers: defaultHeaders });
};

export const useDeleteImageDerogate = (onSuccess?, onError?) => {
  const queryClient = useQueryClient();
  return useMutation(deleteImageDerogate, {
    onSuccess,
    onError,
    onMutate: async data => {
      await queryClient.cancelQueries(['getTechnicalAnalysis']);
      const previousData = queryClient.getQueriesData(['getTechnicalAnalysis']);

      queryClient.setQueriesData(['getTechnicalAnalysis'], (oldQueryData: any) => {
        const selectedPlant = oldQueryData.plants.filter(plant => plant.key === data.plantKey);
        const attribute = selectedPlant[0].attributes.filter(attr => attr.key === data.attributeKey);

        attribute[0].derogate.images = attribute[0].derogate.images.filter(img => img !== data.image);

        return {
          ...oldQueryData,
        };
      });
      return {
        previousData,
      };
    },
  });
};
