import { GKN_BPS } from '@app/constants';
import { CustomerSelection } from '@app/providers';
import { marketMap } from '../customer-search.constants';
import { ICustomerSearchState } from '../customer-search.interfaces';

export const filterSelector = state => filterBps(state.customerSearch);

export const filterBps = (customerSearch: ICustomerSearchState) => {
  const {
    clientBpsSearch,
    querySearch,
    corporateGroupSearch,
    salesOrgsSearch,
    salesGroupSearch,
    filterOptionSearch,
  } = customerSearch;

  let bpsFiltered: CustomerSelection[] = clientBpsSearch;

  if (filterOptionSearch?.market) {
    bpsFiltered = bpsFiltered.filter(bp => bp.market === marketMap[filterOptionSearch?.market]);
  }

  bpsFiltered = bpsFiltered.filter(
    bp => bp.name.toUpperCase().includes(querySearch.toUpperCase()) || bp.customerId.includes(querySearch),
  );

  if (corporateGroupSearch.length) {
    bpsFiltered = bpsFiltered.filter(bp => corporateGroupSearch.map(corp => corp.value).includes(bp.corpGroup));
  }

  if (salesOrgsSearch.length) {
    bpsFiltered = bpsFiltered.filter(bp => salesOrgsSearch.map(salesOrg => salesOrg.value).includes(bp.salesOrg));
  }

  if (salesGroupSearch.length) {
    bpsFiltered = bpsFiltered.filter(bp =>
      salesGroupSearch.map(salesGroup => salesGroup.value).includes(bp.salesGroup),
    );
  }

  return bpsFiltered;
};

export const isGKNSelector = state =>
  state.customerPersist.clientBpsSelected.some(bp => GKN_BPS.includes(bp.customerId));
