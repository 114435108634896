import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { Customer } from '@app/models';
import { CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from './constants';
import { GetCustomerParams } from './customer-params.dto';
import { CustomerResponse, customerResponseToCustomerList } from './customer.dto';

const endpoint = {
  CUSTOMER: '/customers',
};

export const CustomerDataSource = {
  getCustomer: (params: GetCustomerParams): Promise<Customer[]> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.CUSTOMER)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        client_id: CXP_KEY_CLIENT_ID,
        client_secret: CXP_KEY_CLIENT_SECRET,
        access_token: user?.accessToken,
        'sso-access': user.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: CustomerResponse): Promise<Customer[]> => customerResponseToCustomerList(res),
      })
      .execute();
  },
};
