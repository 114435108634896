import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Container from 'typedi';
import { RENEW_TOKEN_INTERVAL_MILESECONDS, VERIFY_LOGOFF_MILESECONDS } from '@app/constants';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { AppPath } from '@app/modules/app/route-constants';
//import { getAzureTokenThunk } from '@app/modules/auth/store/thunk/get-azure-token.thunk';
import { initializeAndGetProfileThunk } from '@app/modules/auth/store/thunk/initialize-and-get-profile.thunk';
import { refreshAndUpdateTokenThunk } from '@app/modules/auth/store/thunk/refresh-and-update-token.thunk';
import { redirect } from '@app/modules/auth/utils/utils';

interface IAuthorizationContainerProps {
  hasUserLoggedIn: boolean;
}
const authStorageService: AuthStorageService = Container.get(AuthStorageService);

export const AuthorizationContainer: React.FC<IAuthorizationContainerProps> = props => {
  const { token, userInfo, refreshToken, ssoAccess } = useSelector((state: RootState) => state.auth);
  const ssoLogout = window.location.search.indexOf('ssoLogout=true') > -1;
  const dispatch = useDispatch<AppThunkDispatch>();

  const { search, pathname } = useLocation();
  const searchParams = new URLSearchParams(search);
  const redirectUrl = searchParams.get('redirect');
  const ssoLogoutParam = searchParams.get('ssoLogout');

  if (redirectUrl && !!token?.length) {
    redirect(redirectUrl);
  }

  useEffect(() => {
    console.log('ssoLogoutParam: ', ssoLogoutParam);
    console.log('redirectUrl: ', redirectUrl);
    console.log('token: ', token);
    console.log('userInfo: ', userInfo?.oktaUid);
    console.log('ssoLogout: ', ssoLogout);

    if (ssoLogout) {
      console.log('ENTROU NO IF DO ssoLogout: ', ssoLogout);
      document.cookie = '@access_token=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/; Domain=.egerdau.com.br;';
      document.cookie = '@sso-access=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/; Domain=.egerdau.com.br;';
      authStorageService.logout();
    } else if (!redirectUrl && token && !userInfo?.oktaUid && !ssoLogout) {
      console.log('ENTROU NO IF DO INITIALIZE NO AUTH: ', ssoLogout);
      dispatch(initializeAndGetProfileThunk(token));
    }
  }, [userInfo]);

  useEffect(() => {
    const azureToken = window.localStorage.getItem('azureToken');
    const azureDateTimeExpiration = window.localStorage.getItem('azureDateTimeExpiration');

    const interval = setInterval(() => {
      // dispatch(getAzureTokenThunk());

      if (ssoAccess) {
        dispatch(refreshAndUpdateTokenThunk(refreshToken));
      }
    }, RENEW_TOKEN_INTERVAL_MILESECONDS);

    const now = new Date().getTime();

    if (!azureToken || !azureDateTimeExpiration || now > parseInt(azureDateTimeExpiration)) {
      // dispatch(getAzureTokenThunk());
    }

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const user = authStorageService.getUser();
      if (!user && pathname !== AppPath.AUTHENTICATION) {
        authStorageService.logout();
      }
    }, VERIFY_LOGOFF_MILESECONDS);
    return () => clearInterval(interval);
  }, []);

  return <>{props.children}</>;
};
