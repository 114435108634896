import { Button, Icon } from '@gerdau/hefesto';
import { Tag } from '@gerdau/hefesto-gerdau-mais-gsb-web';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { IAdminUser, ProfileTypesId } from '@app/models/admin.model';
import { AdminBPsLineSeparator, AdminDataSeparator, BpTypeSpan } from '@app/modules/admin/admin.page.style';
import { AdminStrings } from '@app/modules/admin/admin.string';
import { resetUserPassword } from '@app/modules/admin/store/thunk/reset-password.thunk';
import { getUser } from '@app/modules/admin/store/thunk/user-get.thunk';
import { Body } from '@atomic/atm.typography';
import { FlexColumn } from '@atomic/obj.box';
import {
  AdminUserBPsContainer,
  AdminUserBPsRow,
  AdminUserContainer,
  AdminUserControlContainer,
  AdminUserDataContainer,
  AdminUserInfoContainer,
  AdminUserPermissionContainer,
  AdminUserPermissionRow,
  BPNumberContainer,
  ButtonContainer,
  PermissionTagContainer,
} from '../admin-user.styles';

interface AdminUserListContentProps {
  user: IAdminUser;
}

export const AdminUserListContent: React.FC<AdminUserListContentProps> = props => {
  const { created, lastLogin, email, oktaUid, profile } = props.user;
  const { loadingUser, loadingResetPassword } = useSelector((state: RootState) => state.admin);
  const dispatch = useDispatch<AppThunkDispatch>();
  const strings = AdminStrings.pt.common;

  const renderBPRow = (name: string, type: string, number: string, index: number) => {
    return (
      <FlexColumn key={`${number}-${index}`}>
        <AdminBPsLineSeparator />
        <AdminUserBPsRow>
          <Body bold={700}>
            {`${name} -`}
            <BpTypeSpan>{type}</BpTypeSpan>
          </Body>
          <BPNumberContainer>
            <Body>{`${strings.bpNumber} ${number}`}</Body>
          </BPNumberContainer>
        </AdminUserBPsRow>
      </FlexColumn>
    );
  };

  return (
    <AdminUserContainer>
      <AdminUserInfoContainer>
        <AdminUserDataContainer>
          <Body>{`${strings.createdBy} ${created.by.name}`}</Body>
          <AdminDataSeparator />
          <Body>{`${strings.created} ${created.at.dateFormat}`}</Body>
          <AdminDataSeparator />
          <Body>{`${strings.lastLogin} ${lastLogin.dateFormat}`}</Body>
        </AdminUserDataContainer>
        <AdminUserPermissionContainer>
          <Body>{strings.permissionByTab}</Body>
          <AdminUserPermissionRow>
            {props.user.tabs.map((tab, i) => {
              return (
                <PermissionTagContainer key={`${tab}-${i}`}>
                  {<Tag variant='neutral'>{tab}</Tag>}
                </PermissionTagContainer>
              );
            })}
          </AdminUserPermissionRow>
        </AdminUserPermissionContainer>
        <AdminUserBPsContainer>
          {props.user.clients.map((client, i) => {
            return renderBPRow(client.name, client.type, client.bp, i);
          })}
        </AdminUserBPsContainer>
      </AdminUserInfoContainer>
      <AdminUserControlContainer>
        <ButtonContainer>
          <Button
            name='editUser'
            variant='secondary'
            icon={<Icon option='Edit' />}
            loading={loadingUser}
            onClick={() => {
              dispatch(getUser(email));
            }}
          >
            {strings.editUser}
          </Button>
        </ButtonContainer>
        {profile.toLocaleLowerCase() === ProfileTypesId.Client && (
          <ButtonContainer>
            <Button
              name='sendPasswordRequest'
              variant='secondary'
              icon={<Icon option='Email' />}
              loading={loadingResetPassword}
              onClick={() => {
                dispatch(resetUserPassword(oktaUid));
              }}
            >
              {strings.sendPasswordRequest}
            </Button>
          </ButtonContainer>
        )}
      </AdminUserControlContainer>
    </AdminUserContainer>
  );
};
