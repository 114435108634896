import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { CSATUserModule } from '@app/data/http/admin-settings.datasource';
import { CSATModule } from '@app/modules/admin-settings/admin-settings.modules';
import { hasInternalAccessSelector } from '@app/modules/auth/store/auth.selectores';
import { setOpenNpsModal } from '@app/providers/navigation/navigation.store';
import { FlexColumn } from '@atomic/obj.box';
import { Modal } from '@atomic/obj.modal';
import { NPS_FORECAST_DATE, NPS_MODAL_CUSTOM_WIDTH } from './nps-modal.constats';
import { NpsModalBody } from './partials/nps-modal-body/nps-modal-body';
import { NpsModalFooter } from './partials/nps-modal-footer/nps-modal-footer';
import { NpsModalHeader } from './partials/nps-modal-header/nps-modal-header';

export const NpsModal: React.FC = () => {
  const hasInternalAccess = useSelector(hasInternalAccessSelector);
  const { csatUser = [] } = useSelector((state: RootState) => state.csat);
  const { openNpsModal } = useSelector((state: RootState) => state.navigation);
  const { loading: loadingCustomer } = useSelector((state: RootState) => state.customerSearch);
  const dispatch = useDispatch();

  useEffect(() => {
    const nps = csatUser.find((csat: CSATUserModule) => csat.functionality === CSATModule.nps);
    if (nps && !nps.answer && !hasInternalAccessSelector) {
      dispatch(setOpenNpsModal(true));
    }
  }, [csatUser]);

  useEffect(() => {
    LogAnalytics.pageView('nps');
  }, []);

  if (hasInternalAccess || loadingCustomer) {
    return null;
  }

  const today = new Date();
  if (today > NPS_FORECAST_DATE) {
    return null;
  }

  return (
    <Modal customWidth={NPS_MODAL_CUSTOM_WIDTH} opened={openNpsModal}>
      <FlexColumn>
        <NpsModalHeader />
        <NpsModalBody />
        <NpsModalFooter />
      </FlexColumn>
    </Modal>
  );
};
