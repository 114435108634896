import { differenceInDays, format } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import { useStore } from '@app/core/global-store.service';
import { RootState } from '@app/core/redux/store';
import { Language } from '@app/models';
import { UserStore, useUserStore } from '@app/providers';
import { Body } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { FontWeight, Spacing } from '@atomic/obj.constants';
import { NPS_FORECAST_DATE, NpsPhase } from '../../nps-modal.constats';
import { InpsModalStrings, Strings } from '../../nps.string';
import { NpsModalBodyWrapper } from './nps-modal-body.style';
import { NpsModalGrade } from './nps-modal-grade/nps-modal-grade';
import { NpsModalMessage } from './nps-modal-message/nps-modal-message';
import { NpsModalSucess } from './nps-modal-success/nps-modal-success';

const npsPhaseComponent = {
  [NpsPhase.Grade]: <NpsModalGrade />,
  [NpsPhase.Message]: <NpsModalMessage />,
  [NpsPhase.Success]: <NpsModalSucess />,
};
export const NpsModalBody: React.FC = () => {
  const { user } = useStore<UserStore>(useUserStore);
  const strings: InpsModalStrings = Strings[user?.language];
  const { phase } = useSelector((state: RootState) => state.nps);

  const today = new Date();
  const leftDays = differenceInDays(NPS_FORECAST_DATE, today);
  const { forecastMessage } = strings;
  const { forecastPart1, forecastPart2, forecastPart3 } = forecastMessage;
  const enableUntilMessage = forecastPart1;
  const forecastDateFormated =
    user?.language === Language.eng ? format(NPS_FORECAST_DATE, 'MM/dd/yyyy') : format(NPS_FORECAST_DATE, 'dd/MM/yyyy');
  const leftDaysMessage = ` ${forecastDateFormated} ${forecastPart2} ${leftDays} ${forecastPart3} `;

  return (
    <NpsModalBodyWrapper>
      {npsPhaseComponent[phase]}
      {phase !== NpsPhase.Success && (
        <FlexRow mt>
          <Body mr={Spacing.XSmall}>{`${enableUntilMessage}`}</Body>
          <Body bold={FontWeight.Bold}>{leftDaysMessage}</Body>
        </FlexRow>
      )}
    </NpsModalBodyWrapper>
  );
};
