import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { NotificationItem } from '@app/models/notification-center.model';
import { NotificationConfiguration } from '@app/models/notification-configuration.model';
import {
  CleanNotificationParams,
  ConfigureNotificationSettingsParams,
  mapNotifications,
  NotificationActivationParams,
  NotificationDto,
} from './notification.dto';

const endpoint = {
  NOTIFICATION: '/notification',
  CONFIGURATION: '/configuration',
  USER: '/user',
  ACTIVATE: '/active',
  DEACTIVATE: '/deactivate',
  CLEAN: '/clean',
};

export interface LogoutResponse {
  eGerdauResponse: {
    invalidated: boolean;
  };
}

export const NotificationDatasource = {
  getConfiguration: (): Promise<NotificationConfiguration> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.NOTIFICATION + endpoint.CONFIGURATION + endpoint.USER)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        access_token: user.accessToken,
        Accept: '*/*',
        'sso-access': user.ssoAccess,
      })
      .params({ userId: user.uid })
      .execute();
  },

  configureNotificationSettings: (params: ConfigureNotificationSettingsParams): Promise<NotificationItem[]> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .put(endpoint.NOTIFICATION + endpoint.CONFIGURATION + endpoint.USER)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        access_token: user.accessToken,
        Accept: '*/*',
        'sso-access': user.ssoAccess,
      })
      .data({ ...params, userId: user.uid })
      .interceptors({
        afterSuccess: async (res: NotificationDto): Promise<NotificationItem[]> => mapNotifications(res),
      })
      .execute();
  },

  getNotification: (): Promise<NotificationItem[]> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.NOTIFICATION + endpoint.USER)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        access_token: user.accessToken,
        Accept: '*/*',
        'sso-access': user.ssoAccess,
      })
      .interceptors({
        afterSuccess: async (res: NotificationDto): Promise<NotificationItem[]> => mapNotifications(res),
      })
      .execute();
  },

  activateNotifications: (params: NotificationActivationParams): Promise<void> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .post(endpoint.NOTIFICATION + endpoint.ACTIVATE)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        access_token: user.accessToken,
        Accept: '*/*',
        'sso-access': user.ssoAccess,
      })
      .data({ ...params, userId: user.uid })
      .execute();
  },

  deactivateNotifications: (params: NotificationActivationParams): Promise<void> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .post(endpoint.NOTIFICATION + endpoint.DEACTIVATE)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        access_token: user.accessToken,
        Accept: '*/*',
        'sso-access': user.ssoAccess,
      })
      .data({ ...params, userId: user.uid })
      .execute();
  },

  deleteNotifications: (params: CleanNotificationParams): Promise<void> => {
    const user = Container.get(AuthStorageService).getUser();
    const notificaions = params.notificationIds.map(notificationId => ({ notificationId, userId: user.uid }));

    return Container.get(HttpRequestBuilder)
      .delete(endpoint.NOTIFICATION + endpoint.USER + endpoint.CLEAN)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        access_token: user.accessToken,
        Accept: '*/*',
        'sso-access': user.ssoAccess,
      })
      .data(notificaions)
      .execute();
  },
};
