import Container from 'typedi';
import { AppConfig } from '@app/configure-app';
import { AppWindow } from '@app/utils/interfaces';
import { AuthStorageService } from '../storage/auth.storage';
import { AzureHttpRequestBuilder } from './azure-http-request.builder';

const endpoint = {
  GET_TOKEN: '/token',
};

export interface IAzureToken {
  expires_in: number;
  access_token: string;
}

const config: AppConfig = ((window as unknown) as AppWindow).__APP_CONFIG__;

export const AzureDataSource = {
  getAzureToken: (): Promise<IAzureToken> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(AzureHttpRequestBuilder)
      .post(endpoint.GET_TOKEN)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .data({
        client_id: config.azureClientId,
        client_secret: config.azureClientSectret,
      })
      .interceptors({
        afterSuccess: async (res: { data: IAzureToken }): Promise<IAzureToken> => {
          const value: IAzureToken = { access_token: res.data.access_token, expires_in: res.data.expires_in };
          return value;
        },
      })
      .execute();
  },
};
