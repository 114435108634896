import { AppPath } from '@app/modules/app/route-constants';
export interface IShippingRequestStrings {
  page: {
    breadcrumbs: any[];
    title: string;
    total: string;
    summary: string;
    table: {
      header: string[];
      totalRow: {
        total: string;
        emptyColumn: string;
      };
    };
    button: {
      title: string;
    };
    flashMessage: {
      success: string;
      errorQty: string;
    };
    placeholderObs: string;
    placeholderQtyEdit: string;
    sequentialWarning: string;
    sequentialWarningPlural: string;
    trSequentialWarning: string;
    trIgnoreButton: string;
    trDeleteItemButton: string;
    modalQtyAlert: string;
    modalQtyButton: string;
    trOVWarning: string;
    trSuggestionButton: string;
  };
  receiverModal: {
    title: string;
    currentReceiver: string;
    description: string;
    confirmButton: string;
    cancelButton: string;
  };
  suggestionModal: {
    title: string;
    requestLabel: string;
    suggestionLabel: string;
    suggestionWarningLabel: string;
    suggestionWarningDescription: string;
    confirmButton: string;
    cancelButton: string;
  };
}

interface IShippingRequestLanguages {
  pt: IShippingRequestStrings;
  es: IShippingRequestStrings;
  in: IShippingRequestStrings;
}

const pt: IShippingRequestStrings = {
  page: {
    breadcrumbs: [{ label: 'Pedidos', path: AppPath.ORDERS }, { label: 'Remessas' }],
    title: 'Remessas',
    total: 'Total',
    summary: 'Resumo',
    table: {
      header: [
        'Cód. prod. cliente',
        'Desc. material',
        'Material',
        'Ordem venda',
        'Item OV',
        'Ordem compra',
        'Qtd. em estoque (t)',
        'Qtd. em remessa (t)',
        'Observações',
        'Lote',
      ],
      totalRow: {
        total: 'Total',
        emptyColumn: '',
      },
    },
    button: {
      title: 'Solicitar remessa',
    },
    flashMessage: {
      success: 'Solicitação de remessa feita com sucesso!',
      errorQty: 'A quantidade desejada não pode ultrapassar a quantidade em estoque!',
    },
    placeholderObs: 'Digite sua observação',
    placeholderQtyEdit: 'Digite um valor',
    sequentialWarning: 'Atenção! Possível quebra no sequenciamento das corridas relacionado ao material',
    sequentialWarningPlural: 'Atenção! Possível quebra no sequenciamento das corridas relacionado aos materiais',
    trSequentialWarning: 'Atenção! Possível quebra no sequenciamento das corridas do material acima',
    trIgnoreButton: 'Ignorar',
    trDeleteItemButton: 'Excluir item',
    modalQtyAlert: 'A quantidade desejada não pode ultrapassar a quantidade em estoque!',
    modalQtyButton: 'Ok, entendi',
    trOVWarning: 'Atenção! Existe uma OV mais antiga no estoque com o mesmo material.',
    trSuggestionButton: 'Ver sugestão',
  },
  receiverModal: {
    title: 'Alteração de recebedor',
    currentReceiver: 'Recebedor atual:',
    description: 'Caso deseje alterar o recebedor, basta mudar a seleção abaixo:',
    confirmButton: 'Prosseguir',
    cancelButton: 'Cancelar',
  },
  suggestionModal: {
    title: 'Atenção! Existe uma OV mais antiga no estoque com o mesmo material ',
    requestLabel: 'Sua solicitação:',
    suggestionLabel: 'Sugestão:',
    suggestionWarningLabel: 'Aviso:',
    suggestionWarningDescription: 'Você deve selecionar OVs mais antigas antes de selecionar OVs recentes!',
    confirmButton: 'Prosseguir',
    cancelButton: 'Cancelar',
  },
};

export const Strings: IShippingRequestLanguages = {
  pt,
  es: pt,
  in: pt,
};
