import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { useSubmitShippingRequest } from '@app/domain/shipping-request.use-case';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { isGKNSelector } from '@app/modules/components/customer-search/store/customer-search.selectors';
import { ShippingRequestEvents } from '@app/modules/order/order.analytics';
import { IShippingRequestStrings, Strings } from '@app/modules/shipping-request/shipping-request.string';
import { mapTableRowsToParams } from '@app/modules/shipping-request/shipping-request.utils';
import {
  plantsDistinctSelector,
  totalRequestedShippingQuantitySelector,
} from '@app/modules/shipping-request/store/shipping-request.selectors';
import { setOpenShippingFeedback } from '@app/modules/shipping-request/store/shipping-request.store';
import { setOpenErrorHandlerModal } from '@app/providers/navigation/navigation.store';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Image } from '@atomic/atm.image';
import { VSeparator } from '@atomic/obj.grid';
import {
  ShippingRequestFooterButtonWrapperStyled,
  ShippingRequestSuccessMsg,
  ShippingSuccessIcon,
} from './shipping-request-submit.style';

export const ShippingRequestSubmit = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IShippingRequestStrings = Strings[userInfo.language];
  const customerIds = useSelector(customerIdsJoinedSelector);
  const { selectedRows } = useSelector((state: RootState) => state.orderTabStock);
  const { clientBpsSelected } = useSelector((state: RootState) => state.customerPersist);
  const { tableRows } = useSelector((state: RootState) => state.shippingRequest);
  const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(false);
  const isGKN = useSelector(isGKNSelector);
  const plantCodes = useSelector(plantsDistinctSelector);
  const totalGKN = useSelector((state: RootState) => totalRequestedShippingQuantitySelector(state, plantCodes[0]));
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      tableRows.some(row => row.requestedShippingQuantity > row.stockQuantity) ||
      tableRows.some(row => {
        const sameHeat = row.heat === row.lastHeat;
        const hasAlert = !sameHeat && row.hasQuantityLastHeat === 'Sim' && !row.removedAlert;
        return hasAlert;
      }) ||
      (isGKN && totalGKN === 0)
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [tableRows]);

  const handleSuccess = () => {
    selectedRows.forEach(item => {
      LogAnalytics.success({
        tipo: ShippingRequestEvents.SuccessShippingRequest,
        salesOrderDocuments: item.salesDocument,
        salesOrderDocumentItem: item.salesDocumentItem,
        customerIds,
      });
    });

    setShowSuccessMsg(true);
    dispatch(setOpenShippingFeedback(true));
  };

  const handleError = error => {
    LogAnalytics.error({
      tipo: ShippingRequestEvents.ErrorShippingRequest,
      responseStatus: error.response.status,
      responseData: error.response.data?.message,
      customerIds,
    });
    dispatch(setOpenErrorHandlerModal(true));
  };

  const { performRequest: submitShippingRequest, loading } = useSubmitShippingRequest(handleSuccess, handleError);

  const handleClick = () => {
    submitShippingRequest({
      customerName: userInfo.name,
      customerEmail: userInfo.email,
      corpGroup: clientBpsSelected[0].corpGroup,
      companyName: selectedRows[0].shipToPartyName,
      requestOrigin: 'portal',
      shipping: mapTableRowsToParams(tableRows),
    });
  };

  if (showSuccessMsg) {
    return (
      <ShippingRequestSuccessMsg>
        <ShippingSuccessIcon>
          <Image.Success />
        </ShippingSuccessIcon>
        {strings.page.flashMessage.success}
      </ShippingRequestSuccessMsg>
    );
  }

  return (
    <ShippingRequestFooterButtonWrapperStyled>
      <Button disabled={disabled} kind='callToAction' onClick={handleClick} loading={loading}>
        <FaIcon.FileImport />
        <VSeparator />
        {strings.page.button.title}
      </Button>
    </ShippingRequestFooterButtonWrapperStyled>
  );
};
