export enum ShippingRequestEvents {
  ErrorShippingRequest = 'Erro na solicitação de Remessa',
  CancelShippingRequest = 'Não solicitar remessa',
  SuccessShippingRequest = 'Remessa solicitada',
  IgnoreAlertClick = 'ShippingRequestIgnoreAlertClick',
  DeleteItemClick = 'ShippingRequestDeleteItemClick',
  AlertConfirmClick = 'ShippingRequestAlertConfirmClick',
  ShippingRequestProgrammed = 'Remessa programada',
  CancelShippingRequestProgrammed = 'Cancelamento de remessa programada',
  ShippingRequestClick = 'SolicitacaoDeRemessaClick',
  ShippingRequestView = 'SolicitacaoDeRemessasVisualizado',
}

export enum OrderUpdateEvents {
  CancelOrderUpdate = 'AlteracaoDeCarteiraCancelado',
  OrderUpdateSuccess = 'AlteracaoDeCarteiraSolicitada',
  OrderUpdateError = 'OrderUpdateError',
  OrderUpdateView = 'AlteracaoDeCarteiraVisualizado',
  IgnoreAlertStatusClick = 'OrderUpdateIgnoreAlertStatusClick',
  IgnoreAlertDateClick = 'OrderUpdateIgnoreAlertDateClick',
  IgnoreAlertCancelClick = 'OrderUpdateIgnoreAlertCancelClick',
  DeleteItemClick = 'OrderUpdateDeleteItemClick',
  RevisionItemClick = 'OrderUpdateRevisionItemClickClick',
}

export enum TruckTrackingRequestEvents {
  TruckTrackingClick = 'TruckTrackingClick',
  TruckTrackingPageView = 'TruckTrackingVisualizado',
  TruckTrackingSuccess = 'TruckTrackingSucesso',
  TruckTrackingNoTracking = 'NoTracking',
  TruckTrackingTimeout = 'Timeout',
}

export enum OrderPriorizationEvents {
  OrderPriorizationFilterCheck = 'AtivacaoFiltroItensCriticos',
  OrderLackOfChargeFilterCheck = 'AtivacaoFiltroFaltaDeCarga',
  OrderPriorizationView = 'PriorizacaoDeItensVisualizado',
  OrderPriorizationSuccess = 'PriorizacaoDeItensSolicitada',
  OrderPriorizationError = 'PriorizacaoDeItensFalha',
  CancelOrderPriorization = 'PriorizacaoDeItemCancelada',
}
