import { datadogRum } from '@datadog/browser-rum';
import Container from 'typedi';
import { AppConfig } from '@app/configure-app';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { AppWindow } from './interfaces';

const config: AppConfig = ((window as unknown) as AppWindow).__APP_CONFIG__;
const user = Container.get(AuthStorageService).getUser();

export const handleDatadogRUM = isAuthPage => {
  if (config.qa || config.prod) {
    datadogRum.init({
      applicationId: config.datadogApp,
      clientToken: config.datadogClientToken,
      site: config.datadogSite,
      service: config.datadogService,
      env: config.qa ? 'staging' : 'production',
      sessionSampleRate: 20,
      sessionReplaySampleRate: 0,
      //startSessionReplayRecordingManually: true,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      //premiumSampleRate: 0,
      defaultPrivacyLevel: 'mask-user-input',
    });

    //datadogRum.startSessionReplayRecording();

    if (!isAuthPage && user) {
      datadogRum.setUser({ id: user.uid });
    }
  }
};
