import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { ShipmentActivationParams } from './notification.dto';

const endpoint = {
  REMESSA: '/shipment',
  ACTIVATE: '/active',
  DEACTIVATE: '/deactivate',
};

export const ShippingConfigDatasource = {
  activateShippingConfig: (params: ShipmentActivationParams): Promise<void> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .post(endpoint.REMESSA + endpoint.ACTIVATE)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        access_token: user.accessToken,
        Accept: '*/*',
        'sso-access': user.ssoAccess,
      })
      .data({ ...params, userId: user.uid })
      .execute();
  },

  deactivateShippingConfig: (params: ShipmentActivationParams): Promise<void> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .post(endpoint.REMESSA + endpoint.DEACTIVATE)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        access_token: user.accessToken,
        Accept: '*/*',
        'sso-access': user.ssoAccess,
      })
      .data({ ...params, userId: user.uid })
      .execute();
  },
};
