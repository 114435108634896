import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import store from '@app/core/redux/store';
import { InvoiceList, InvoiceMEList } from '@app/models/invoice-list.model';
import { AuthStorageService } from '../storage/auth.storage';
import { CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from './constants';
import { InvoiceListDto, InvoiceMEListDto, mapInvoiceList, mapInvoiceMEList } from './invoice.dto';

const endpoint = {
  SALES_ORDER_MI: '/mi/financials/invoice',
  SALES_ORDER_ME: '/me/financials/invoice',
};

export interface GetInvoiceParams {
  customerIds: string;
  statusCompensation: string;
}

export const InvoiceDataSource = {
  getInvoiceMI: (params: GetInvoiceParams): Promise<InvoiceList> => {
    const user = Container.get(AuthStorageService).getUser();
    const authStore = store.getState().auth;

    return Container.get(HttpRequestBuilder)
      .get(endpoint.SALES_ORDER_MI)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        client_id: CXP_KEY_CLIENT_ID,
        client_secret: CXP_KEY_CLIENT_SECRET,
        access_token: authStore.token,
        'sso-access': user.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: InvoiceListDto): Promise<InvoiceList> => mapInvoiceList(res),
      })
      .execute();
  },
  getInvoiceME: (params: GetInvoiceParams): Promise<InvoiceMEList> => {
    const user = Container.get(AuthStorageService).getUser();
    const authStore = store.getState().auth;

    return Container.get(HttpRequestBuilder)
      .get(endpoint.SALES_ORDER_ME)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        client_id: CXP_KEY_CLIENT_ID,
        client_secret: CXP_KEY_CLIENT_SECRET,
        access_token: authStore.token,
        'sso-access': user.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: InvoiceMEListDto): Promise<InvoiceMEList> => mapInvoiceMEList(res),
      })
      .execute();
  },
};
