import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET, endpoint } from '@app/data/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';

interface ParamsDelete {
  id: string;
}

export const deleteDocument = async (params?: ParamsDelete) => {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    client_id: CXP_KEY_CLIENT_ID,
    client_secret: CXP_KEY_CLIENT_SECRET,
    Authorization: 'Bearer ' + user.accessToken,
    access_token: user.accessToken,
    'Content-Type': 'application/json',
    Accept: '*/*',
    'sso-access': user.ssoAccess,
  };

  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.DOCUMENTS}/${params.id}`;

  const { data: dataResponse } = await axios.delete(url, { headers: defaultHeaders });

  return dataResponse;
};

export const useDeleteDocument = (onSuccess?, onError?) => {
  const queryClient = useQueryClient();
  return useMutation(deleteDocument, {
    onSuccess,
    onError,
    onSettled: () => {
      queryClient.invalidateQueries(['getDocumentList']);
    },
  });
};
