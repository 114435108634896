import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { endpoint, CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from '@app/data/http';
import { GetAvailableStatusParams } from '@app/data/http/quote-status-params.dto';
import { AvailableStatusResponse, mapCurrentStatus } from '@app/data/http/quote-status.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export async function getAvailableStatus(params?: GetAvailableStatusParams): Promise<AvailableStatusResponse> {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    client_id: CXP_KEY_CLIENT_ID,
    client_secret: CXP_KEY_CLIENT_SECRET,
    Authorization: 'Bearer ' + user.accessToken,
    access_token: user.accessToken,
    'Content-Type': 'application/json',
    Accept: '*/*',
    language: params.language,
    'sso-access': user.ssoAccess,
  };

  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
    endpoint.ANALYSIS_STATUS
  }`;

  const { data, status } = await axios.get(url, { headers: defaultHeaders });

  if (status === 200) {
    return data;
  } else {
    return null;
  }
}
export function useCheckProcessingStatus(params: GetAvailableStatusParams, onSuccess?, onError?) {
  return useQuery(['getAvailableStatus', params], () => getAvailableStatus(params), {
    enabled: params.currentStatus === 'PROCESSING',
    onSuccess,
    onError,
    select: data => {
      return mapCurrentStatus(data);
    },
    refetchInterval: 10000,
    refetchOnWindowFocus: false,
  });
}
