import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { OpenOrderStatusGraph, OrderStatusGraph } from '@app/models/dashboard.model';
import { CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from '../../constants';
import { mapOpenedStatusGraphsDto, OpenedStatusGraphsDto } from './open-orders-status.dto';
import { QtdConfirmedGraphsDTO, QtdRequestedGraphsDTO } from './qtd.dto';
import { mapOrderStatusGraph, StatusGraphsDTO } from './status.dto';

const endpoint = {
  DASHBOARD: '/dashboard',
  OPEN_ORDERS: '/open-orders',
};

const openOrdersEndpoint = {
  openedStatus: {
    OPENED_STATUS_ORDERS: '/status-pedido-aberto',
  },
  qtd: {
    REQUESTED: '/qtd-solicitada-mensal',
    CONFIRMED: '/qtd-confirmada-mensal',
  },
  status: {
    TOTAL: '/pedidos-aberto/status-pedido/total',
  },
};

export const OpenOrdersGraphDataSource = {
  openedStatus: (clientNumber: string): Promise<OpenOrderStatusGraph> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.DASHBOARD + endpoint.OPEN_ORDERS + openOrdersEndpoint.openedStatus.OPENED_STATUS_ORDERS)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        client_id: CXP_KEY_CLIENT_ID,
        client_secret: CXP_KEY_CLIENT_SECRET,
        access_token: user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .params({ customerIds: clientNumber })
      .interceptors({
        afterSuccess: async (res: OpenedStatusGraphsDto): Promise<OpenOrderStatusGraph> =>
          mapOpenedStatusGraphsDto(res),
      })
      .execute();
  },
  qtd: {
    qtdRequested: (clientNumber: string): Promise<QtdRequestedGraphsDTO> => {
      const user = Container.get(AuthStorageService).getUser();

      return Container.get(HttpRequestBuilder)
        .get(endpoint.DASHBOARD + endpoint.OPEN_ORDERS + openOrdersEndpoint.qtd.REQUESTED)
        .headers({
          Authorization: 'Bearer ' + user.accessToken,
          client_id: CXP_KEY_CLIENT_ID,
          client_secret: CXP_KEY_CLIENT_SECRET,
          access_token: user.accessToken,
          'sso-access': user.ssoAccess,
        })
        .params({ customerIds: clientNumber })
        .execute();
    },
    qtdConfirmed: (clientNumber: string): Promise<QtdConfirmedGraphsDTO> => {
      const user = Container.get(AuthStorageService).getUser();

      return Container.get(HttpRequestBuilder)
        .get(endpoint.DASHBOARD + endpoint.OPEN_ORDERS + openOrdersEndpoint.qtd.CONFIRMED)
        .headers({
          Authorization: 'Bearer ' + user.accessToken,
          client_id: CXP_KEY_CLIENT_ID,
          client_secret: CXP_KEY_CLIENT_SECRET,
          access_token: user.accessToken,
          'sso-access': user.ssoAccess,
        })
        .params({ customerIds: clientNumber })
        .execute();
    },
  },
  status: (clientNumber: string): Promise<OrderStatusGraph> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.DASHBOARD + endpoint.OPEN_ORDERS + openOrdersEndpoint.status.TOTAL)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        client_id: CXP_KEY_CLIENT_ID,
        client_secret: CXP_KEY_CLIENT_SECRET,
        access_token: user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .params({ customerIds: clientNumber })
      .interceptors({
        afterSuccess: async (res: StatusGraphsDTO): Promise<OrderStatusGraph> => mapOrderStatusGraph(res),
      })
      .execute();
  },
};
