import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from 'typedi';
import { AppConfig } from '@app/configure-app';
import { AppThunkDispatch, persistor, RootState } from '@app/core/redux/store';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { AppPath } from '@app/modules/app/route-constants';
import { hasInternalAccessSelector, hasSurrogateAccessSelector } from '@app/modules/auth/store/auth.selectores';
import {
  confirmSelectedBpsClient,
  RESET_CUSTOMER_ACTION,
  setCurrentMarket,
} from '@app/modules/components/customer-search/store/customer-persist.store';
import {
  initCustomerSearch,
  setClientBpSearch,
} from '@app/modules/components/customer-search/store/customer-search.store';
import { getSurrogate } from '@app/modules/components/surrogate/store/thunks/get-surrogate.thunks';
import { AppWindow } from '@app/utils/interfaces';
import { setUser } from './user-redux.store';
import { MarketType } from './user.store';

const config: AppConfig = ((window as unknown) as AppWindow).__APP_CONFIG__;
export interface CustomerContainerProps {
  children: JSX.Element;
  hasUserLoggedIn: boolean;
}
export const CustomerContainer: React.FC<CustomerContainerProps> = ({ children, hasUserLoggedIn }) => {
  const { userInfo, token } = useSelector((state: RootState) => state.auth);
  const { email } = useSelector((state: RootState) => state.user);
  const hasInternalAccess = useSelector(hasInternalAccessSelector);
  const customerState = useSelector((state: RootState) => state.customerPersist);
  const dispatch = useDispatch<AppThunkDispatch>();
  const authStorageService: AuthStorageService = Container.get(AuthStorageService);
  const hasSurrogateAccess = useSelector(hasSurrogateAccessSelector);

  useEffect(() => {
    if (hasUserLoggedIn) {
      if (email !== userInfo?.email) {
        dispatch(RESET_CUSTOMER_ACTION);
      }
      setTimeout(() => {
        dispatch(setUser(userInfo));
      }, 0);

      dispatch(setClientBpSearch(userInfo.bpList));
      dispatch(initCustomerSearch(customerState));

      if (hasSurrogateAccess) {
        dispatch(getSurrogate({ email: userInfo.email }));
      }

      if (!hasInternalAccess) {
        if (userInfo.bpList?.length) {
          if (userInfo.bpList[0]?.market === MarketType.External) {
            dispatch(setCurrentMarket(MarketType.External));
          } else {
            dispatch(setCurrentMarket(MarketType.Internal));
          }
        }

        dispatch(setClientBpSearch(userInfo.bpList?.map(bp => ({ ...bp, selected: true }))));
        dispatch(
          confirmSelectedBpsClient({ clientBpsSearch: userInfo.bpList?.map(bp => ({ ...bp, selected: true })) }),
        );
      }
    }
  }, [hasUserLoggedIn, hasInternalAccess]);

  useEffect(() => {
    const isLoginPage = window.location.pathname.indexOf('/auth') === 0;

    if ((!isLoginPage && userInfo && !userInfo.applications) || (!isLoginPage && !token)) {
      authStorageService.logout();
    }
  }, []);

  useEffect(() => {
    const envVersion = config.version;
    const localStoreVersion = localStorage.getItem('version');

    if (envVersion !== localStoreVersion) {
      persistor.pause();
      persistor.flush().then(() => {
        return persistor.purge();
      });

      setTimeout(() => {
        localStorage.setItem('version', envVersion);
        window.location.href = AppPath.AUTHENTICATION;
      }, 0);
    }
  }, []);

  return <>{children}</>;
};
