import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from '@app/data/http';
import { GetAssetsResponse } from '@app/data/http/quote-assets.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export async function getFile(params?: any): Promise<GetAssetsResponse[]> {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    client_id: CXP_KEY_CLIENT_ID,
    client_secret: CXP_KEY_CLIENT_SECRET,
    'Content-Type': 'application/json',
    access_token: user.accessToken,
    Authorization: 'Bearer ' + user.accessToken,
    Accept: '*/*',
    Language: params.language,
    'sso-access': user.ssoAccess,
  };

  const { data, status } = await axios.get(params.url, { headers: defaultHeaders, responseType: 'arraybuffer' });

  if (status === 200) {
    const blob = new Blob([data], { type: params.type });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = params.filename;
    link.click();
  }
  return null;
}

export const useDownloadAsset = () => {
  return useMutation(getFile, {});
};
