import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { SCROLL_TOP_DIFFERENCE } from '@app/modules/app/app.constants';
import { SHIPPING_REQUEST_LEFT_TOOLTIP_POSITION } from '@app/modules/shipping-request/shipping-request.constants';
import { IShippingRequestRow } from '@app/modules/shipping-request/shipping-request.interfaces';
import { setOpenReceiverModalIndex } from '@app/modules/shipping-request/store/shipping-request.store';
import useScrollPosition from '@app/utils/use-scroll-position.hook';
import { Button } from '@atomic/atm.button';
import { CustomizedTooltip } from '@atomic/atm.customized-tooltip';
import { FaIcon } from '@atomic/atm.fa-icon';
import { FlexRow } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';

interface IShippingRequestEditReceiverFieldProps {
  item: IShippingRequestRow;
}

export const ShippingRequestEditReceiverField: React.FC<IShippingRequestEditReceiverFieldProps> = ({ item }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { selectedNewReceivers } = useSelector((state: RootState) => state.shippingRequest);
  const selectedNewReceiverItem = selectedNewReceivers.find(row => row.tableRowIndex === item.index);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const scrollPosition = useScrollPosition();

  const onClick = () => {
    dispatch(setOpenReceiverModalIndex(item.index));
  };

  const showInfo = () => {
    return !!item.newReceiver && item.newReceiver !== item.receiver;
  };

  let topTooltip = 0;
  let leftTooltip = 0;

  if (ref?.current) {
    const iconBoundings = ref?.current?.getBoundingClientRect();
    topTooltip = iconBoundings.top;
    leftTooltip = iconBoundings.left;
  }

  const hasScrolled = document.documentElement.scrollTop > SCROLL_TOP_DIFFERENCE;
  let headerDiscount = 0;
  hasScrolled ? (headerDiscount = SCROLL_TOP_DIFFERENCE) : (headerDiscount = SCROLL_TOP_DIFFERENCE * 2);
  const customTop = topTooltip + scrollPosition - headerDiscount;
  const customLeft = leftTooltip - SHIPPING_REQUEST_LEFT_TOOLTIP_POSITION;

  return (
    <FlexRow vAlign='center' hAlign='center'>
      <FlexRow mr={Spacing.Small}>
        <Button onClick={onClick} kind='secondary'>
          <FaIcon.Pen />
        </Button>
      </FlexRow>
      {showInfo() && (
        <>
          <FlexRow
            pl={Spacing.Small}
            hAlign='center'
            ref={ref}
            onMouseOver={() => setShowTooltip(true)}
            onMouseOut={() => setShowTooltip(false)}
          >
            <FaIcon.FlashInfo clickable color={Color.Primary} />
            <CustomizedTooltip
              description={selectedNewReceiverItem?.name}
              visible={showTooltip}
              onClick={() => setShowTooltip(true)}
              onClose={() => setShowTooltip(false)}
              customHeight={customTop}
              customLeftAlign={customLeft}
              customArrowX={45}
              hasFooter={false}
              hasHeader={false}
              hasOffset
            />
          </FlexRow>
        </>
      )}
    </FlexRow>
  );
};
