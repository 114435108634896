import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { endpoint, CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from '@app/data/http';
import { GetAssetsParams } from '@app/data/http/quote-assets-params.dto';
import { GetAssetsResponse, mapGetAssets } from '@app/data/http/quote-assets.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export async function getAssets(params?: GetAssetsParams): Promise<GetAssetsResponse[]> {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    client_id: CXP_KEY_CLIENT_ID,
    client_secret: CXP_KEY_CLIENT_SECRET,
    access_token: user.accessToken,
    Accept: '*/*',
    Language: params.language,
    'sso-access': user.ssoAccess,
  };

  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
    endpoint.ANALYSIS_ASSETS
  }`;

  const { data, status } = await axios.get(url, { headers: defaultHeaders });

  if (status === 200) {
    return data;
  } else {
    return null;
  }
}

export function useGetAssets(params: GetAssetsParams, onSuccess?) {
  return useQuery(['getAssets', params], () => getAssets(params), {
    enabled: false,
    onSuccess,
    select: data => {
      return mapGetAssets(data);
    },
    refetchOnWindowFocus: false,
    retry: false,
  });
}
