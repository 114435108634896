import Container from 'typedi';
import { HttpInterceptor, HttpRequest } from '@app/core/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export class TokenInterceptor implements HttpInterceptor {
  async before(request: HttpRequest): Promise<HttpRequest> {
    const user = Container.get(AuthStorageService).getUser();

    request.headers.access_token = `${user?.accessToken}`;

    return request;
  }
}
