import { AdminGroup, AdminLastLogin, AdminUser, AdminUserProfile } from '@app/models/admin-new.model';
import { IAdminBP, IAdminBPList, IAdminTab, IAdminUser, IAdminUserList, IRole } from '@app/models/admin.model';
import { IUser } from '@app/modules/admin/admin.interfaces';
import { orderedTabs } from '@app/modules/admin/partials/admin-tabs/tabs/admin-profile/utils/utils';
import { dateParser } from '@app/utils/date-parser';
import {
  GetAdminBPsResponse,
  GetAdminLastLoginResponse,
  GetAdminTabsResponse,
  GetAdminUsersResponse,
  GetGroupDetailResponse,
  GetGroupResponse,
  GetRolesResponse,
  GetUserDetailResponse,
  GetUserResponse,
  ListAvailableGroupsResponse,
} from './admin.params.dto';

export interface CreateUserData {
  name: string;
  email: string;
  phone?: string;
  language: string;
  profile: UserProfileData[];
}

export interface UserProfileData {
  gerdauSystem: string;
  profileSystem?: string;
  givenProfile: string;
  codSeller?: string;
  bps?: string[];
  roles?: string[];
}

export const mapGroup = (response: GetGroupResponse): GetGroupResponse => {
  const groups: AdminGroup[] = response.group.map(group => {
    return {
      address: !!group.address && formatFirstLetterUpperCase(group.address),
      name: formatFirstLetterUpperCase(group.name),
      cnpj: group.cnpj,
      identifier: group.identifier,
      groupId: group.groupId,
      codGroup: group.codGroup,
      description: group.description,
    };
  });

  return { group: groups };
};

export const mapGroupDetail = (response: GetGroupDetailResponse): AdminUser[] => {
  return response.users.map(user => {
    return {
      name: user.name,
      email: user.email,
      phone: user.mobilePhone,
      language: user.preferredLanguage,
      profile: user.profile,
      displayName: user.displayName,
      codSeller: user.codeSeller,
      roles: user.roles,
      created: user.created,
    };
  });
};

export const mapSearchResultList = (response: ListAvailableGroupsResponse): AdminGroup[] => {
  return response.list?.map(item => {
    const group: AdminGroup = {
      name: formatFirstLetterUpperCase(item.name),
      cnpj: item.cnpj,
      identifier: item.identifier,
      groupId: item.codGroup,
    };

    if (item.address) {
      group.address = formatFirstLetterUpperCase(item.address);
    }

    return group;
  });
};

export const mapUserProfile = (response: GetUserDetailResponse): AdminUserProfile[] => {
  return response.profile.map(user => {
    return {
      profile: user.givenProfile,
      displayName: user.displayName,
      name: user.name,
      cnpj: user.cnpj,
      system: user.gerdauSystem,
      groupId: user.codeGroup,
    };
  });
};

export const mapLastLogin = (response: GetAdminLastLoginResponse): AdminLastLogin => {
  return {
    date: response.lastlogin && dateParser({ unformattedDate: response.lastlogin, hideHours: false, utc: false }),
  };
};

export const mapUser = (response: GetUserResponse): IUser => {
  const { data } = response;
  return data;
};

export const mapUsers = (response: GetAdminUsersResponse): IAdminUserList => {
  const users: IAdminUser[] = [];
  response.data?.items?.map(user => {
    return users.push({
      _id: user._id,
      oktaUid: user.oktaUid,
      name: user.name,
      email: user.email,
      created: user.created,
      lastLogin: user.lastLogin,
      profile: user.profile,
      tabs: user.tabs,
      clients: user.clients,
    });
  });

  return { users: users, pagination: response.data.pagination };
};

export const mapBPs = (response: GetAdminBPsResponse): IAdminBPList => {
  const users: IAdminBP[] = [];

  response.data?.items?.map(bp => {
    return users.push({
      _id: bp._id,
      name: bp.name,
      type: bp.type,
      bp: bp.bp,
      users: bp.users,
      corporateGroup: bp.corporateGroup,
      corporateGroupName: bp.corporateGroupName,
    });
  });

  return { bps: users, pagination: response.data.pagination };
};

export const mapRole = (response: GetRolesResponse): IRole[] => {
  const roles: IRole[] = [];

  response.data?.map(role => {
    return roles.push({
      _id: role._id,
      profile: role.profile,
      tabs: role.tabs,
    });
  });

  return roles;
};

export const mapTab = (response: GetAdminTabsResponse): IAdminTab[] => {
  const tabs: IAdminTab[] = [{ _id: null, tag: null, name: null, description: null, order: 0 }];

  response.data?.map(tab => {
    return tabs.push(orderedTabs(tab));
  });

  return tabs;
};

const formatFirstLetterUpperCase = (value: string) => {
  return value
    .split(' ')
    .map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase())
    .join(' ');
};
