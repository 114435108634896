import * as React from 'react';
import Container from 'typedi';
import { InvoiceDataSource } from '@app/data/http/invoice.datasource';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { FlashMessageTargetName, MarketType } from '@app/providers';

export const useFinanceQuery = (marketType: string) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  const authStorageService: AuthStorageService = Container.get(AuthStorageService);

  const fetch = React.useCallback(
    async (clientNumber: string) => {
      setLoading(true);
      try {
        let resPaid;
        let resNOTexpired;
        let resExpired;

        if (marketType === MarketType.Internal) {
          resPaid = await InvoiceDataSource.getInvoiceMI({
            customerIds: clientNumber,
            statusCompensation: 'PAID',
          }).catch(() => ({
            invoice: [],
          }));
          resNOTexpired = await InvoiceDataSource.getInvoiceMI({
            customerIds: clientNumber,
            statusCompensation: 'NOT EXPIRED',
          }).catch(() => ({ invoice: [] }));
          resExpired = await InvoiceDataSource.getInvoiceMI({
            customerIds: clientNumber,
            statusCompensation: 'EXPIRED',
          }).catch(() => ({ invoice: [] }));
        } else {
          resPaid = await InvoiceDataSource.getInvoiceME({
            customerIds: clientNumber,
            statusCompensation: 'PAID',
          }).catch(() => ({
            invoice: [],
          }));
          resNOTexpired = await InvoiceDataSource.getInvoiceME({
            customerIds: clientNumber,
            statusCompensation: 'NOT EXPIRED',
          }).catch(() => ({ invoice: [] }));
          resExpired = await InvoiceDataSource.getInvoiceME({
            customerIds: clientNumber,
            statusCompensation: 'EXPIRED',
          }).catch(() => ({ invoice: [] }));
        }

        let res = [];
        res = resPaid.invoice ? resPaid.invoice : [];
        res = res.concat(resNOTexpired.invoice ? resNOTexpired.invoice : []);
        res = res.concat(resExpired.invoice ? resExpired.invoice : []);
        setData(res);
      } catch (err) {
        setError(err);
        if (err.message.includes('401')) {
          show('alert', 'Sua sessão expirou, por favor faça o login novamente');
          authStorageService.logout();
        }
      }
      setLoading(false);
    },
    [authStorageService, show],
  );

  return { fetch, loading, error, data };
};
