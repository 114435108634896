import React from 'react';
import { useSelector } from 'react-redux';
import { isGKNSelector } from '@app/modules/components/customer-search/store/customer-search.selectors';
import { IShippingRequestColumns } from '@app/modules/shipping-request/shipping-request.interfaces';
import { getTableColumnsDefinitions } from '@app/modules/shipping-request/shipping-request.utils';
import { Body } from '@atomic/atm.typography';
import { TD, TR } from '@atomic/mol.table';
import { Color, FontWeight } from '@atomic/obj.constants';

export const ShippingRequestTableHeader: React.FC = () => {
  const isGKN = useSelector(isGKNSelector);
  const columns = getTableColumnsDefinitions(isGKN);

  return (
    <TR>
      {columns.map((column: IShippingRequestColumns, index: number) => {
        const tdColor = column.headerColor || Color.GrayLight;

        return (
          <TD key={index} color={tdColor} width={column.width}>
            <Body align='center' centralized color={Color.Black} bold={FontWeight.Bold}>
              {column.title}
            </Body>
          </TD>
        );
      })}
    </TR>
  );
};
