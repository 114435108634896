import styled from 'styled-components';
import { Color, FieldHeight, Spacing } from '@atomic/obj.constants';

export const AdminUserViewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AdminUserViewWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px ${Spacing.XLarge};
`;

interface AdminUserAddUserStyledProps {
  margingLeft: boolean;
}

export const AdminUserActionButtonStyled = styled.div`
  width: 15%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: ${(props: AdminUserAddUserStyledProps) => (props.margingLeft ? Spacing.Medium : '0px')};
`;

export const AdminUserContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AdminUserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

export const AdminUserDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${Spacing.Small} ${Spacing.Small};
  height: ${FieldHeight};
`;

export const AdminUserPermissionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spacing.Small} ${Spacing.Small};
`;

export const AdminUserPermissionRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${Spacing.Small};
`;

export const PermissionTagContainer = styled.div`
  margin-right: ${Spacing.Small};
`;

export const AdminUserBPsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spacing.Small} ${Spacing.Small};
`;

export const AdminUserBPsRow = styled.div`
  display: flex;
  flex-direction: row;
  line-height: ${Spacing.XXLarge};
`;

export const BPNumberContainer = styled.div`
  margin-left: ${Spacing.Large};
`;

export const AdminUserControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  justify-content: flex-start;
  align-items: flex-end;
  padding-right: ${Spacing.Small};
  padding-top: ${Spacing.XLarge};
`;

export const ButtonContainer = styled.div`
  width: auto;
  margin-bottom: ${Spacing.Medium};
`;

export const AdminUserContainerShimmer = styled.div`
  border: 1px solid ${Color.MediumGray};
  border-radius: 4px;
  margin: ${Spacing.XLarge};
  padding: 0px ${Spacing.Small} ${Spacing.Small} 0px;
`;

interface AdminUserItemShimmerProps {
  lastItem: boolean;
}

export const AdminUserItemShimmer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${Spacing.Small} 0px ${Spacing.Small} ${Spacing.Small};
  border-bottom: ${(props: AdminUserItemShimmerProps) => (props.lastItem ? '' : `1px solid ${Color.MediumGray}`)};
`;
