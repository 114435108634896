import { IOrderTabOpenState } from '../shipping-request.interfaces';

export const initialState: IOrderTabOpenState = {
  showShippingRequest: false,
  openShippingFeedback: false,
  tableRows: [],
  openReceiverModalIndex: null,
  receiverOptions: [],
  selectedNewReceivers: [],
  openAlertQtyModal: false,
  hasOldSalesOrderAlert: false,
  openShippingRequestSuggestionModalIndex: null,
  suggestionOptions: [],
};
