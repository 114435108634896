// eslint-disable-next-line prettier/prettier
import type { RootState } from '@app/core/redux/store';
import { IShippingRequestRow } from '../shipping-request.interfaces';
import { getStockQuantityTotal } from '../shipping-request.utils';

export const selectedRowsByPlantSelector = (state, plantCode: string): IShippingRequestRow[] => {
  return state.shippingRequest.tableRows.filter(row => row.plantCode === plantCode);
};

export const totalStockQuantitySelector = (state, plantCode: string): number => {
  const filteredRows = state.shippingRequest.tableRows.filter(row => row.plantCode === plantCode);
  const total = getStockQuantityTotal(filteredRows);
  return total;
};

export const totalRequestedShippingQuantitySelector = (state: RootState, plantCode: string): number => {
  const filteredRows = state.shippingRequest.tableRows.filter(row => row.plantCode === plantCode);
  const total = filteredRows.reduce((sum, row) => {

    if (row.requestedShippingQuantity) {
      return sum + parseFloat(row.requestedShippingQuantity.toString());
    }
    return sum + parseFloat(row.stockQuantity.toString());
  }, 0);


  return total;
};

export const plantsDistinctSelector = (state): string[] => {
  const plantCodesSelected: string[] = state.shippingRequest.tableRows.map(row => row.plantCode);
  return [...new Set(plantCodesSelected)];
};

export const stockQunatityTotalRowsSelector = (state): number => {
  const total = state.shippingRequest.tableRows.reduce(
    (accumulator: number, row: IShippingRequestRow) => accumulator + row.stockQuantity,
    0,
  );
  return total;
};

export const selectStockQuantitySelector = (state, heat, salesOrder): number => {
  if (state.shippingRequest.tableRows) {
    const index = state.shippingRequest.tableRows.findIndex(row => row.salesOrder === salesOrder && row.heat === heat);
    return state.shippingRequest.tableRows[index]?.stockQuantity;
  }
  return null;
};

export const selectQtyWishedSelector = (state, heat, salesOrder): number => {
  if (state.shippingRequest.tableRows) {
    const index = state.shippingRequest.tableRows.findIndex(row => row.salesOrder === salesOrder && row.heat === heat);
    return state.shippingRequest.tableRows[index]?.requestedShippingQuantity;
  }
  return null;
};
