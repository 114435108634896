import { ColDef, GridReadyEvent, RowNode, SelectionChangedEvent } from 'ag-grid-community';
import React, { MutableRefObject, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { columnsTabLayoutSelector } from '@app/core/redux/grid-layout/grid-layout.selectors';
import { setTabColumns } from '@app/core/redux/grid-layout/grid-layout.store';
import { postGridLayout } from '@app/core/redux/grid-layout/thunks/grid-layout-post.thunk';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { LayoutTabs } from '@app/models/layout.model';
import { AgGrid } from '@app/modules/components';
import { AgGridRowCounter } from '@app/modules/order/grid-counter/grid-row-counter.component';
import { FlashMessageTargetName } from '@app/providers';
import { useFlashMessage } from '../components/flash-message.hook';
import { OrderTabErrorRendererComponent } from './order-tabs/ErrorRenderer/error-renderer';
import { handleStockTabColumn } from './order-tabs/MI/tab-stock/utils/handler-utils';
import { AgGridOrderWrapperStyled, OrderTabStyled } from './order-tabs/order-tabs.component.style';
import { OrdersStrings } from './orders.string';
import { handleDebounce, handleGridFilter, handleGridReady } from './utils/handler-utils';

interface HandleSelectionChangeParams {
  e: SelectionChangedEvent;
  setSelectedRows: (value: any[]) => void;
}

interface OrderTabGridProps {
  gridEvent: MutableRefObject<GridReadyEvent | null>;
  performRequest: () => any;
  setFilteredRows: (value: any[]) => any;
  setSelectedRows?: (value: any[]) => any;
  strings: any;
  tabName: LayoutTabs;
  data: any[];
  error: unknown;
  loading: boolean;
  frameworkComponents?: { [renderer: string]: any };
  handleSelectionChange?: (params: HandleSelectionChangeParams) => void;
  showCriticalFilter?: boolean;
  showActionBar?: boolean;
}

export const OrderTabGrid: React.FC<OrderTabGridProps> = ({
  gridEvent,
  performRequest,
  setFilteredRows,
  setSelectedRows,
  strings,
  tabName,
  data,
  error,
  loading,
  frameworkComponents,
  handleSelectionChange,
  showCriticalFilter,
  showActionBar,
}) => {
  const [columnState, setColumnState] = useState([]);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { toogleVisible } = useSelector((state: RootState) => state.gridLayout);
  const columnDef = useSelector(columnsTabLayoutSelector(tabName, userInfo?.language));

  useEffect(() => {
    setColumnState(columnDef);
  }, [toogleVisible]);

  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  const dispatch = useDispatch<AppThunkDispatch>();
  const handleGridFilterConfigured = gridApi => {
    handleGridFilter({ gridApi, setFilteredRows: value => dispatch(setFilteredRows(value)) });
  };
  const handleSelectionChangeConfigured = (e: SelectionChangedEvent) => {
    return handleSelectionChange({ e, setSelectedRows: value => dispatch(setSelectedRows(value)) });
  };

  const handleGridReadyConfigured = (e: GridReadyEvent) => handleGridReady({ e, gridEvent });

  const handleSuccess = () => {
    const columns: ColDef[] = gridEvent.current?.api?.getColumnDefs();
    show('success', OrdersStrings[userInfo?.language]?.successSaveLayout);
    dispatch(setTabColumns({ name: tabName, cols: columns }));
  };

  const onSortChange = () => {
    const columns: ColDef[] = gridEvent.current?.api?.getColumnDefs();
    setColumnState(columns);
    handleDebounce(columns, () =>
      dispatch(postGridLayout({ tab: tabName, handleSuccess, columns, language: userInfo.language })),
    );
  };

  const onColumnChange = () => {
    const columns: ColDef[] = gridEvent.current?.api?.getColumnDefs();
    setColumnState(columns);
    handleDebounce(columns, () =>
      dispatch(postGridLayout({ tab: tabName, handleSuccess, columns, language: userInfo.language })),
    );
  };

  const handleRowClass = row => {
    return row.data.activateCritical ? 'row-bold' : '';
  };

  const getFilteredCriticalItemsRowCount = () => {
    let criticalItemRowCount = 0;

    if (gridEvent?.current?.api) {
      gridEvent.current.api.forEachNodeAfterFilterAndSort((rowNode: RowNode) => {
        if (rowNode.data.activateCritical) {
          criticalItemRowCount++;
        }
      });
    }

    return criticalItemRowCount;
  };

  handleStockTabColumn(tabName, columnState);

  if (error) {
    return (
      <OrderTabErrorRendererComponent
        errorMessage={strings.error}
        action={() => dispatch(performRequest())}
        loading={loading}
      />
    );
  }

  return (
    <OrderTabStyled>
      <div id={`${tabName}-grid`}>
        <AgGridOrderWrapperStyled showActionBar={showActionBar}>
          <AgGrid
            columnDef={columnState}
            onGridReady={handleGridReadyConfigured}
            onFilterChanged={handleGridFilterConfigured}
            onSelectionChange={handleSelectionChangeConfigured}
            rowData={data}
            loading={loading}
            onColumnChange={onColumnChange}
            onSortChange={onSortChange}
            frameworkComponents={frameworkComponents}
            error={
              error && {
                error: error,
                message: strings.error,
              }
            }
            getRowClass={handleRowClass}
          />
        </AgGridOrderWrapperStyled>
        {gridEvent?.current && (
          <AgGridRowCounter
            rows={gridEvent.current.api.getDisplayedRowCount()}
            criticalItemRows={getFilteredCriticalItemsRowCount()}
            showCriticalFilter={showCriticalFilter}
          />
        )}
      </div>
    </OrderTabStyled>
  );
};
