import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { OrdersStrings } from '../../orders.string';
import { OrderTabErrorStyled } from '../order-tabs.component.style';

export interface OrderTabOpenComponentProps {
  loading: boolean;
  errorMessage: string;
  action: (params?: any) => void;
}

export const OrderTabErrorRendererComponent: React.FC<OrderTabOpenComponentProps> = ({
  loading,
  errorMessage,
  action,
}) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);

  return (
    <OrderTabErrorStyled>
      {errorMessage}
      <FlexRow mt={Spacing.XLarge}>
        <Button loading={loading} kind='secondary' onClick={action}>
          {OrdersStrings[userInfo?.language].common.tryAgain}
        </Button>
      </FlexRow>
    </OrderTabErrorStyled>
  );
};
