import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Table } from '@atomic/mol.table';
import { VSeparator } from '@atomic/obj.grid';
import { IShippingRequestRow } from '../../shipping-request.interfaces';
import { ShippingRequestSelectedItemTableBody } from './partials/shipping-request-selected-item-table-body/shipping-request-selected-item-table-body.component';
import { ShippingRequestSelectedItemTableHeader } from './partials/shipping-request-selected-item-table-header/shipping-request-selected-item-table-header.component';
import {
  ShippingRequestSelectedItemTableOptionsScroll,
  ShippingRequestSelectedItemTableScroll,
  ShippingRequestSelectedItemTableWrapper,
} from './shipping-request-selected-item-table.style';

interface IShippingRequestSelectedItemTableProps {
  item: IShippingRequestRow;
}

export const ShippingRequestSelectedItemTable: React.FC<IShippingRequestSelectedItemTableProps> = ({ item }) => {
  const { suggestionOptions } = useSelector((state: RootState) => state.shippingRequest);

  const handleTableWrapper = () => {
    return (
      <ShippingRequestSelectedItemTableWrapper>
        <Table collapse>
          <ShippingRequestSelectedItemTableHeader />
          <ShippingRequestSelectedItemTableBody item={item} />
          <VSeparator />
        </Table>
      </ShippingRequestSelectedItemTableWrapper>
    );
  };

  if (suggestionOptions?.length > 1) {
    return (
      <ShippingRequestSelectedItemTableOptionsScroll>
        {handleTableWrapper()}
      </ShippingRequestSelectedItemTableOptionsScroll>
    );
  } else {
    return <ShippingRequestSelectedItemTableScroll>{handleTableWrapper()}</ShippingRequestSelectedItemTableScroll>;
  }
};
