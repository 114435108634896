import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage';
import { SLICE_NAME } from '../preventive-stop-modal.constants';
import { IPreventiveStopModalState } from '../preventive-stop-modal.interfaces';
import { initialState } from './preventive-stop-modal.initial';
import { reducers } from './preventive-stop-modal.reducers';

export const preventiveStopModalSlice = createSlice<
  IPreventiveStopModalState,
  SliceCaseReducers<IPreventiveStopModalState>
>({
  name: SLICE_NAME,
  initialState,
  reducers,
});

export const { reset, setVisited, setLastVisitedDate } = preventiveStopModalSlice.actions;

const persistConfig = {
  key: 'preventive-stop-persist-reducer',
  storage,
};

export const preventiveStopPersistReducer = persistReducer(persistConfig, preventiveStopModalSlice.reducer);
