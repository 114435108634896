import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET, endpoint } from '@app/data/http';
import { PatchAssetsParams } from '@app/data/http/quote-assets-params.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export const editAssets = async (params?: PatchAssetsParams) => {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    client_id: CXP_KEY_CLIENT_ID,
    client_secret: CXP_KEY_CLIENT_SECRET,
    access_token: user.accessToken,
    'Content-Type': 'application/json',
    Accept: '*/*',
    Language: params.language,
    'sso-access': user.ssoAccess,
  };

  const data = { client_visible: params.isClientVisible };

  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
    endpoint.ANALYSIS_ASSETS
  }/${params.assetKey}`;

  await axios.patch(url, data, { headers: defaultHeaders });
};

export const useEditAssets = onSuccess => {
  return useMutation(editAssets, {
    onSuccess,
  });
};
