import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { endpoint, CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from '@app/data/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export async function getClientList(params) {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    client_id: CXP_KEY_CLIENT_ID,
    client_secret: CXP_KEY_CLIENT_SECRET,
    access_token: user.accessToken,
    'Content-Type': 'application/json',
    Accept: '*/*',
    language: params.language,
    'sso-access': user.ssoAccess,
  };

  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.CLIENTS}?client=${params.client}`;

  const { data, status } = await axios.get(url, { headers: defaultHeaders });

  if (status === 200) {
    return data;
  } else {
    return [];
  }
}

export function useGetClientsList(params?: any, onSuccess?) {
  return useQuery(['getClientsList'], () => getClientList(params), {
    onSuccess,
    enabled: params.enabled && params.client !== undefined,
    refetchOnWindowFocus: false,
    retry: false,
  });
}
