import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET, endpoint } from '@app/data/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export const postDocument = async (params?: any) => {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    client_id: CXP_KEY_CLIENT_ID,
    client_secret: CXP_KEY_CLIENT_SECRET,
    Authorization: 'Bearer ' + user.accessToken,
    access_token: user.accessToken,
    'Content-Type': 'application/json',
    Accept: '*/*',
    'sso-access': user.ssoAccess,
  };

  const form = new FormData();

  form.append('title', params.title);
  form.append('documentType', params.documentType);
  if (params.expirationDate) {
    form.append('expirationDate', params.expirationDate);
  }
  form.append('file', params.file);

  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.DOCUMENTS}`;

  const { data: dataResponse } = await axios.post(url, form, { headers: defaultHeaders });

  return dataResponse;
};

export const usePostDocument = (onSuccess?, onError?) => {
  const queryClient = useQueryClient();
  return useMutation(postDocument, {
    onSuccess,
    onError,
    onSettled: () => {
      queryClient.invalidateQueries(['getDocumentList']);
    },
  });
};
