import React from 'react';
import { useDispatch } from 'react-redux';
import { Material } from '@app/models';
import { hasInputError } from '@app/modules/order-input/order-input-validation';
import { MaterialCell, MaterialUnity } from '@app/modules/order-input/order-input.constants';
import { RadioField } from '@atomic/atm.radio';
import { FlexColumn } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { FormField } from '@atomic/obj.form';
import { handleAmountAndUnityChange } from '../utils';
import { UnityErrorWrapper } from './unity-field.style';

interface IUnityField {
  material: Material;
}

export const UnityField: React.FC<IUnityField> = ({ material }) => {
  const dispatch = useDispatch();

  return (
    <FlexColumn pl={Spacing.Medium}>
      <FormField
        value={material.unity}
        onValueChange={value => {
          const updatedMaterial = { ...material };
          updatedMaterial.unity = value.toString();
          handleAmountAndUnityChange(updatedMaterial, dispatch);
        }}
        name='unity'
      >
        <RadioField id={MaterialUnity.Ton}>{MaterialUnity.Ton}</RadioField>
        <RadioField id={MaterialUnity.Kg}>{MaterialUnity.Kg}</RadioField>
      </FormField>
      <UnityErrorWrapper>{hasInputError(material, MaterialCell.Unity)}</UnityErrorWrapper>
    </FlexColumn>
  );
};
