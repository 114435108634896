import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStore } from '@app/core/global-store.service';
import { RootState } from '@app/core/redux/store';
import { UserStore, useUserStore } from '@app/providers';
import { H2 } from '@atomic/atm.typography';
import { notes } from '../../../nps-modal.constats';
import { InpsModalStrings, Strings } from '../../../nps.string';
import { setNote } from '../../../store/nps-modal.store';
import { GradeNote, GradeWrapper } from './nps-modal-grade.style';

export const NpsModalGrade: React.FC = () => {
  const { user } = useStore<UserStore>(useUserStore);
  const strings: InpsModalStrings = Strings[user.language];
  const dispatch = useDispatch();
  const handleClick = value => dispatch(setNote(value));
  const { note: value } = useSelector((state: RootState) => state.nps);

  return (
    <>
      <H2>{strings.gradeQuestion}</H2>
      <GradeWrapper>
        {notes.map(note => {
          return (
            <GradeNote
              selected={note.value === value}
              key={note.value}
              color={note.color}
              onClick={() => handleClick(note.value)}
            >
              {note.value}
            </GradeNote>
          );
        })}
      </GradeWrapper>
    </>
  );
};
