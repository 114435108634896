/* eslint-disable complexity */
import { format } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { DatePicker } from '@atomic/atm.date-picker';
import { Dropzone } from '@atomic/atm.dropzone';
import { TagCheckboxField, TagCheckboxGroup } from '@atomic/atm.tag-checkbox';
import { TagRadioField } from '@atomic/atm.tag-radio';
import { TextField } from '@atomic/atm.text-field';
import { Body } from '@atomic/atm.typography';
import { Color } from '@atomic/obj.constants';
import { Form, Validators } from '@atomic/obj.form';
import { ChatContext } from '../chat.context';
import { ChatStrings } from '../chat.string';
import { ChatResultItems } from './result/chat-result.component';
import { FieldWrapper, MainQuestion, QuestionWrapper, SecondaryQuestion } from './result/chat-result.component.style';

interface Field {
  question: string;
  typeField: string;
  key: string;
  display: boolean;
  label?: string;
  conditionalField?: boolean;
  options?: any;
  conditional?: any;
  mandatory?: boolean;
  detail?: string;
}
export interface ChatFlowProps {
  field: Field;
  handleValue: (value: any, keyField?: string, label?: string, conditional?: boolean, type?: string) => void;
  handleUpload: (file: File[], key?: string, label?: string) => void;
}

export const ChatFlowFields = (props: ChatFlowProps) => {
  const { chatResult } = React.useContext(ChatContext);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = ChatStrings[userInfo.language].newForm.messages;

  function checkInitialValue(field, result: ChatResultItems[]) {
    if (result[field.key]) {
      return !result[field.key]?.value ? [] : result[field.key]?.value;
    }
  }

  function checkValidator(result: ChatResultItems[], field, mandatory) {
    if (mandatory) {
      if (result) {
        if (result[field]?.value) {
          return [];
        }
      }
      return [Validators.Required(strings.mandatoryAlert)];
    } else {
      return [];
    }
  }

  return (
    props.field.display && (
      <QuestionWrapper data-testid={`field${props.field.key}`}>
        <MainQuestion>{props.field.question}</MainQuestion>
        {props.field.detail && <SecondaryQuestion>{props.field.detail}</SecondaryQuestion>}

        <FieldWrapper>
          {props.field?.typeField === 'radioButton' ? (
            <Form.Field
              name={props.field.key}
              onValueChange={value =>
                props.handleValue(value, props.field.key, props.field.label, props.field.conditionalField, 'text')
              }
              validators={checkValidator(chatResult, props.field?.key, props.field?.mandatory)}
              initialValue={chatResult && chatResult[props.field?.key]?.value}
            >
              {props.field?.options.map(option => (
                <TagRadioField key={option.key} id={option} type='success' disabled={false}>
                  <span data-testid={`option${option.key}`}>{option.label}</span>
                </TagRadioField>
              ))}
            </Form.Field>
          ) : props.field?.typeField === 'checkbox' ? (
            <Form.Field
              name={props.field.key}
              onValueChange={value =>
                props.handleValue(value, props.field.key, props.field.label, props.field.conditionalField, 'textArray')
              }
              validators={props?.field?.mandatory ? [Validators.Required(strings.mandatoryAlert)] : []}
              value={checkInitialValue(props.field, chatResult)}
            >
              <TagCheckboxGroup>
                {props.field?.options.map(option => (
                  <TagCheckboxField key={option.key} id={option.key}>
                    <span data-testid={`option${option.key}`}>{option.label}</span>
                  </TagCheckboxField>
                ))}
              </TagCheckboxGroup>
            </Form.Field>
          ) : props.field?.typeField === 'file' ? (
            <>
              <Dropzone
                limitAcceptance='.pdf'
                onDropAccepted={value => props.handleUpload(value, props.field.key, props.field.label)}
              />
              {chatResult && chatResult[props.field?.key]?.value && (
                <Body bold={600} color={Color.Accessory}>{`${chatResult[props.field.key]?.label}: ${
                  chatResult[props.field?.key]?.file[0].name
                }`}</Body>
              )}
            </>
          ) : props.field?.typeField === 'date' ? (
            <Form.Field
              name={props.field.key}
              validators={checkValidator(chatResult, props.field?.key, props?.field?.mandatory)}
            >
              <DatePicker
                onValueChange={value =>
                  props.handleValue(
                    value !== null && value !== '' ? format(value, 'yyyy-MM-dd HH:ii') : value,
                    props.field.key,
                    props.field.label,
                    false,
                    'date',
                  )
                }
                language={userInfo.language}
                startDate={
                  chatResult && chatResult[props.field?.key]?.value !== undefined
                    ? new Date(chatResult[props.field.key]?.value)
                    : null
                }
                maxDate={new Date()}
              />
            </Form.Field>
          ) : (
            <Form.Field
              name={props.field.key}
              validators={props?.field?.mandatory ? [Validators.Required(strings.mandatoryAlert)] : []}
              initialValue={
                chatResult !== null && chatResult[props.field?.key]?.value ? chatResult[props.field?.key].value : null
              }
            >
              <TextField
                id={props.field.key}
                onBlur={value =>
                  props.handleValue(value.target.value, props.field.key, props.field.label, false, 'text')
                }
              ></TextField>
            </Form.Field>
          )}
        </FieldWrapper>
      </QuestionWrapper>
    )
  );
};
