import { RootState } from '@app/core/redux/store';
import { IAnnouncementItem } from '@app/data/http/announcement.dto';
import {
  ONBOARDING_BRANDS_KEY,
  ONBOARDING_ORDER_MI_KEY,
} from '@app/modules/components/announcement-modal/announcement-modal.constants';
import { MarketType } from '../user.store';

export const newsModalOpenedSelector = state => {
  const { openNewsModal, openNewsModalHistory, openNpsModal, openPreventiveStopModal } = state.navigation;

  return (
    state.customerPersist.currentMarket === MarketType.Internal &&
    (openNewsModal || openNewsModalHistory) &&
    !openNpsModal &&
    !openPreventiveStopModal
  );
};

export const preventiveStopOpenedSelector = state => {
  const {
    openBPsModal,
    openNewsModal,
    openNewsModalHistory,
    openNpsModal,
    openScheduleModal,
    openPreventiveStopModal,
  } = state.navigation;

  return (
    (!openNewsModal || state.customerPersist.currentMarket === MarketType.External) &&
    !openNewsModalHistory &&
    !openBPsModal &&
    !openNpsModal &&
    !openScheduleModal &&
    openPreventiveStopModal
  );
};

export const announcementOpenedSelector = state => {
  const {
    openBPsModal,
    openNewsModal,
    openNewsModalHistory,
    openNpsModal,
    openScheduleModal,
    openPreventiveStopModal,
  } = state.navigation;

  return (
    !openNewsModal &&
    !openNewsModalHistory &&
    !openBPsModal &&
    !openNpsModal &&
    !openScheduleModal &&
    !openPreventiveStopModal
  );
};

export const alredyOnboardingBrandsSelector = (state: RootState) => {
  if (state.navigation.onboarding) {
    return !!state?.navigation?.onboarding?.announcements?.find(
      (item: IAnnouncementItem) => item.key === ONBOARDING_BRANDS_KEY,
    );
  }
  return false;
};

export const alredyOnboardingOpenOrderMiSelector = (state: RootState) => {
  if (state.navigation.onboarding) {
    return !!state?.navigation?.onboarding.announcements?.find(
      (item: IAnnouncementItem) => item.key === ONBOARDING_ORDER_MI_KEY,
    );
  }
  return false;
};
