import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { endpoint, CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from '@app/data/http';
import { GetAnalysisParams } from '@app/data/http/quote-params.dto';
import { mapSimiliarMaterials, SimilarsMaterialResponse } from '@app/data/http/quote.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export async function getSimilarsMaterials(params?: GetAnalysisParams): Promise<SimilarsMaterialResponse> {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    client_id: CXP_KEY_CLIENT_ID,
    client_secret: CXP_KEY_CLIENT_SECRET,
    Authorization: 'Bearer ' + user.accessToken,
    access_token: user.accessToken,
    'Content-Type': 'application/json',
    Accept: '*/*',
    'Access-Control-Allow-Origin': 'egerdau.com.br',
    language: params.language,
    'sso-access': user.ssoAccess,
  };

  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.id}${
    endpoint.SIMILAR_MATERIAL
  }`;

  const { data, status } = await axios.get(url, { headers: defaultHeaders });

  if (status === 200) {
    return data;
  } else {
    return null;
  }
}

export function useTechnicalAnalysisSimilarsMaterials(params: GetAnalysisParams, onError?) {
  return useQuery(['getSimilarsMaterials', params], () => getSimilarsMaterials(params), {
    onError,
    select: data => {
      return mapSimiliarMaterials(data);
    },
    enabled: params.enable,
    refetchOnWindowFocus: false,
    retry: false,
  });
}
