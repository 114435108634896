import styled from 'styled-components';
import { Body } from '@atomic/atm.typography';
import { Color } from '@atomic/obj.constants';

export const ButtonWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

interface SSOProps {
  textDecoration: string;
  disabled: boolean;
}

export const SSOButtonWrapperStyled = styled(Body)`
  color: ${Color.Primary};
  opacity: ${(props: SSOProps) => (props.disabled ? '25%' : '100%')};
  text-decoration: ${(props: SSOProps) => (props.textDecoration ? props.textDecoration : 'auto')};
`;
