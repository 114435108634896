import Container from 'typedi';
import { AuthStorageService } from '../storage/auth.storage';
import { AuthHttpRequestBuilder } from './auth-http-request.builder';

const endpoint = {
  CHANGE_PASSWORD: '/change-password',
};

export interface ChangePasswordResponse {
  eGerdauResponse: {
    passwordChanged: boolean;
  };
}

export interface ChangePasswordParameters {
  oldPassword: string;
  newPassword: string;
  uid: string;
}

export const ChangePasswordDataSource = {
  postChangePassword: (params: ChangePasswordParameters): Promise<ChangePasswordResponse> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(AuthHttpRequestBuilder)
      .post(endpoint.CHANGE_PASSWORD)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .data({
        oldPassword: params.oldPassword,
        newPassword: params.newPassword,
        uid: params.uid,
      })
      .execute();
  },
};
