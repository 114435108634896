import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { endpoint, CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from '@app/data/http';
import { PatchSkuParams } from '@app/data/http/quote-status-params.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export const editSkus = async (params?: PatchSkuParams) => {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    client_id: CXP_KEY_CLIENT_ID,
    client_secret: CXP_KEY_CLIENT_SECRET,
    Authorization: 'Bearer ' + user.accessToken,
    access_token: user.accessToken,
    'Content-Type': 'application/json',
    Accept: '*/*',
    Language: params.language,
    'sso-access': user.ssoAccess,
  };

  const skus = params.skus;

  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
    endpoint.ANALYSIS_STATUS
  }${endpoint.SKU}`;

  const { data: dataResponse } = await axios.patch(url, { skus }, { headers: defaultHeaders });

  return dataResponse;
};

export const useEditSkus = (onSuccess?, onError?) => {
  const queryClient = useQueryClient();
  return useMutation(editSkus, {
    onSuccess,
    onError,
    onMutate: async () => {
      await queryClient.cancelQueries(['getTechnicalAnalysis']);
      const previousData = queryClient.getQueriesData(['getTechnicalAnalysis']);
      return {
        previousData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries(['getTechnicalAnalysis']);
    },
  });
};
