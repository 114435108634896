import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import {
  BilledOrderGraph,
  ConfirmedOrderGraphs,
  FinanceMatchedMatchesGraphs,
  FinanceOpenMatchesGraphs,
  OpenOrderGraphs,
  StockOrderGraphs,
} from '@app/models/dashboard.model';
import { CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from './constants';
import {
  BilledOrderGraphDto,
  ConfirmedOrderGraphsDto,
  FinanceMatchedMatchesGraphDto,
  FinanceOpenMatchesGraphDto,
  mapBilledOrdersDashboard,
  mapConfirmedOrdersDashboard,
  mapFinanceMatchedMatchesDashboard,
  mapFinanceOpenMatchesDashboard,
  mapOpenOrdersDashboard,
  mapStockOrdersDashboard,
  OpenOrderGraphsDto,
  StockOrderGraphsDto,
} from './dashboard.dto';

const endpoint = {
  DASHBOARD: '/dashboard',
  OPEN_ORDERS: '/open-orders',
  CONFIRMED_ORDERS: '/confirmed-orders',
  BILLED_ORDERS: '/month-daily-billing',
  STOCK_ORDERS: '/stock',
  INTERNAL_MARKET: '/mi',
  FINANCE: '/financas',
  MATCHES: '/partidas',
  MATCHED: '/compensadas',
  OPEN: '/abertas',
};

export const DashboardDataSource = {
  getOpenOrders: (clientNumber: string): Promise<OpenOrderGraphs> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.DASHBOARD + endpoint.OPEN_ORDERS)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        client_id: CXP_KEY_CLIENT_ID,
        client_secret: CXP_KEY_CLIENT_SECRET,
        access_token: user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .params({ customerId: clientNumber })
      .interceptors({
        afterSuccess: async (res: OpenOrderGraphsDto): Promise<OpenOrderGraphs> => mapOpenOrdersDashboard(res),
      })
      .execute();
  },

  getConfirmedOrders: (clientNumber: string): Promise<ConfirmedOrderGraphs> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.DASHBOARD + endpoint.CONFIRMED_ORDERS)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        client_id: CXP_KEY_CLIENT_ID,
        client_secret: CXP_KEY_CLIENT_SECRET,
        access_token: user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .params({ customerIds: clientNumber })
      .interceptors({
        afterSuccess: async (res: ConfirmedOrderGraphsDto): Promise<ConfirmedOrderGraphs> =>
          mapConfirmedOrdersDashboard(res),
      })
      .execute();
  },

  getBilledOrders: (clientNumber: string): Promise<BilledOrderGraph> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.DASHBOARD + endpoint.BILLED_ORDERS)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        client_id: CXP_KEY_CLIENT_ID,
        client_secret: CXP_KEY_CLIENT_SECRET,
        access_token: user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .params({ customerIds: clientNumber })
      .interceptors({
        afterSuccess: async (res: BilledOrderGraphDto): Promise<BilledOrderGraph> => mapBilledOrdersDashboard(res),
      })
      .execute();
  },

  getStockOrders: (clientNumber: string): Promise<StockOrderGraphs> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.DASHBOARD + endpoint.STOCK_ORDERS)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        client_id: CXP_KEY_CLIENT_ID,
        client_secret: CXP_KEY_CLIENT_SECRET,
        access_token: user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .params({ customerIds: clientNumber })
      .interceptors({
        afterSuccess: async (res: StockOrderGraphsDto): Promise<StockOrderGraphs> => mapStockOrdersDashboard(res),
      })
      .execute();
  },

  getFinanceMatchedMatches: (clientNumber: string): Promise<FinanceMatchedMatchesGraphs> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.INTERNAL_MARKET + endpoint.DASHBOARD + endpoint.FINANCE + endpoint.MATCHES + endpoint.MATCHED)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        client_id: CXP_KEY_CLIENT_ID,
        client_secret: CXP_KEY_CLIENT_SECRET,
        access_token: user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .params({ customerIds: clientNumber })
      .interceptors({
        afterSuccess: async (res: FinanceMatchedMatchesGraphDto): Promise<FinanceMatchedMatchesGraphs> =>
          mapFinanceMatchedMatchesDashboard(res),
      })
      .execute();
  },

  getFinanceOpenMatches: (clientNumber: string): Promise<FinanceOpenMatchesGraphs> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.INTERNAL_MARKET + endpoint.DASHBOARD + endpoint.FINANCE + endpoint.MATCHES + endpoint.OPEN)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        client_id: CXP_KEY_CLIENT_ID,
        client_secret: CXP_KEY_CLIENT_SECRET,
        access_token: user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .params({ customerIds: clientNumber })
      .interceptors({
        afterSuccess: async (res: FinanceOpenMatchesGraphDto): Promise<FinanceOpenMatchesGraphs> =>
          mapFinanceOpenMatchesDashboard(res),
      })
      .execute();
  },
};
