/* eslint-disable complexity */
import { differenceInMinutes } from 'date-fns';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Container } from 'typedi/Container';
import { LogAnalytics } from '@app/core/analytics';
import { useStore } from '@app/core/global-store.service';
import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { RootState } from '@app/core/redux/store';
import { QuoteStatusDataSource } from '@app/data/http';
import { PatchCancelQuotationParams } from '@app/data/http/quote-status-params.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { EventTypes } from '@app/models/analytics.model';
import {
  CurrentStatus,
  ExtraNotifications,
  FullAnalysisData,
  Stages,
  TechnicalAnalysisExtraInfos,
} from '@app/models/quote.model';
import { AppPath } from '@app/modules/app/route-constants';
import {
  hasEPPAccessSelector,
  hasPEDAccessSelector,
  hasRTCAccessSelector,
  hasSellerAccessSelector,
  quoteIsInternalUserSelector,
} from '@app/modules/auth/store/auth.selectores';
import { ErrorPlaceholder } from '@app/modules/components/error.placeholder';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { TechnicalAnalysisEvents } from '@app/modules/quote/quote.analytics';
import { QuoteStrings } from '@app/modules/quote/quote.string';
import { FlashMessageTargetName, UserStore, useUserStore } from '@app/providers';
import { useVerifySession } from '@app/utils/verify-session';
import { Button } from '@atomic/atm.button';
import { Frame } from '@atomic/atm.frame';
import { PreviewMessage } from '@atomic/mol.preview-message';
import { Hbox } from '@atomic/obj.box';
import { Feedback, FeedbackAnalytics, FeedbackType } from '@atomic/obj.feedback';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { TechnicalAnalysisAssetsModal, TechnicalAnalysisAssetsProvider } from './assets';
import { AnalysisApqpModal } from './components/analysis-apqp-modal.component';
import { AnalysisTypeModal } from './components/analysisTypeModal';
import { AttributeStepModal } from './components/attribute-step-modal.component';
import { TechnicalAnalysisSimilarMaterialsModal } from './components/technical-analysis-similar-materials-modal.component';
import { useChangeStatus } from './hooks/useChangeStatus';
import { useCheckProcessingStatus } from './hooks/useCheckProcessingStatus';
import { useSendToBagg } from './hooks/useSendToBagg';
import { getTechnicalAnalysis, useTechnicalAnalysis } from './hooks/useTechnicalAnalysis';
import { useTechnicalAnalysisSimilarsMaterials } from './hooks/useTechnicalAnalysisSimilars';
import { TechnicalAnalysisStatusModal, TechnicalAnalysisStatusData } from './status';
import { StatusCancelModal } from './status/statusCancelModal';
import { StatusPendingModal } from './status/statusPendingModal';
import { TechnicalAnalysisHeader } from './technical-analysis-header.component';
import { TechnicalAnalysisPlantsTab } from './technical-analysis-plants-tab';
import { TechnicalAnalysisTab } from './technical-analysis-tab.component';
import { TechnicalAnalysisContext } from './technical-analysis.context';
import { TechnicalAnalysisShimmer } from './technical-analysis.shimmer';
import { TechnicalAnalysisStrings } from './technical-analysis.string';
import { previewMessagesAlert } from './utils/previewMessagesAlert';

interface TechnicalAnalysisProps {
  id: number;
}

const recurrentProduct = 'RECURRENT_PRODUCT';

const chekdatesForModalAssets = (starDate: string) => {
  const newStartDate = new Date(starDate);
  const endDate = new Date();
  return differenceInMinutes(endDate, newStartDate);
};

export const TechnicalAnalysis: React.FC<TechnicalAnalysisProps> = props => {
  const authStorageService: AuthStorageService = Container.get(AuthStorageService);
  const [statusModalOpened, setStatusModalOpened] = React.useState(false);
  const [assetsModalOpened, setAssetsModalOpened] = React.useState(false);
  const [assetsModalCount, setAssetsModalCount] = React.useState(0);
  const [attributeStepModalOpened, setAttributeStepModalOpened] = React.useState(false);
  const [openSimilarMaterialsModal, setOpenSimilarMaterialsModal] = React.useState(false);
  const [analysisApqpModalOpened, setAnalysisApqpModalOpened] = React.useState(false);
  const [analysisTypeModalOpened, setAnalysisTypeModalOpened] = React.useState(false);
  const [pendingModalOpened, setPendingModalOpened] = React.useState(false);
  const [cancelModalOpened, setCancelModalOpened] = React.useState(false);
  const [openFeedback, setOpenFeedback] = React.useState(false);
  const [selectedStage, setSelectedStage] = React.useState<Stages>(null);
  const [totalAssets, setTotalAssets] = React.useState<number>(0);
  const [extraAttribute, setExtraAttribute] = React.useState<TechnicalAnalysisExtraInfos>({
    PPAP: null,
    PRIORITY: null,
    PARTNUMBER: null,
  });
  const [extraNotifications, setExtraNotifications] = React.useState<ExtraNotifications>({
    APQP: null,
    PE: null,
    FMEAS: null,
    FMEAS_EDIT: null,
    NEWPRODUCTEPP: null,
    NEWPRODUCTEPPJUSTIFY: null,
  });
  const [analysisType, setAnalysisType] = React.useState<string | null>(null);
  const [tooltipApqpStatus, setTooltipApqpStatus] = React.useState(false);
  const [showLabelModalAssetsFlow, setShowLabelModalAssetsFlow] = React.useState(false);
  const [forbiddenAccess, setForbiddenAccess] = React.useState(false);
  const hasRTCAccess = useSelector(hasRTCAccessSelector);
  const hasEPPAccess = useSelector(hasEPPAccessSelector);
  const hasPEDAccess = useSelector(hasPEDAccessSelector);
  const hasSellerAccess = useSelector(hasSellerAccessSelector);

  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  const {
    analysis,
    setAnalysis,
    setAnalysisId,
    fullError,
    fullLoading,
    attributeError,
    contextError,
    setBlockLoading,
  } = React.useContext(TechnicalAnalysisContext);

  const [tabIndex, setTabIndex] = React.useState(analysis?.currentTab ? analysis?.currentTab : 0);

  const history = useHistory();

  const { user } = useStore<UserStore>(useUserStore);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);
  const strings = TechnicalAnalysisStrings[userInfo.language].components;
  const verifySession = useVerifySession();

  const handleGetAnalysisSuccess = (response: FullAnalysisData) => {
    LogAnalytics.pageView(TechnicalAnalysisEvents.TechnicalAnalysisView);

    setAnalysis(response);
    setAnalysisId(props.id);
    setBlockLoading(false);
  };

  const handleGetAnalysisError = data => {
    if (data.message.includes('401')) {
      authStorageService.logout();
    } else if (data.message.includes('403')) {
      setForbiddenAccess(true);
    } else {
      show('alert', data.message ? data.message : data.description);
    }
  };

  const {
    data: dataAnalysis,
    isLoading: loading,
    isFetching: loadingFetching,
    error,
    refetch: refetchAnalysis,
  } = useTechnicalAnalysis(
    { id: props.id, language: userInfo.language },
    handleGetAnalysisSuccess,
    handleGetAnalysisError,
  );

  const {
    data: dataSimilarMaterials,
    isLoading: loadingSimilarMaterials,
    refetch: refetchSimilarMaterials,
  } = useTechnicalAnalysisSimilarsMaterials(
    { id: props.id, enable: userInfo.profile.profile !== 'client', language: userInfo.language },
    handleGetAnalysisError,
  );

  React.useEffect(() => {
    if (dataAnalysis) {
      if (
        chekdatesForModalAssets(dataAnalysis.unformattedCreationDate) <= 5 &&
        dataAnalysis.flowType === 'CLIENT_MANUAL' &&
        assetsModalCount === 0
      ) {
        setAssetsModalOpened(true);
        setAssetsModalCount(1);
        setShowLabelModalAssetsFlow(true);
      }
      setTotalAssets(dataAnalysis.assets.length);
    }
  }, [dataAnalysis]);

  const handleChangeStatusSuccess = () => {
    setStatusModalOpened(false);
    setDisableButtons(false);
    show('success', strings.messages.success);
  };

  const handleChangeStatusCancelSuccess = () => {
    setStatusModalOpened(false);
    setDisableButtons(false);
    show('success', strings.messages.success);
    refetchAnalysis();
  };

  const handleChangeStatusError = (res: any) => {
    const errorMessage = res.response?.data?.details;
    if (errorMessage) {
      LogAnalytics.error({ tipo: TechnicalAnalysisEvents.StatusChangeError });
      show('alert', `${strings.messages.alert} ${errorMessage}`);
      setDisableButtons(false);
      setActionsLoading(false);
      refetchAnalysis();
    }
  };

  const { mutate: patchStatus, isLoading: patchLoading } = useChangeStatus(
    handleChangeStatusSuccess,
    handleChangeStatusError,
  );

  const [disableButtons, setDisableButtons] = React.useState<boolean>(false);
  const { performRequest: patchCancelQuotation, loading: patchCancelLoading } = useLazyRequest<
    PatchCancelQuotationParams,
    void
  >(QuoteStatusDataSource.patchCancelQuotation, handleChangeStatusCancelSuccess, handleChangeStatusError);

  const handleTabChange = (index: number) => {
    LogAnalytics.pageView(TechnicalAnalysisEvents.PlantView + analysis?.plants?.[tabIndex]);
    setTabIndex(index);
  };

  const handleStatusClick = (stage: Stages) => {
    LogAnalytics.pageView(TechnicalAnalysisEvents.StatusChange + stage.label);
    setSelectedStage(stage);
    const status = stage.key;
    const extraInfos = analysis?.permissions?.extraInfos;
    const internalStatus = analysis?.internalStatus?.key;

    const statusStepModal = ['UNDER_ANALYSIS_EPP', 'DATA_VALIDATION_RTC', 'UNDER_ANALYSIS_PED'];
    const statusApqpModal = ['UNDER_ANALYSIS_EPP', 'UNDER_ANALYSIS_PED', 'UNDER_ANALYSIS_RTC', 'FINALIZED'];

    if (extraInfos && statusStepModal.includes(status)) {
      setAttributeStepModalOpened(true);
    } else if (
      statusApqpModal.includes(status) &&
      (internalStatus === 'UNDER_ANALYSIS_EPP' || internalStatus === 'UNDER_ANALYSIS_PED')
    ) {
      setAnalysisApqpModalOpened(true);
    } else if (hasSellerAccess && status === 'VALIDATION_RTC') {
      setAnalysisTypeModalOpened(true);
    } else {
      setStatusModalOpened(true);
    }
  };

  const handleStatusModalClose = () => {
    LogAnalytics.click({ tipo: TechnicalAnalysisEvents.StatusChangeClosed });
    setSelectedStage(null);
    setStatusModalOpened(false);
  };

  const handleAttributeStepModalClose = () => {
    LogAnalytics.click({ tipo: TechnicalAnalysisEvents.StatusChangeClosed });
    setSelectedStage(null);
    setAttributeStepModalOpened(false);
  };

  const handleAnalysisApqpModalClose = () => {
    LogAnalytics.click({ tipo: TechnicalAnalysisEvents.StatusChangeClosed });
    setAnalysisApqpModalOpened(false);
  };

  const handlePendingModalClose = () => {
    LogAnalytics.click({ tipo: TechnicalAnalysisEvents.StatusChangeClosed });
    setSelectedStage(null);
    setPendingModalOpened(false);
  };

  const handleCancelModalClose = () => {
    setSelectedStage(null);
    setCancelModalOpened(false);
  };

  const handleExtraAttrbute = (ppap: string, priority: string, partNumber: string | number) => {
    LogAnalytics.click({ tipo: TechnicalAnalysisEvents.PriorityPpapChange });
    setExtraAttribute({
      PPAP: ppap,
      PRIORITY: priority,
      PARTNUMBER: partNumber,
    });
    setAttributeStepModalOpened(false);
    setStatusModalOpened(true);
  };

  const handleAnalysisApqp = (
    apqp: string,
    pe: string,
    fmeas: string,
    change: string,
    newProductEpp: string,
    newProductEppJustify: string,
  ) => {
    setAnalysisApqpModalOpened(false);

    if (apqp && pe && fmeas && change) {
      if (newProductEpp) {
        setExtraNotifications({
          APQP: apqp,
          PE: pe,
          FMEAS: fmeas,
          FMEAS_EDIT: change,
          NEWPRODUCTEPP: newProductEpp,
          NEWPRODUCTEPPJUSTIFY: newProductEppJustify,
        });
      } else {
        setExtraNotifications({ APQP: apqp, PE: pe, FMEAS: fmeas, FMEAS_EDIT: change });
      }

      if (apqp === strings.attachments.no) {
        LogAnalytics.click({ tipo: TechnicalAnalysisEvents.ApqpNeed });
        setTooltipApqpStatus(true);
      } else if (apqp === strings.attachments.yes) {
        LogAnalytics.click({ tipo: TechnicalAnalysisEvents.ApqpAlreadyAttached });
        setStatusModalOpened(true);
        setTooltipApqpStatus(false);
      } else {
        LogAnalytics.click({ tipo: TechnicalAnalysisEvents.NoNeedApqp });
        setStatusModalOpened(true);
        setTooltipApqpStatus(false);
      }
    }
  };

  const handleAnalysisType = (type: string) => {
    setAnalysisTypeModalOpened(false);

    if (type) {
      setAnalysisType(type);
      setStatusModalOpened(true);
    }
  };

  const handleChangeStatus = (key: string, data?: TechnicalAnalysisStatusData) => {
    const extraInfos = analysis?.permissions?.extraInfos;
    const status = quoteIsInternalUser ? { internal: { key } } : { external: { key } };

    let attributes = null;

    if (extraInfos) {
      attributes = { PPAP: extraAttribute.PPAP, PRIORITY: extraAttribute.PRIORITY };
    } else {
      if (data?.APQP && data?.PE && data?.FMEAS && data?.FMEAS_EDIT && data?.NEWPRODUCTEPP) {
        attributes = {
          APQP: data?.APQP,
          PE: data?.PE,
          FMEAS: data?.FMEAS,
          FMEAS_EDIT: data?.FMEAS_EDIT,
          NEW_PRODUCT_EPP: data?.NEWPRODUCTEPP,
        };
      } else {
        attributes = null;
      }
    }
    LogAnalytics.click({ tipo: TechnicalAnalysisEvents.StatusChange });
    if (data) {
      data.partNumber = data?.partNumber ? data.partNumber : extraAttribute.PARTNUMBER;
    }

    if (quoteIsInternalUser) {
      analysis.internalStatus = {
        key: status.internal.key,
        label: QuoteStrings[userInfo.language].constants.stage.label[key],
      };
    } else {
      analysis.externalStatus = {
        key: status.external.key,
        label: QuoteStrings[userInfo.language].constants.stage.label[key],
      };
    }
    if (
      !quoteIsInternalUser &&
      (dataAnalysis?.cancelRequestAllowed || dataAnalysis?.externalStatus?.key === 'WAITING_PRICE')
    ) {
      patchCancelQuotation({ analysisId: +props.id, reason: data.observation, language: userInfo.language });
    } else {
      patchStatus({
        extraInfos: attributes,
        analysisId: +props.id,
        current: status,
        notification: data,
        analysisType,
        language: userInfo.language,
      });
    }

    setDisableButtons(true);

    window.localStorage.removeItem('openToolTipRequestAnalysis');
    setStatusModalOpened(false);
    setPendingModalOpened(false);
    setCancelModalOpened(false);
    setOpenFeedback(true);
  };

  const handleEditAnalysis = () => {
    LogAnalytics.click({ tipo: TechnicalAnalysisEvents.EditAnalysis });
    history.push(AppPath.CHAT.DETAIL.replace(':action', 'edit').replace(':analysisId', props.id.toString()));
  };

  const handleDuplicateAnalysis = () => {
    LogAnalytics.click({ tipo: TechnicalAnalysisEvents.DuplicateAnalysis });
    history.push(AppPath.CHAT.DETAIL.replace(':action', 'duplicate').replace(':analysisId', props.id.toString()));
  };

  const handleRefetchAnalysis = () => {
    LogAnalytics.click({ tipo: TechnicalAnalysisEvents.RefreshAnalysis });
    getTechnicalAnalysis({ id: props.id, language: userInfo.language });
  };

  const handleAssetsClick = () => {
    LogAnalytics.pageView(TechnicalAnalysisEvents.Assets);
    setAssetsModalOpened(true);
  };

  const handleOpenSimilarMaterialsModal = () => {
    LogAnalytics.pageView(TechnicalAnalysisEvents.ClickOnSimilarButton);
    if (verifySession === null) {
      history.push(AppPath.AUTHENTICATION);
    } else {
      setOpenSimilarMaterialsModal(true);
    }
  };

  const handleCloseSimilarMaterialsModal = () => setOpenSimilarMaterialsModal(false);

  const showPreviewWarning = previewMessagesAlert(
    'showPreviewWarning',
    !quoteIsInternalUser,
    dataAnalysis?.externalStatus.key,
  );

  const showPreviewCanceled = previewMessagesAlert('showPreviewCanceled', null, analysis?.externalStatus.key);
  let messageCanceled = null;
  if (showPreviewCanceled && analysis?.history.length > 0) {
    dataAnalysis?.history.map(item => {
      if (item.external.key === 'CANCELED') {
        messageCanceled = item.observation;
      }
    });
  }

  const showPreviewRequestCanceled =
    dataAnalysis?.cancelRequestReason &&
    `${QuoteStrings[userInfo.language].components.previewCanceledRequest}${analysis.cancelRequestReason}`;

  const handleCloseModalAssets = () => {
    setAssetsModalOpened(false);
    setTotalAssets(analysis.assets.length);
  };

  const [nextStatus, setNextStatus] = React.useState('');
  const handlePendingModalOpened = (key: string, action: string) => {
    setNextStatus(key);
    if (action === 'sendToGerdau') {
      if (analysis.askPartNumber) {
        setPendingModalOpened(true);
      } else {
        handleChangeStatus(key);
      }
    } else {
      setCancelModalOpened(true);
    }
  };

  const handleSendToBaggSuccess = () => {
    LogAnalytics.success({ tipo: TechnicalAnalysisEvents.SendToBaggSuccess });
    show('success', strings.messages.sendToBaggSuccess);
    setBlockLoading(false);
  };

  const { isFetching: loadingSendtoBagg, refetch: sendToBagg } = useSendToBagg(
    { id: props.id, language: userInfo.language },
    handleSendToBaggSuccess,
    handleGetAnalysisError,
  );

  const handleSendToBagg = () => {
    sendToBagg();
  };

  const handleCheckStatusSuccess = (res: CurrentStatus) => {
    if (
      res.currentStatus?.internalStatus?.key !== 'PROCESSING' &&
      res.currentStatus?.externalStatus?.key !== 'PROCESSING'
    ) {
      refetchAnalysis();
      refetchSimilarMaterials();
    }
  };

  useCheckProcessingStatus(
    {
      analysisId: props.id,
      currentStatus: dataAnalysis?.internalStatus?.key
        ? dataAnalysis?.internalStatus?.key
        : dataAnalysis?.externalStatus?.key,
      language: userInfo.language,
    },
    handleCheckStatusSuccess,
    handleGetAnalysisError,
  );

  const loadingPage = patchLoading || patchCancelLoading || fullLoading || loadingFetching;

  const [actionsLoading, setActionsLoading] = React.useState(loadingPage);

  React.useEffect(() => {
    setActionsLoading(loadingPage);
  }, [loadingPage]);

  const BLOCK_PROCESS =
    dataAnalysis?.internalStatus?.key === 'PROCESSING' || dataAnalysis?.externalStatus?.key === 'PROCESSING';

  const mesButtonPermission =
    (hasEPPAccess || hasRTCAccess || hasPEDAccess) &&
    (dataAnalysis?.internalStatus?.key === 'INTERNAL_QUERY' ||
      dataAnalysis?.internalStatus?.key === 'UNDER_ANALYSIS_EPP' ||
      dataAnalysis?.internalStatus?.key === 'UNDER_ANALYSIS_PED' ||
      dataAnalysis?.internalStatus?.key === 'UNDER_ANALYSIS_RTC');

  const handleClickMesButton = () => {
    if (window) {
      LogAnalytics.click({ tipo: TechnicalAnalysisEvents.ClickOnMESButton });
      window.open(
        'https://app.powerbi.com/groups/c7a8be8e-f4d8-4b83-915a-86bd5224a25e/reports/945b373d-d2f1-4c88-b6df-801138fb92db/ReportSection',
        '_blank',
      );
    }
  };

  return (
    <LoadingState
      data={!!dataAnalysis}
      loading={loading || loadingSendtoBagg}
      error={!!error || !!contextError || !!fullError || !!attributeError}
    >
      <LoadingState.Shimmer>
        <TechnicalAnalysisShimmer />
      </LoadingState.Shimmer>

      <LoadingState.Error>
        <ErrorPlaceholder customMessage={forbiddenAccess && strings.messages.forbidden} />
      </LoadingState.Error>

      {showPreviewWarning && (
        <PreviewMessage
          kind='warning'
          icon='exclamation-triangle'
          message={QuoteStrings[userInfo.language].components.previewWarning}
        />
      )}

      {showPreviewCanceled ||
        (showPreviewRequestCanceled && (
          <PreviewMessage
            kind='alert'
            icon='exclamation-triangle'
            message={
              showPreviewRequestCanceled
                ? showPreviewRequestCanceled
                : `${QuoteStrings[userInfo.language].components.previewCanceled}${
                    messageCanceled ? messageCanceled : QuoteStrings[userInfo.language].components.noCanceledMessage
                  }`
            }
          />
        ))}

      <Frame>
        <Grid fluid>
          <Row middle='xs'>
            <Col xs={12}>
              <TechnicalAnalysisHeader
                analysisId={props.id}
                analysis={dataAnalysis}
                onStatusClick={handleStatusClick}
                onAssetsClick={handleAssetsClick}
                onRefetchClick={handleRefetchAnalysis}
                onClientSubmit={handlePendingModalOpened}
                onAnalysisDuplicateClick={handleDuplicateAnalysis}
                onAnalysisEditClick={handleEditAnalysis}
                onSimilarMaterialsModal={handleOpenSimilarMaterialsModal}
                onHandleSendToBagg={handleSendToBagg}
                totalSimilarMaterial={
                  dataSimilarMaterials?.similarMaterials && dataSimilarMaterials?.similarMaterials?.rows !== undefined
                    ? dataSimilarMaterials?.similarMaterials?.rows.length
                    : 0
                }
                tooltipApqpStatus={tooltipApqpStatus}
                totalAssets={totalAssets}
                actionsLoading={actionsLoading}
                blockProcess={BLOCK_PROCESS}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={8}>
              <TechnicalAnalysisPlantsTab
                analysis={dataAnalysis}
                tabIndex={tabIndex}
                handleTabChange={handleTabChange}
              />
            </Col>
            <Col xs={4}>
              <Hbox noGrow hAlign='flex-end' vAlign='flex-end'>
                {mesButtonPermission && <Button onClick={handleClickMesButton}>{strings.buttonMES}</Button>}
              </Hbox>
            </Col>
          </Row>
        </Grid>
      </Frame>
      {dataAnalysis && (
        <TechnicalAnalysisTab
          hasPrice={!!dataAnalysis?.price}
          analysis={dataAnalysis}
          plant={dataAnalysis.plants?.[tabIndex]}
          recurrentProduct={dataAnalysis?.flow === recurrentProduct}
          editedAttributes={dataAnalysis?.editedAttributes}
          externalStatus={dataAnalysis?.externalStatus.key}
          internalUser={quoteIsInternalUser}
          disableButtons={disableButtons || BLOCK_PROCESS}
          blockProcess={BLOCK_PROCESS}
          actionsLoading={actionsLoading}
          setActionsLoading={setActionsLoading}
        />
      )}

      <AttributeStepModal
        market={
          dataAnalysis?.requiredQuery?.MARKET
            ? dataAnalysis?.requiredQuery?.MARKET
            : dataAnalysis?.requiredQuery?.MERCADO
        }
        onModalClose={handleAttributeStepModalClose}
        onNextClick={handleExtraAttrbute}
        opened={attributeStepModalOpened}
      />

      <AnalysisApqpModal
        onModalClose={handleAnalysisApqpModalClose}
        onNextClick={handleAnalysisApqp}
        opened={analysisApqpModalOpened}
      />

      <AnalysisTypeModal
        onModalClose={() => setAnalysisTypeModalOpened(false)}
        onNextClick={handleAnalysisType}
        opened={analysisTypeModalOpened}
      />

      <StatusPendingModal
        onClose={handlePendingModalClose}
        opened={pendingModalOpened}
        status={nextStatus}
        onSubmit={handleChangeStatus}
      />

      <StatusCancelModal
        onClose={handleCancelModalClose}
        opened={cancelModalOpened}
        status={
          dataAnalysis?.externalStatus?.key === 'PENDING' || dataAnalysis?.externalStatus?.key === 'CLIENT_EDITION'
            ? 'CANCELED'
            : dataAnalysis?.externalStatus?.key
        }
        onSubmit={handleChangeStatus}
      />

      <TechnicalAnalysisStatusModal
        onClose={handleStatusModalClose}
        opened={statusModalOpened}
        data={selectedStage}
        extraNotifications={extraNotifications}
        analysisType={analysisType}
        onSubmit={handleChangeStatus}
        loading={patchLoading || loading}
      />

      <TechnicalAnalysisSimilarMaterialsModal
        opened={openSimilarMaterialsModal}
        onClose={handleCloseSimilarMaterialsModal}
        data={dataSimilarMaterials?.similarMaterials}
        loading={loadingSimilarMaterials}
      />

      <TechnicalAnalysisAssetsProvider analysisId={props.id}>
        <TechnicalAnalysisAssetsModal
          analysis={dataAnalysis}
          opened={assetsModalOpened}
          onClose={handleCloseModalAssets}
          accessToken={user.accessToken}
          analysisId={+props.id}
          assets={dataAnalysis?.assets}
          showLabel={showLabelModalAssetsFlow}
        />
      </TechnicalAnalysisAssetsProvider>
      <Feedback
        analysisId={+props.id}
        opened={openFeedback}
        setOpened={setOpenFeedback}
        context={FeedbackAnalytics.TechnicalAnalysisChangeStatus}
        type={FeedbackType.Img}
        analyticsType={EventTypes.Feedback}
      />
    </LoadingState>
  );
};
