import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { GetNFShippingRequestParams, GetNFxmlRequestParams, GetWaybillRequestParams } from '@app/models/document.model';
import { AuthStorageService } from '../storage/auth.storage';
import { CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from './constants';
import { IDocumentCQParams, DocumentResponse, documentResponseToBlob } from './document.dto';
import { DownloadZipParams } from './download-zip-params.dto';

const endpoint = {
  NF: '/documents/invoice/',
  CQ: '/documents/quality-certificate',
  DC: '/export/documents',
  WAYBILL: '/documents/romaneio',
  NFXML: '/documents/nf-xml',
  NFSHIPPING: '/documents/invoice/shipping',
};

export const DocumentDataSource = {
  getNFPdf: (docNumber: string, customerIds: string): Promise<File> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.NF + docNumber + '?customerIds=' + customerIds)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        client_id: CXP_KEY_CLIENT_ID,
        client_secret: CXP_KEY_CLIENT_SECRET,
        access_token: user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .interceptors({
        afterSuccess: async (res: DocumentResponse): Promise<string> => documentResponseToBlob(res),
      })
      .execute();
  },

  getCQPdf: (params: IDocumentCQParams): Promise<File> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.CQ}/${params.ov}/${params.itemOv}/${params.numDelivery}/${params.numDeliveryItem}`)
      .params({ customerIds: params.customerIds })
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        client_id: CXP_KEY_CLIENT_ID,
        client_secret: CXP_KEY_CLIENT_SECRET,
        access_token: user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .interceptors({
        afterSuccess: async (res: DocumentResponse): Promise<string> => documentResponseToBlob(res),
      })
      .execute();
  },

  getWaybill: (params: GetWaybillRequestParams): Promise<File> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.WAYBILL)
      .params(params)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        client_id: CXP_KEY_CLIENT_ID,
        client_secret: CXP_KEY_CLIENT_SECRET,
        access_token: user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .interceptors({
        afterSuccess: async (res: DocumentResponse): Promise<string> => documentResponseToBlob(res),
      })
      .execute();
  },

  getNFxml: (params: GetNFxmlRequestParams): Promise<File> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.NFXML)
      .params(params)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        client_id: CXP_KEY_CLIENT_ID,
        client_secret: CXP_KEY_CLIENT_SECRET,
        access_token: user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .interceptors({
        afterSuccess: async (res: DocumentResponse): Promise<string> => documentResponseToBlob(res),
      })
      .execute();
  },
  getNFshipping: (params: GetNFShippingRequestParams): Promise<File> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.NFSHIPPING}/${params.document}/${params.documentItem}?customerIds=${params.customerIds}`)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        client_id: CXP_KEY_CLIENT_ID,
        client_secret: CXP_KEY_CLIENT_SECRET,
        access_token: user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .interceptors({
        afterSuccess: async (res: DocumentResponse): Promise<string> => documentResponseToBlob(res),
      })
      .execute();
  },
  getZipFile: async (params: DownloadZipParams): Promise<any> => {
    const user = Container.get(AuthStorageService).getUser();

    return fetch(
      `${Container.get('DocumentsUrl')}${endpoint.DC}?boardingId=${params.boardingNumber}&customerIds=${
        params.customerIds
      }`,
      {
        headers: {
          Authorization: 'Bearer ' + user.accessToken,
          client_id: CXP_KEY_CLIENT_ID,
          client_secret: CXP_KEY_CLIENT_SECRET,
          access_token: user.accessToken,
          'Content-Type': 'application/zip',
          Accept: 'application/zip, */*',
        },
      },
    ).then(value => value.blob());
  },
};
