import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { defaultCrmHeaders, endpointCrm } from '@app/data/http';
import { CrmAttachedFilesResponse, GetCrmParams, mapAttachedFilesData } from '@app/data/http/crm.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export async function getAttachedFiles(params?: GetCrmParams): Promise<CrmAttachedFilesResponse> {
  const user = Container.get(AuthStorageService).getUser();
  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpointCrm.CRM}/${params.id}${
    endpointCrm.ATTACHED_FILES
  }`;

  const { data, status } = await axios.get(url, { headers: { ...defaultCrmHeaders, 'sso-access': user.ssoAccess } });

  if (status === 200) {
    return data;
  } else {
    return null;
  }
}

export function useGetAttachedFiles(params: GetCrmParams, onError?) {
  return useQuery(['getAttachedFiles', params], () => getAttachedFiles(params), {
    onError,
    enabled: params.enable,
    select: data => {
      return mapAttachedFilesData(data.data);
    },
    refetchOnWindowFocus: false,
  });
}
