import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { AuthStorageService } from '../storage/auth.storage';

const endpointAdmin = {
  RESET: '/csat/admin/reset',
  MODULES: '/csat/admin',
};

const endpointUser = {
  ANSWER: '/csat/user/answer',
  MODULES: '/csat/user',
};

export interface CSATAdminModule {
  functionality: string;
  active: boolean;
  resetDate: string;
}
export interface CSATUserModule {
  functionality: string;
  answer: boolean;
  resetDate: string;
}

export interface AdminSettingsResetCSATParameters {
  functionality: string;
}
export interface AsnwerUpdateUserParameters {
  functionality: string;
}
export interface AdminSettingsGetConfigCSATResponse {
  csats: CSATAdminModule[];
}
export interface AdminSettingsGetUserResponse {
  csats: CSATUserModule[];
}
export interface AsnwerUpdateUserResponse {
  identifier: string;
  functionality: string;
}

// const defaultHeaders = {
//   client_id: CXP_KEY_CLIENT_ID,
//   client_secret: CXP_KEY_CLIENT_SECRET,
//   'Content-Type': 'application/json',
//   Accept: '*/*',
//   language: user?.language,
// };

export const AdminSettingsDataSource = {
  postResetCSAT: (params: AdminSettingsResetCSATParameters): Promise<void> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .post(endpointAdmin.RESET)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .data(params)
      .execute();
  },
  answerCSATUser: (params: AsnwerUpdateUserParameters): Promise<AsnwerUpdateUserResponse> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .post(`${endpointUser.ANSWER}`)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .data({ identifier: user.uid, functionality: params.functionality })
      .execute();
  },
  getCSATConfig: (): Promise<AdminSettingsGetConfigCSATResponse> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpointAdmin.MODULES)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .execute();
  },
  getCSATUser: (): Promise<AdminSettingsGetUserResponse> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(`${endpointUser.MODULES}?identifier=${user.uid}`)
      .headers({
        language: 'pt',
        Authorization: 'Bearer ' + user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .execute();
  },
};
