import { Tab, Button, Icon, Title } from '@gerdau/hefesto';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '@app/core/redux/store';
import { hasAdminGsbSelector } from '@app/modules/auth/store/auth.selectores';
import { IAppStrings, Strings } from '../../../app/app.string';
import { AppPath } from '../../../app/route-constants';
import { AdminStrings, IAdminStrings } from '../../admin.string';
import { AdminUserForm } from '../admin-user-form/admin-user-form.component';
import { AdminControlWrapperStyled, AdminTabWrapperStyled } from './admin-tabs.style';
import { AdminBPComponent } from './tabs/admin-bp/admin-bp.component';
import { AdminProfileView } from './tabs/admin-profile/admin-profile-view.component';
import { AdminUserComponent } from './tabs/admin-user/admin-user.component';

const stringsAdm: IAppStrings = Strings.pt;
const strings: IAdminStrings = AdminStrings.pt;

export const AdminTabs: React.FC = () => {
  const { showForm } = useSelector((state: RootState) => state.admin);
  const [activeKey, setActiveKey] = React.useState('0');
  const hasAdminGsb = useSelector(hasAdminGsbSelector);
  const history = useHistory();
  const tabsArray = [
    {
      content: <AdminUserComponent />,
      key: '0',
      name: strings.tabs.users,
      show: true,
    },
    {
      content: <AdminBPComponent />,
      key: '1',
      name: strings.tabs.bps,
      show: true,
    },
    {
      content: <AdminProfileView />,
      key: '2',
      name: strings.tabs.profiles,
      show: hasAdminGsb,
    },
  ];

  return (
    <AdminTabWrapperStyled>
      <AdminControlWrapperStyled>
        <Title level={1} variant='primary'>
          {stringsAdm.admin.title}
        </Title>
        <Button
          name='backToGerdauMaisBtn'
          variant='secondary'
          icon={<Icon option='ArrowLeft' />}
          onClick={() => {
            history.push(AppPath.ORDERS);
          }}
        >
          {strings.actions.homeBtn}
        </Button>
      </AdminControlWrapperStyled>
      {!showForm && (
        <Tab
          activeKey={activeKey}
          onChange={function noRefCheck(key) {
            setActiveKey(key);
          }}
          tabItems={tabsArray.filter(t => t.show)}
          tabPosition='top'
        />
      )}
      {showForm && <AdminUserForm />}
    </AdminTabWrapperStyled>
  );
};
