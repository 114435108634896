import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { BAGG_KEY_CLIENT_ID, BAGG_KEY_CLIENT_SECRET, BaggHttpRequestBuilder } from '@app/data/http';
import { ComplainClientResponse, mapComplainClients } from '@app/data/http/complain.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { handleUnauthorized } from '@app/utils/http-utils';

const endpoint = {
  SALESFORCE_ACCOUNTS: '/salesforce-accounts',
  FORM_COMPLAIN: '/form-complaints',
  FORM_COMPLAIN_FILES: '/form-attachment',
};

export async function getComplainClients(params?: any): Promise<ComplainClientResponse> {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    client_id: BAGG_KEY_CLIENT_ID,
    client_secret: BAGG_KEY_CLIENT_SECRET,
    access_token: user.accessToken,
    'Content-Type': 'application/json',
    Accept: '*/*',
    'sso-access': user.ssoAccess,
  };

  const url = `${Container.get(BaggHttpRequestBuilder).config.baseUrl}${endpoint.SALESFORCE_ACCOUNTS}?market=${
    params.market
  }`;

  try {
    const { data, status } = await axios.get(url, { headers: defaultHeaders });
    if (status === 200) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    handleUnauthorized(error);
    return null;
  }
}

export function useComplainClients(params?: any, onError?) {
  return useQuery(['getComplainClients'], () => getComplainClients(params), {
    onError,
    select: data => {
      return mapComplainClients(data);
    },
    refetchOnWindowFocus: false,
  });
}
