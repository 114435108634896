import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET, endpoint } from '@app/data/http';
import { ConfigurationsReponse } from '@app/data/http/quote-params.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { handleUnauthorized } from '@app/utils/http-utils';

export async function getChatCreateV2(): Promise<ConfigurationsReponse> {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    client_id: CXP_KEY_CLIENT_ID,
    client_secret: CXP_KEY_CLIENT_SECRET,
    Authorization: 'Bearer ' + user.accessToken,
    access_token: user?.accessToken,
    'Content-Type': 'application/json',
    Accept: '*/*',
    language: user.language,
    'sso-access': user.ssoAccess,
  };

  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.CONFIGURATIONS}`;
  try {
    const { data, status } = await axios.get(url, { headers: defaultHeaders });
    if (status === 200) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    handleUnauthorized(error);
    return null;
  }
}

export function useChatCreate(onSuccess?, onError?) {
  return useQuery(['getChatCreateV2'], () => getChatCreateV2(), {
    onSuccess,
    onError,
    enabled: true,
    refetchOnWindowFocus: false,
  });
}
