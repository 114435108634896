import { IPreventiveStopModalState } from '../preventive-stop-modal.interfaces';
import { initialState } from './preventive-stop-modal.initial';

export const reducers = {
  reset: () => {
    return initialState;
  },
  setVisited: (state: IPreventiveStopModalState, { payload }) => {
    state.visited = payload;
  },
  setLastVisitedDate: (state: IPreventiveStopModalState, { payload }) => {
    state.lastVisitedDate = payload;
  },
};
