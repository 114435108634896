import * as React from 'react';
import { useSelector } from 'react-redux';
import { useStore } from '@app/core/global-store.service';
import { useUserStore, UserStore } from '@app/providers';
import { Frame } from '@atomic/atm.frame';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { quoteIsInternalUserSelector } from '../auth/store/auth.selectores';
import { MenuComplainPage } from './menu-complain-page.component';

// eslint-disable-next-line complexity
export const ComplainPage: React.FC = () => {
  const { user } = useStore<UserStore>(useUserStore);
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);

  return (
    <>
      <Frame>
        <Grid fluid>
          <Row>
            <Col md={5} xs={12}>
              {quoteIsInternalUser && user?.accessToken && <MenuComplainPage tabIndex={null} />}
            </Col>
            <Col md={7} xs={12}></Col>
          </Row>
        </Grid>
      </Frame>
      <Grid fluid>
        <Row mt>
          <Col xs={12}>
            <Row>
              <Col xs={12}></Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    </>
  );
};
