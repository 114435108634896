/* eslint-disable prettier/prettier */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogAnalytics } from '@app/core/analytics';
import type { RootState } from '@app/core/redux/store';
import { OrderInputDatasource, IValidateMultipleParams, IMultipleMaterialParams } from '@app/data/http';
import { IMultipleValidation } from '@app/data/http/order-input.dto';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { OrderInputEvents } from '../../order-input.analytics';
import { SLICE_NAME } from '../../order-input.constants';
import { IOrderInputState } from '../../order-input.interfaces';
import { fillMaterialsValidation, filterAvailableMaterialsForMultiple, mapMaterialsToMultipleParams } from '../../order-input.utils';


export const validateAmountMultiple = createAsyncThunk(`${SLICE_NAME}/validateAmountMultiple`, async (_, thunkAPI) => {
  const state: RootState = thunkAPI.getState() as RootState;
  const orderInputState = state.orderInput;
  const { selectedIssuer, selectedMaterials } = orderInputState;
  const customerIds = customerIdsJoinedSelector(state);
  const multiplePayload: IMultipleMaterialParams[] = mapMaterialsToMultipleParams(filterAvailableMaterialsForMultiple(selectedMaterials));

  const payload: IValidateMultipleParams = {
    emissionCode: selectedIssuer.value,
    multiple: multiplePayload,
  };


  return await OrderInputDatasource.validateMultiple(payload).then(data => data)
    .catch(error => {
      LogAnalytics.error({
        tipo: OrderInputEvents.ValidateMultipleError,
        responseStatus: error.response.status,
        responseData: error.response.data?.message,
        customerIds,
      });
      return thunkAPI.rejectWithValue({ message: error.response.data?.message });
    });
});

export const addValidateAmountMultipleReducers = builder => {
  builder.addCase(validateAmountMultiple.pending, (state: IOrderInputState) => {
    state.loadingMultiple = true;
    state.openMultipleAlertModal = true;
  });
  builder.addCase(validateAmountMultiple.fulfilled, (state: IOrderInputState, action) => {
    const multipleValidation: IMultipleValidation[] = action.payload;

    state.loadingMultiple = false;
    const filledMaterials = fillMaterialsValidation(state.selectedMaterials, multipleValidation);
    state.selectedMaterials = filledMaterials;

  });
  builder.addCase(validateAmountMultiple.rejected, (state: IOrderInputState) => {
    state.loadingMultiple = false;
  });
};



