// import React, { useEffect } from 'react';
import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { isGKNSelector } from '@app/modules/components/customer-search/store/customer-search.selectors';
import { PARTY_NUMBERS_GKN_ALERT } from '@app/modules/order/order-tabs/MI/tab-stock/order-tab-stock.constants';
import {
  IShippingRequestColumns,
  IShippingRequestRow,
} from '@app/modules/shipping-request/shipping-request.interfaces';
import {
  // getOldShippingItem,
  getTableColumnsDefinitions,
  // handleHasOldSalesOrderAlert,
} from '@app/modules/shipping-request/shipping-request.utils';
import { selectedRowsByPlantSelector } from '@app/modules/shipping-request/store/shipping-request.selectors';
// import { setHasOldSalesOrderAlert } from '@app/modules/shipping-request/store/shipping-request.store';
import { TD, TR } from '@atomic/mol.table';
import { Color } from '@atomic/obj.constants';
// import { ShippingRequestOldSaleOrderWarningTR } from './partials/shipping-request-old-sale-order-warning-tr/shipping-request-old-sale-order-warning-tr.componet';
import { ShippingRequestTableBodyTD } from './partials/shipping-request-table-body-td/shipping-request-table-body-td.component';
import { ShippingRequestWarningTR } from './partials/shipping-request-warning-tr/shipping-request-warning-tr.componet';

interface IShippingRequestTableBodyProps {
  plant: string;
}

export const ShippingRequestTableBody: React.FC<IShippingRequestTableBodyProps> = ({ plant }) => {
  const tableRows: IShippingRequestRow[] = useSelector((state: RootState) => selectedRowsByPlantSelector(state, plant));
  // const { data } = useSelector((state: RootState) => state.orderTabStock);
  // const { hasOldSalesOrderAlert } = useSelector((state: RootState) => state.shippingRequest);
  const isGKN = useSelector(isGKNSelector);
  const columns = getTableColumnsDefinitions(isGKN);
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if (data && tableRows) {
  //     dispatch(setHasOldSalesOrderAlert(handleHasOldSalesOrderAlert(data, tableRows)));
  //   }
  // }, []);

  return (
    <>
      {tableRows.map((item, rowIndex) => {
        const hasAlert =
          item.breakHeat === 'Sim' && PARTY_NUMBERS_GKN_ALERT.includes(item.customerProductCode) && !item.removedAlert;
        const key = isGKN ? `${item.salesOrder}-${item.heat}` : rowIndex;
        // const oldOVInData = data.filter(d => d.material === item.material && d.salesDocument !== item.salesOrder);
        // const oldShippingItem = getOldShippingItem(item, oldOVInData, tableRows);

        return (
          <>
            <TR key={key} bordered>
              {columns.map((column: IShippingRequestColumns) => (
                <TD color={hasAlert && Color.LightYellow} key={column.field}>
                  <ShippingRequestTableBodyTD item={item} type={column.type} value={item[column.field]} />
                </TD>
              ))}
            </TR>
            {hasAlert && <ShippingRequestWarningTR item={item} />}
            {/* {hasOldSalesOrderAlert && !isGKN && (
              <ShippingRequestOldSaleOrderWarningTR item={item} oldShippingItem={oldShippingItem} />
            )} */}
          </>
        );
      })}
    </>
  );
};
