import { ColDef } from 'ag-grid-community';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { HanaLanguage } from '@app/models';
import { LayoutModel } from '@app/models/layout.model';
import { LayoutDto, mapColsToColsDto, mapLayoutResponseToLayoutData } from './layout.dto';

export interface GetLayoutParams {
  language: string;
}

export interface PostLayoutParams {
  tab: string;
  columns: ColDef[];
  customerIds?: string;
  language: string;
}

const endpoint = {
  USER_LAYOUT: '/user/config/tabs',
  USER_UPDATE_LAYOUT: '/user/config/tab',
};

export const LayoutDataSource = {
  getLayout: (params: GetLayoutParams): Promise<LayoutModel> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.USER_LAYOUT)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        access_token: user.accessToken,
        Accept: '*/*',
        'sso-access': user.ssoAccess,
      })
      .params({ identifier: user.uid, language: HanaLanguage[params.language] })
      .interceptors({
        afterSuccess: async (res: LayoutDto): Promise<LayoutModel> => mapLayoutResponseToLayoutData(res),
      })
      .execute();
  },

  postLayout: (params: PostLayoutParams): Promise<any> => {
    const user = Container.get(AuthStorageService).getUser();
    const updatedColumns = mapColsToColsDto(params.columns);

    return Container.get(HttpRequestBuilder)
      .post(`${endpoint.USER_UPDATE_LAYOUT}`)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        access_token: user.accessToken,
        Accept: '*/*',
        'sso-access': user.ssoAccess,
      })
      .data({
        language: HanaLanguage[params.language],
        tab: params.tab,
        columns: updatedColumns,
        identifier: user.uid,
      })
      .execute();
  },
};
