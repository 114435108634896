import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { QuoteStatusDataSource } from '@app/data/http';
import { GetAvailableStatusParams } from '@app/data/http/quote-status-params.dto';
import { AvailableStatus } from '@app/models/quote.model';

export interface ContextTechnicalAnalysisValues {
  loadingStatus: boolean;
  errorStatus: Error;
  getStatus: (params: GetAvailableStatusParams) => void;
}

export function useStatusTechnicalAnalysis(
  setAvaliableStatus: (status: AvailableStatus) => void,
): ContextTechnicalAnalysisValues {
  const { performRequest: getStatus, loading: loadingStatus, error: errorStatus } = useLazyRequest<
    GetAvailableStatusParams,
    AvailableStatus
  >(QuoteStatusDataSource.getAvailableStatus, setAvaliableStatus);

  return {
    getStatus,
    loadingStatus,
    errorStatus,
  };
}
