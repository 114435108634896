import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import {
  IShippingRequestSuggestion,
  ISuggestionOption,
} from '@app/modules/shipping-request/shipping-request.interfaces';
import { IShippingRequestStrings, Strings } from '@app/modules/shipping-request/shipping-request.string';
import { getOldestSuggestions } from '@app/modules/shipping-request/shipping-request.utils';
import { setSuggestions } from '@app/modules/shipping-request/store/shipping-request.store';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { ShippingRequestSelectedItemTable } from '../../../shipping-request-selected-item-table/shipping-request-selected-item-table.component';
import { ShippingRequestSuggestionTable } from '../../../shipping-request-suggestion-table/shipping-request-suggestion-table.component';
import { ShippingRequestSuggestionModalBodyWrapper } from './shipping-request-suggestion-modal-body.style';

interface IShippingRequestSuggestionModalBodyParams {
  shippingRequestSuggestion: IShippingRequestSuggestion;
}

export const ShippingRequestSuggestionModalBody: React.FC<IShippingRequestSuggestionModalBodyParams> = ({
  shippingRequestSuggestion,
}) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { suggestionOptions } = useSelector((state: RootState) => state.shippingRequest);
  const strings: IShippingRequestStrings = Strings[userInfo.language];
  const dispatch = useDispatch<AppThunkDispatch>();
  const oldestSuggestions = getOldestSuggestions(shippingRequestSuggestion.oldShippingItem);

  useEffect(() => {
    if (oldestSuggestions && suggestionOptions?.length === 0) {
      const options: ISuggestionOption[] = [];

      oldestSuggestions.forEach(option => {
        options.push({ item: option, selected: false });
      });

      dispatch(setSuggestions(options));
    }
  }, [oldestSuggestions]);

  return (
    <ShippingRequestSuggestionModalBodyWrapper>
      <FlexColumn>
        <Body bold={700}>{strings.suggestionModal.requestLabel}</Body>
        <VSeparator />
        <ShippingRequestSelectedItemTable item={shippingRequestSuggestion.item} />
        <VSeparator small lineVisible />
        <VSeparator />
        <Body bold={700}>{strings.suggestionModal.suggestionLabel}</Body>
        <VSeparator />
        <ShippingRequestSuggestionTable options={oldestSuggestions} />
        <FlexRow>
          <Body bold={700}>{strings.suggestionModal.suggestionWarningLabel}</Body>
          <Body ml={Spacing.XSmall}>{strings.suggestionModal.suggestionWarningDescription}</Body>
        </FlexRow>
      </FlexColumn>
    </ShippingRequestSuggestionModalBodyWrapper>
  );
};
