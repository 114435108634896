/* eslint-disable prettier/prettier */
import * as React from 'react';
import { FormFieldContext, FormFieldContextState } from '@atomic/obj.form';
import {
  TextFieldButtonAreaStyled,
  TextFieldDismissButtonStyled,
  TextFieldIconStyled,
  TextFieldIconWrapperStyled,
  TextFieldMaskedStyled,
  TextFieldStyled,
  TextFieldWrapperStyled,
} from './text-field.component.style';

export interface TextFieldMaskOptionsProps {
  format?: string;
  mask?: string;
  precision?: number;
  delimiter?: string;
  separator?: string;
  unit?: string;
}

export interface TextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  inputRef?: any;
  onValueChange?: (value: string | number | string[]) => void;
  options?: TextFieldMaskOptionsProps;
  invalid?: boolean;
  disabled?: boolean;
  kind?: string;
  icon?: string[] | string;
  dismissable?: boolean;
  initialValue?: string | number | string[];
  align?: 'left' | 'center' | 'right';
  testid?: string;
  noArrows?: boolean;
  // https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill-processing-model
  // tslint:disable-next-line:max-line-length
  autoComplete?:
    | 'nope'
    | 'off'
    | 'on'
    | 'name'
    | 'honorific-prefix'
    | 'given-name'
    | 'additional-name'
    | 'family-name'
    | 'honorific-suffix'
    | 'nickname'
    | 'organization-title'
    | 'username'
    | 'new-password'
    | 'current-password'
    | 'organization'
    | 'street-address'
    | 'address-line1'
    | 'address-line2'
    | 'address-line3'
    | 'address-level4'
    | 'address-level3'
    | 'address-level2'
    | 'address-level1'
    | 'country'
    | 'country-name'
    | 'postal-code'
    | 'cc-name'
    | 'cc-given-name'
    | 'cc-additional-name'
    | 'cc-family-name'
    | 'cc-number'
    | 'cc-exp'
    | 'cc-exp-month'
    | 'cc-exp-year'
    | 'cc-csc'
    | 'cc-type'
    | 'transaction-currency'
    | 'transaction-amount'
    | 'language'
    | 'bday'
    | 'bday-day'
    | 'bday-month'
    | 'bday-year'
    | 'sex'
    | 'url'
    | 'photo'
    | 'tel'
    | 'tel-country-code'
    | 'tel-national'
    | 'tel-area-code'
    | 'tel-local'
    | 'tel-local-prefix'
    | 'tel-local-suffix'
    | 'tel-extension'
    | 'email'
    | 'impp';
  iconname?: string;
  hasButton?: boolean;
  small?: boolean;
  value?: string | number | string[];
}

export interface TextFieldState {
  value?: string | number | string[];
}

export class TextField extends React.Component<TextFieldProps, TextFieldState> {
  static defaultProps = {
    kind: 'normal',
  };

  private formFieldConsumer: FormFieldContextState;

  constructor(props: TextFieldProps) {
    super(props);
    this.state = {
      value: props.initialValue || props.value,
    };
  }

  render() {
    const {
      inputRef,
      autoComplete,
      onValueChange,
      kind,
      icon,
      hasButton,
      children,
      onChange,
      value,
      options,
      dismissable,
      initialValue,
      onBlur,
      onFocus,
      small,
      align,
      noArrows,
      ...other
    } = this.props;
    return (
      <FormFieldContext.Consumer>
        {(formFieldConsumer: FormFieldContextState) => {
          this.formFieldConsumer = formFieldConsumer;
          const val = this.getCurrentValue();

          return (
            <React.Fragment>
              {icon && (
                <TextFieldIconWrapperStyled>
                  <TextFieldIconStyled icon={icon} />
                </TextFieldIconWrapperStyled>
              )}
              {kind === 'normal' ? (
                <TextFieldWrapperStyled>
                  <TextFieldStyled
                    ref={inputRef}
                    value={val || ''}
                    onChange={this.handleChangeText}
                    icon={icon}
                    hasButton={hasButton}
                    autoComplete={autoComplete}
                    onInput={this.handleInput}
                    onBlur={this.handleBlur}
                    onFocus={this.handleFocus}
                    invalid={formFieldConsumer?.errors?.length}
                    small={small}
                    align={align}
                    noArrows={noArrows}
                    data-testid={this.props.testid}
                    {...other}
                  />
                  {dismissable && val && val.length > 0 && (
                    <TextFieldDismissButtonStyled onClick={this.handleDismiss} />
                  )}
                  {hasButton && (
                    <TextFieldIconWrapperStyled>
                      <TextFieldButtonAreaStyled>{children}</TextFieldButtonAreaStyled>
                    </TextFieldIconWrapperStyled>
                  )}
                </TextFieldWrapperStyled>
              ) : (
                <TextFieldWrapperStyled>
                    <TextFieldMaskedStyled kind={kind}
                    options={options}
                    value={val || ''}
                    onChangeText={this.handleChangeText}
                    icon={icon}
                    autoComplete={autoComplete}
                    onInput={this.handleInput}
                    onBlur={this.handleBlur}
                    onFocus={this.handleFocus}
                      invalid={formFieldConsumer?.errors?.length} ref={inputRef}
                    {...other}
                  />
                  {dismissable && val && val.length > 0 && (
                    <TextFieldDismissButtonStyled onClick={this.handleDismiss} />
                  )}
                </TextFieldWrapperStyled>
              )}
            </React.Fragment>
          );
        }}
      </FormFieldContext.Consumer>
    );
  }

  componentDidMount() {
    if (this.isControlled() && this.props.initialValue !== undefined) {
      throw new Error('Use either the initialValue prop, or the value prop, but not both');
    }

    if (this.formFieldConsumer) {
      if (this.isControlled() || this.props.initialValue !== undefined) {
        if (this.formFieldConsumer.value) {
          throw new Error('Please, use either value props in <TextField> or <Form.Field> component.');
        }

        this.formFieldConsumer.onValueChange(this.state.value, null);
      }
    }
  }

  componentDidUpdate(prevProps: TextFieldProps) {
    if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
      if (this.props.onValueChange) {
        this.props.onValueChange(this.props.value);
      }

      if (this.formFieldConsumer) {
        this.formFieldConsumer.onValueChange(this.props.value, null);
      }

      this.setState({ value: this.props.value });
    }
  }

  private handleChangeText = (eventOrText: string | React.FormEvent<HTMLInputElement>) => {
    let value: string;

    if (typeof eventOrText === 'string') {
      value = eventOrText;
    } else {
      value = (eventOrText as React.FormEvent<HTMLInputElement>).currentTarget.value;
    }

    if (this.state && value === this.state.value) {
      return;
    }

    if (this.props.onValueChange) {
      this.props.onValueChange(value);
    }

    if (this.isControlled()) {
      return;
    }

    if (this.formFieldConsumer) {
      this.formFieldConsumer.onValueChange(value, null);
    } else {
      this.setState({ value });
    }
  };

  private handleInput = event => {
    const nextValue = event.currentTarget.value;
    const currentValue = this.getCurrentValue();

    // HACK: "not fired by keyboard" means that who is calling onInput
    // is not the user by typing letters on the keyboard.
    // For instance, it can be fired by pasting some value or
    // by using form auto-complete.
    // Why is this necessary? auto-complete doesn't fire onChange event
    // but it fires onInput.
    // If you don't handle onInput, some bugs may appear if you use
    // auto-complete on Chrome iOS
    const notFiredByKeyboardTyping =
      (nextValue.length ? nextValue.length : 0) - (currentValue ? currentValue.length : 0) > 1;

    if (notFiredByKeyboardTyping) {
      event.preventDefault();
      this.handleChangeText(nextValue);
    }
  };

  private handleFocus = event => {
    if (this.formFieldConsumer) {
      this.formFieldConsumer.onFocusChange(true);
    }
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  };

  private handleBlur = (event: any) => {
    if (this.formFieldConsumer) {
      this.formFieldConsumer.onFocusChange(false);
    }
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  private handleDismiss = () => {
    this.handleChangeText('');
  };

  private isControlled = () => this.props.value !== undefined;

  private getCurrentValue = () => {
    if (this.isControlled()) {
      return this.props.value;
    }
    return this.formFieldConsumer && this.formFieldConsumer.value ? this.formFieldConsumer.value : this.state.value;
  };
}
