import * as React from 'react';
import { AppPath } from '@app/modules/app/route-constants';
import { Redirect } from './redirect.component';

export const RedirectCache: React.FC = () => {
  let redirectInternalPath = AppPath.ORDERS;

  if (window.localStorage.getItem('path')) {
    const localStoragePath = window.localStorage.getItem('path');

    if (localStoragePath) {
      redirectInternalPath = JSON.parse(localStoragePath);
    }
  }
  return <Redirect to={redirectInternalPath} />;
};
