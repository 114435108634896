import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../nps-modal.constats';
import { INpsState } from '../nps-modal.interfaces';
import { initialState } from './nps-modal.initial';
import { reducers } from './nps-modal.reducers';

export const npsSlice = createSlice<INpsState, SliceCaseReducers<INpsState>>({
  name: SLICE_NAME,
  initialState,
  reducers,
});

export const { setDontSeeAgain, setPhase, setNote, setMessage, submitNps } = npsSlice.actions;

export const npsReducer = npsSlice.reducer;
