import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { defaultCrmHeaders, endpointCrm } from '@app/data/http';
import { CrmNotesResponse, GetCrmParams, mapNotesData } from '@app/data/http/crm.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export async function getNotes(params?: GetCrmParams): Promise<CrmNotesResponse> {
  const user = Container.get(AuthStorageService).getUser();
  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpointCrm.CRM}/${params.id}${endpointCrm.NOTES}`;

  const { data, status } = await axios.get(url, { headers: { ...defaultCrmHeaders, 'sso-access': user.ssoAccess } });

  if (status === 200) {
    return data;
  } else {
    return null;
  }
}

export function useGetNotes(params: GetCrmParams, onError?) {
  return useQuery(['getNotes', params], () => getNotes(params), {
    onError,
    enabled: params.enable,
    select: data => {
      return mapNotesData(data.data);
    },
    refetchOnWindowFocus: false,
  });
}
