import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { defaultCrmHeaders, endpointCrm } from '@app/data/http';
import { CrmListResponse } from '@app/data/http/crm.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { CrmParams } from '@app/models/crm.model';

export async function getCrmList(params?: CrmParams): Promise<CrmListResponse> {
  const user = Container.get(AuthStorageService).getUser();
  let search = params.filters.currentFilter?.search ? `&search=${params.filters.currentFilter?.search?.join()}` : '';

  if (params.filters.startDate) {
    search = `${search}&startDate=${params.filters.startDate}&endDate=${params.filters.endDate}`;
  }

  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpointCrm.CRM}?skip=${
    params.filters.paginate.skip
  }&take=${params.filters.paginate.take}&limit=${params.filters.paginate.limit}${search}&lang=${params.filters.lang}`;

  let numFilters = 0;
  if (params?.filters?.currentFilter) {
    Object.keys(params?.filters?.currentFilter).forEach(item => {
      item !== 'search' && numFilters++;
    });

    if (numFilters > 0) {
      const filter = {};

      Object.entries(params.filters.currentFilter).forEach(item => {
        if (item[0] !== 'search') {
          filter[item[0]] = item[1].join('|');
        }
      });

      const dataPost = {
        filter,
      };

      const { data, status } = await axios.post(url, dataPost, {
        headers: { ...defaultCrmHeaders, 'sso-access': user.ssoAccess },
      });
      if (status === 200 || status === 404) {
        return data;
      } else {
        return null;
      }
    } else {
      const { data, status } = await axios.get(url, {
        headers: { ...defaultCrmHeaders, 'sso-access': user.ssoAccess },
      });
      if (status === 200 || status === 404) {
        return data;
      } else {
        return null;
      }
    }
  } else {
    const { data, status } = await axios.get(url, { headers: { ...defaultCrmHeaders, 'sso-access': user.ssoAccess } });
    if (status === 200 || status === 404) {
      return data;
    } else {
      return null;
    }
  }
}

export function useGetCrmList(params?: CrmParams, onError?) {
  return useQuery(['getCrmList', params], () => getCrmList(params), {
    onError,
    enabled: params.filters.enable,
    refetchOnWindowFocus: false,
  });
}
