import { PayloadAction } from '@reduxjs/toolkit';
import Container from 'typedi';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { NpsPhase } from '../nps-modal.constats';
import { INpsState } from '../nps-modal.interfaces';
import { sendNpsAnalytcs } from '../utils';

const user = Container.get(AuthStorageService).getUser();

export const reducers = {
  setDontSeeAgain: (state: INpsState, { payload }: PayloadAction<boolean>) => {
    state.dontSeeAgain = payload;
  },
  setPhase: (state: INpsState, { payload }: PayloadAction<NpsPhase>) => {
    state.phase = payload;
  },
  setNote: (state: INpsState, { payload }: PayloadAction<number>) => {
    state.note = payload;
  },
  setMessage: (state: INpsState, { payload }: PayloadAction<string>) => {
    state.message = payload;
  },
  submitNps: (state: INpsState, { payload }: PayloadAction<{ salesOrg: string; isTopAccount: boolean }>) => {
    const params = {
      isTopAccount: payload.isTopAccount,
      salesOrg: payload.salesOrg,
      note: state.note,
      message: state.message,
    };

    sendNpsAnalytcs(params, user);

    state.phase = NpsPhase.Success;
  },
};
