import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'react-styled-flexboxgrid';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { BackButton } from '@app/modules/components/back-button/back-button.component';
import { setSelectedRows } from '@app/modules/order/order-tabs/MI/tab-stock/store/order-tab-stock.store';
import { ShippingRequestEvents } from '@app/modules/order/order.analytics';
import { Breadcrumb } from '@atomic/atm.breadcrumb';
import { Frame } from '@atomic/atm.frame';
import { FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { IShippingRequestStrings, Strings } from '../../shipping-request.string';
import { setShowShippingRequest, setTableRows } from '../../store/shipping-request.store';

export const ShippingRequestHeader: React.FC = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IShippingRequestStrings = Strings[userInfo.language];

  const handleClickBackButton = () => {
    dispatch(setShowShippingRequest(false));
    dispatch(setSelectedRows([]));
    dispatch(setTableRows({ selectedRows: [] }));
    LogAnalytics.click({ tipo: ShippingRequestEvents.CancelShippingRequest });
  };

  const breadCrumbData = strings.page.breadcrumbs.map(breadcrumb => ({
    label: breadcrumb.label,
    onLinkClick: handleClickBackButton,
  }));

  return (
    <Frame>
      <Grid fluid>
        <FlexRow pt={Spacing.Small} pb={Spacing.Small} vAlign='center' hAlign='space-between'>
          <Breadcrumb data={breadCrumbData} />
          <BackButton handleClick={handleClickBackButton} />
        </FlexRow>
      </Grid>
    </Frame>
  );
};
