import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { AuthStorageService } from '../storage/auth.storage';
import {
  IAnnouncementData,
  IAnnouncementDto,
  IAnnouncementItem,
  IAnnouncementParams,
  IPatchAnnouncementParams,
  IPutAnnouncementParams,
  mapAnnouncementsResponse,
} from './announcement.dto';

const endpoint = {
  ANNOUNCEMENTS: '/announcements',
};

export const AnnouncementModalDatasource = {
  getAnnouncements: (params: IAnnouncementParams): Promise<IAnnouncementItem[]> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.ANNOUNCEMENTS)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: IAnnouncementDto): Promise<IAnnouncementData> => mapAnnouncementsResponse(res),
      })
      .execute();
  },
  putAnnouncement: (params: IPutAnnouncementParams): Promise<IAnnouncementItem[]> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .put(endpoint.ANNOUNCEMENTS)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .data(params)
      .execute();
  },
  patchAnnouncement: (params: IPatchAnnouncementParams): Promise<IAnnouncementItem[]> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .patch(endpoint.ANNOUNCEMENTS)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .data(params)
      .execute();
  },
};
