/* eslint-disable prettier/prettier */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStore } from '@app/core/global-store.service';
import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { useRequest } from '@app/core/hook/request.hook';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { NotificationDatasource } from '@app/data/http/notification.datasource';
import { NotificationPanelSteps } from '@app/models/notification-center.model';
import { NotificationStore, useNotificationStore } from '@app/providers/notification.store';
import { Drawer } from '@atomic/atm.drawer';
import { Grid, VSeparator } from '@atomic/obj.grid';
import { validateTokenAndGetNotification } from '../../components/notification-center/store/thunk/validate-and-get-notification.thunk';
import { NotificationConfiguration } from './notification-configuration.component';
import { Notification } from './notification.component';

export interface NotificationPanelProps {
  active: boolean;
  onCloseClick: () => void;
}

const NOTIFICATION_INTERVAL = 60000;

export const NotificationPanel: React.FC<NotificationPanelProps> = props => {
  const { ssoAccess } = useSelector((state: RootState) => state.auth);
  const { isFirstPageLoad } = useSelector((state: RootState) => state.notification);
  const dispatch = useDispatch<AppThunkDispatch>();

  const { currentStep, setConfiguration, setNotification, setCurrentStep } = useStore<NotificationStore>(
    useNotificationStore,
  );

  const { loading: notificationLoading, error: notificationError, performRequest: getNotification } = useRequest(
    NotificationDatasource.getNotification,
    {},
    setNotification,
  );

  const onBackClick = () => {
    getConfiguration();
    setCurrentStep(NotificationPanelSteps.Notification);
  };

  React.useEffect(() => {
    const interval = setInterval(async () => {
      if (isFirstPageLoad && ssoAccess) {
        await dispatch(validateTokenAndGetNotification());
      } else {
        getNotification();
      }
    }, NOTIFICATION_INTERVAL);

    return () => clearInterval(interval);
  }, [isFirstPageLoad]);

  const { loading: configurationLoading, error: configurationError, performRequest: getConfiguration } = useRequest(
    NotificationDatasource.getConfiguration,
    {},
    setConfiguration,
  );

  const { performRequest: clearNotification } = useLazyRequest(NotificationDatasource.deleteNotifications, () =>
    getNotification(),
  );

  return (
    <Drawer active={props.active} right large onClick={props.onCloseClick}>
      <VSeparator />
      <Grid fluid>
        {currentStep === NotificationPanelSteps.Notification ? (
          <Notification
            onCloseClick={props.onCloseClick}
            onConfigurationClick={() => setCurrentStep(NotificationPanelSteps.Configuration)}
            loading={notificationLoading}
            error={!!notificationError}
            onRefetch={getNotification}
            onClearClick={ids => clearNotification({ notificationIds: ids })}
          />
        ) : (
          <NotificationConfiguration
            loading={configurationLoading}
            error={!!configurationError}
            onRefetch={getConfiguration}
            onBackClick={onBackClick}
          />
        )}
      </Grid>
    </Drawer>
  );
};
