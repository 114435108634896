import React from 'react';
import { selectedItemTableColumnsDefinitions } from '@app/modules/shipping-request/shipping-request.constants';
import {
  IShippingRequestColumns,
  IShippingRequestRow,
} from '@app/modules/shipping-request/shipping-request.interfaces';
import { TD, TR } from '@atomic/mol.table';
import { ShippingRequestTableBodyTD } from '../../../shipping-request-body/partials/shipping-request-table/partials/shipping-request-table-body/partials/shipping-request-table-body-td/shipping-request-table-body-td.component';

interface IShippingRequestSelectedItemTableBodyProps {
  item: IShippingRequestRow;
}

export const ShippingRequestSelectedItemTableBody: React.FC<IShippingRequestSelectedItemTableBodyProps> = ({
  item,
}) => {
  return (
    <TR key={item.index} bordered>
      {selectedItemTableColumnsDefinitions.map((column: IShippingRequestColumns) => (
        <TD key={column.field}>
          <ShippingRequestTableBodyTD item={item} type={column.type} value={item[column.field]} />
        </TD>
      ))}
    </TR>
  );
};
