import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogAnalytics } from '@app/core/analytics';
import { AdminDataSource } from '@app/data/http/admin.datasource';
import { IRole } from '@app/models/admin.model';
import { authErrorMessageMap } from '@app/modules/auth/auth-error.mapper';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { AdminUserEvents } from '../../admin.analytics';
import { SLICE_NAME } from '../../admin.constants';

export const getRoles = createAsyncThunk(`${SLICE_NAME}/getRoles`, async (_, thunkAPI) => {
  return await AdminDataSource.getRoles()
    .then(data => data)
    .catch(err => {
      LogAnalytics.error({
        tipo: AdminUserEvents.GetRolesError,
        message: authErrorMessageMap[parseInt(err.response.status)],
      });
      thunkAPI.dispatch(
        setToast({
          show: true,
          text: authErrorMessageMap[parseInt(err.response.status)],
          variant: ToastVariant.DANGER,
        }),
      );
      return thunkAPI.rejectWithValue({ status: err.response.status });
    });
});

export const addGetProfileReducers = builder => {
  builder.addCase(getRoles.pending, state => {
    state.loadingProfile = true;
  });
  builder.addCase(getRoles.fulfilled, (state, action) => {
    const payload: IRole[] = action.payload as IRole[];
    state.roles = payload;
    state.eppRoleConfig = payload.filter(p => p.profile === 'epp')[0];
    state.pedRoleConfig = payload.filter(p => p.profile === 'ped')[0];
    state.rtcRoleConfig = payload.filter(p => p.profile === 'rtc')[0];
    state.gqRoleConfig = payload.filter(p => p.profile === 'gq')[0];
    state.adminRoleConfig = payload.filter(p => p.profile === 'admin')[0];
    state.sellerRoleConfig = payload.filter(p => p.profile === 'seller')[0];
    state.scmRoleConfig = payload.filter(p => p.profile === 'scm')[0];
    state.squadRoleConfig = payload.filter(p => p.profile === 'squad')[0];
    state.loadingProfile = false;
  });
  builder.addCase(getRoles.rejected, state => {
    state.loadingProfile = false;
    state.loading = false;
  });
};
