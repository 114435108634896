/* eslint-disable prettier/prettier */
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { columnsTabLayoutSelector } from '@app/core/redux/grid-layout/grid-layout.selectors';
import { setTabColumns } from '@app/core/redux/grid-layout/grid-layout.store';
import { postGridLayout } from '@app/core/redux/grid-layout/thunks/grid-layout-post.thunk';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { mapDateColumns } from '@app/data/http/layout.dto';
import { useFinanceQuery } from '@app/domain';
import { HelpCenterSectionKeys, HelpCenterSubsectionKeys } from '@app/models/help-center.model';
import { LayoutTabs } from '@app/models/layout.model';
import { AgGrid, GridColumnFilter } from '@app/modules/components';
import { GridDownload } from '@app/modules/components/grid-download/grid-download.component';
import { FlashMessageTargetName, MarketType } from '@app/providers';
import { Badge } from '@atomic/atm.badge';
import { Image } from '@atomic/atm.image';
import { FlexColumn, FlexRow, Hbox } from '@atomic/obj.box';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { gridValueToCurrency, toCurrency } from '@atomic/obj.utils';
import { AdvancedFiltersButton } from '../components/advanced-filters-button/advanced-filters-button.component';
import { useFlashMessage } from '../components/flash-message.hook';
import { GridButtonWithIcon } from '../components/grid-button-with-icon.component';
import { AgGridRowCounter } from '../order/grid-counter/grid-row-counter.component';
import { OrderTabErrorRendererComponent } from '../order/order-tabs/ErrorRenderer/error-renderer';
import { handleDebounce } from '../order/utils/handler-utils';
import { FinanceGridRowFilter } from './finance-grid-row-filter.component';
import { AgGridFinanceWrapperStyled, FinanceGridWrapper } from './finance-grid.style';
import { Strings, IFinanceStrings } from './finance.string';
import { renderFinanceSubtotalField } from './grid-utils/grid-finance-field-render';
import { filteredDataSelector } from './store/finance.selectors';

const StatusCellRenderer: React.FC<{ data: any }> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { currentMarket } = useSelector((state: RootState) => state.customerPersist);

  const { data } = props;

  const strings: IFinanceStrings = Strings[userInfo.language];

  if (!data) {
    return null;
  }

  switch (currentMarket === MarketType.Internal ? data.statusCompensation : data.status) {
    case 'PAID':
      return <Badge color='success'>{strings?.paid}</Badge>;
    case 'EXPIRED':
      return <Badge color='alert'>{strings?.expired}</Badge>;
    case 'NOT EXPIRED':
      return <Badge color='warning'>{strings?.notExpired}</Badge>;
    default:
      return <span>{currentMarket === MarketType.Internal ? data.statusCompensation : data.status}</span>;
  }
};

interface FinanceGridProps {
  clientNumber: string;
}

export const FinanceGrid: React.FC<FinanceGridProps> = ({ clientNumber }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IFinanceStrings = Strings[userInfo?.language];
  const { currentMarket, clientBpsSelected } = useSelector((state: RootState) => state.customerPersist);
  const { fetch, data, loading, error } = useFinanceQuery(clientBpsSelected[0].market);
  const filteredData = useSelector((state: RootState) => filteredDataSelector(state, data));
  const tabName = clientBpsSelected[0].market === MarketType.Internal ? LayoutTabs.FinanceMI : LayoutTabs.FinanceME;
  const columnDef = useSelector(columnsTabLayoutSelector(tabName, userInfo?.language));
  const { toogleVisible } = useSelector((state: RootState) => state.gridLayout);
  const [columnState, setColumnState] = useState([]);
  const dispatch = useDispatch<AppThunkDispatch>();
  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  const gridEvent = React.useRef<GridReadyEvent>(null);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [isGridFilter, setIsGridFilter] = React.useState(false);

  const handleLoad = () => {
    fetch(clientNumber);
  };

  useEffect(() => {
    setColumnState(columnDef);
  }, [toogleVisible]);

  useEffect(() => {
    handleLoad();
  }, [fetch, clientNumber]);

  useEffect(() => {
    if (columnDef.length) {
      if (isGridFilter) {
        renderFinanceSubtotalField(filteredRows, userInfo?.language, columnState);
      } else if (filteredData) {
        setIsGridFilter(false);
        renderFinanceSubtotalField(filteredData, userInfo?.language, columnState);
      }
    }
  }, [filteredData, filteredRows, columnState]);


  const handleGridReady = (event: GridReadyEvent) => {
    LogAnalytics.pageView('FinancasVisualizado');
    gridEvent.current = event;
  };

  const handleGridFilter = (gridApi: GridApi) => {
    const rows = [];

    gridApi.forEachNodeAfterFilter(rowNode => {
      rows.push(rowNode.data);
    });

    setIsGridFilter(true);
    setFilteredRows(rows);
  };

  const handleDelayDays = (row: any) => {
    if (row && row.data && row.data.experyDate && row.data.experyDate.indexOf('/') > -1 && !row.data.compensation) {
      const experyDateSplit = row.data.experyDate.split('/');
      const dateExp = new Date(`${experyDateSplit[2]}/${experyDateSplit[1]}/${experyDateSplit[0]}`);
      const dateExpDay = dateExp.getDate();
      const delayedDaysInMilliseconds = new Date().getTime() - new Date(dateExp.setDate(dateExpDay + 1)).getTime();
      const delayedDays = Math.ceil(delayedDaysInMilliseconds / (1000 * 3600 * 24));

      return delayedDays;
    } else {
      return '';
    }
  };

  const handleSortDateColumn = (cols: ColDef[]) => {
    if (cols) {
      cols.forEach(column => {
        column.filter = 'agTextColumnFilter';

        if (column.field === 'experyDate' || column.field === 'shipmentDate' || column.field === 'launchDate') {
          mapDateColumns(column, userInfo?.language);
        }

        if (column.field === 'amount' || column.field === 'amountBranch') {
          if (currentMarket === MarketType.Internal) {
            column.valueFormatter = col => toCurrency(col.value?.toString(), 'BRL');
          } else {
            column.valueFormatter = col =>
              gridValueToCurrency(col.value?.toString(), userInfo?.language === 'in' ? 'USD' : 'BRL');
          }
        }

        if (column.field === 'status' || column.field === 'statusCompensation') {
          column.cellRenderer = 'statusCellRenderer';
          column.filter = 'agSetColumnFilter';
          column.floatingFilter = false;
          column.width = 200;
        }

        if (column.field === 'days') {
          column.valueGetter = handleDelayDays;
        }
      });
    }
  };

  handleSortDateColumn(columnState);

  const handleSuccess = () => {
    const columns: ColDef[] = gridEvent.current?.api?.getColumnDefs();
    show('success', strings.successSaveLayout);
    dispatch(setTabColumns({ name: tabName, cols: columns }));
  };

  const onSortChange = () => {
    const columns: ColDef[] = gridEvent.current?.api?.getColumnDefs();
    setColumnState(columns);
    handleDebounce(columns, () =>
      dispatch(postGridLayout({ tab: tabName, handleSuccess, columns, language: userInfo.language })),
    );
  };

  const onColumnChange = () => {
    const columns: ColDef[] = gridEvent.current?.api?.getColumnDefs();
    setColumnState(columns);
    handleDebounce(columns, () =>
      dispatch(postGridLayout({ tab: tabName, handleSuccess, columns, language: userInfo.language })),
    );
  };

  return (
    <FinanceGridWrapper>
      <Grid fluid>
        <Row pt>
          <Col xs={12} sm={7}>
            <FlexRow vAlign='center'>
              <AdvancedFiltersButton />
              <FinanceGridRowFilter gridEvent={gridEvent.current} market={currentMarket} />
            </FlexRow>
          </Col>
          <Col xs={12} sm={5}>
            <FlexRow hAlign='flex-end'>
              <FlexColumn noGrow>
                <GridButtonWithIcon
                  text={strings?.refresh}
                  icon={() => <Image.RefreshIcon />}
                  kind='secondary'
                  onClick={handleLoad}
                />
              </FlexColumn>
              <Hbox.Separator />
              <FlexColumn noGrow>
                <GridColumnFilter
                  tabName={tabName}
                  helpRedirect={{
                    section: HelpCenterSectionKeys.Glossary,
                    subsection: HelpCenterSubsectionKeys.FinanceGlossary,
                  }}
                />
              </FlexColumn>
              <Hbox.Separator />
              <FlexColumn noGrow>
                <GridDownload gridEvent={gridEvent.current} filename={strings.fileName} tabName={tabName} />
              </FlexColumn>
            </FlexRow>
          </Col>
        </Row>
        {!error ? (
          <Row>
            <Col xs={12}>
              <div id='finance-grid' className='ag-theme-balham'>
                <AgGridFinanceWrapperStyled>
                  <AgGrid
                    columnDef={columnState}
                    onGridReady={handleGridReady}
                    onFilterChanged={handleGridFilter}
                    rowData={filteredData}
                    loading={loading && columnState && !!columnState.length}
                    onColumnChange={onColumnChange}
                    onSortChange={onSortChange}
                    frameworkComponents={{
                      statusCellRenderer: StatusCellRenderer,
                    }}
                  />
                </AgGridFinanceWrapperStyled>
              </div>
            </Col>
          </Row>
        ) : (
          <OrderTabErrorRendererComponent errorMessage={strings?.agGrid.error} action={handleLoad} loading={loading} />
        )}
        {gridEvent?.current && <AgGridRowCounter rows={gridEvent.current.api.getDisplayedRowCount()} />}
      </Grid>
    </FinanceGridWrapper>
  );
};
