import * as React from 'react';
import { LogAnalytics } from '@app/core/analytics';
import { RouteComponentProps } from '@app/core/route';
import { QuoteScreensAnalytics } from '@app/modules/quote/quote.analytics';
import { TechnicalAnalysis } from './technical-analysis.component';
import { TechnicalAnalysisProvider } from './technical-analysis.context';

export const TechnicalAnalysisPage: React.FC<RouteComponentProps<{ id: string }, {}, {}>> = props => {
  LogAnalytics.setCurrentScreen(QuoteScreensAnalytics.TechnicalAnalysis + props.match.params.id);
  return (
    <TechnicalAnalysisProvider>
      <TechnicalAnalysis id={+props.match.params.id} />
    </TechnicalAnalysisProvider>
  );
};
