import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { defaultCrmComplainHeaders } from '@app/data/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export async function downloadAttachedFileComplain(params?: any): Promise<any> {
  const user = Container.get(AuthStorageService).getUser();

  const { data, status } = await axios.get(params.url, {
    headers: { ...defaultCrmComplainHeaders, 'sso-access': user.ssoAccess },
    responseType: 'arraybuffer',
  });

  if (status === 200) {
    const blob = new Blob([data], { type: params.type });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = params.filename;
    link.click();
  }
}

export const useDownloadAttachedFileComplain = () => {
  return useMutation(downloadAttachedFileComplain, {});
};
