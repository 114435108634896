import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { IShippingRequestRow } from '@app/modules/shipping-request/shipping-request.interfaces';
import { IShippingRequestStrings, Strings } from '@app/modules/shipping-request/shipping-request.string';
import {
  selectQtyWishedSelector,
  selectStockQuantitySelector,
} from '@app/modules/shipping-request/store/shipping-request.selectors';
import {
  setOpenAlertQtyModal,
  updateRequestedShippingQuantity,
} from '@app/modules/shipping-request/store/shipping-request.store';
import { EditQtyFieldStyled } from './shipping-request-edit-qty.style';

interface ShippingRequestEditQtyFieldProps {
  item: IShippingRequestRow;
}

export const ShippingRequestEditQtyField: React.FC<ShippingRequestEditQtyFieldProps> = ({ item }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { heat, salesOrder } = item;
  const stockQtyRow: number = useSelector(state => selectStockQuantitySelector(state, heat, salesOrder));
  const qtyWished: number = useSelector(state => selectQtyWishedSelector(state, heat, salesOrder));
  const [value, setValue] = useState(qtyWished);

  const strings: IShippingRequestStrings = Strings[userInfo.language];
  const dispatch = useDispatch();

  const onChange = newValue => {
    if (newValue >= 0) {
      setValue(newValue);
    } else {
      setValue(null);
    }
  };

  useEffect(() => {
    dispatch(updateRequestedShippingQuantity({ heat, salesOrder, value }));
  }, [value]);

  const hasError = qtyWished > stockQtyRow;

  useEffect(() => {
    if (hasError) {
      dispatch(setOpenAlertQtyModal(true));
    }
  }, [qtyWished, stockQtyRow]);

  return (
    <EditQtyFieldStyled
      hasError={hasError}
      placeholder={strings.page.placeholderQtyEdit}
      type='number'
      min='0'
      value={value}
      onValueChange={onChange}
    />
  );
};
