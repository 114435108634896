import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { endpoint, CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from '@app/data/http';
import { FullAnalysisResponse } from '@app/data/http/quote.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export const deleteComments = async (params?: any) => {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    client_id: CXP_KEY_CLIENT_ID,
    client_secret: CXP_KEY_CLIENT_SECRET,
    Authorization: 'Bearer ' + user.accessToken,
    access_token: user.accessToken,
    'Content-Type': 'application/json',
    Accept: '*/*',
    Language: params.language,
    'sso-access': user.ssoAccess,
  };

  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
    endpoint.ANALYSIS_PLANT
  }/${params.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}/${params.attributeKey}${endpoint.ANALYSIS_COMMENTS}/${
    params.commentKey
  }`;

  return await axios.delete(url, { headers: defaultHeaders });
};

export const useDeleteComment = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteComments, {
    onSuccess,
    onError,
    onMutate: async data => {
      await queryClient.cancelQueries(['getTechnicalAnalysis']);
      const previousData = queryClient.getQueriesData(['getTechnicalAnalysis']);
      queryClient.setQueriesData(['getTechnicalAnalysis'], (oldQueryData: FullAnalysisResponse) => {
        const selectedPlant = oldQueryData.plants.filter(plant => plant.key === data.plantKey);

        const { comments } = selectedPlant[0].attributes[data.ATTRIBUTE_INDEX];
        const commentIndex = comments.findIndex(comment => comment.key === data.commentKey);
        comments.splice(commentIndex, 1);

        return {
          ...oldQueryData,
        };
      });
      return {
        previousData,
      };
    },
  });
};
