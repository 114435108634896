import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { endpoint, CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from '@app/data/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export const deleteAttributes = async (params?: any) => {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    client_id: CXP_KEY_CLIENT_ID,
    client_secret: CXP_KEY_CLIENT_SECRET,
    Authorization: 'Bearer ' + user.accessToken,
    access_token: user.accessToken,
    'Content-Type': 'application/json',
    Accept: '*/*',
    language: params.language,
    'sso-access': user.ssoAccess,
  };

  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.data.analysisId}${
    endpoint.ANALYSIS_PLANT
  }/${params.data.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}/${params.data.attributeKey}`;

  const { data: dataResponse } = await axios.delete(url, { headers: defaultHeaders });

  return dataResponse;
};

export const useDeleteAttributes = (onSuccess?, onError?) => {
  const queryClient = useQueryClient();
  return useMutation(deleteAttributes, {
    onSuccess,
    onError,
    onMutate: async () => {
      await queryClient.cancelQueries(['getTechnicalAnalysis']);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['getTechnicalAnalysis']);
    },
  });
};
