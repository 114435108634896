import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET, endpoint } from '@app/data/http';
import { b64toBlob } from '@app/data/http/document.dto';
import { GetAssetsResponse } from '@app/data/http/quote-assets.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export async function getPriceQuotation(params?: any): Promise<GetAssetsResponse[]> {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    client_id: CXP_KEY_CLIENT_ID,
    client_secret: CXP_KEY_CLIENT_SECRET,
    'Content-Type': 'application/json',
    access_token: user.accessToken,
    Authorization: 'Bearer ' + user.accessToken,
    Accept: '*/*',
    Language: params.language,
    'sso-access': user.ssoAccess,
  };

  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.QUOTE}/${params.quoteId}${endpoint.PDF}`;

  const { data, status } = await axios.get(url, { headers: defaultHeaders });

  if (status === 200) {
    const blob = b64toBlob(data[0].pdfBase64, 'application/pdf');
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `Preço-${params.quoteId}.pdf`;
    link.click();
  }
  return null;
}

export const useDownloadPriceQuotation = () => {
  return useMutation(getPriceQuotation, {});
};
