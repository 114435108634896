import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Modal } from '@atomic/obj.modal';
import { SUGGESTION_MODAL_WIDTH } from '../../shipping-request.constants';
import { setOpenShippingRequestSuggestionModal } from '../../store/shipping-request.store';
import { ShippingRequestSuggestionModalBody } from './partials/shipping-request-suggestion-modal-body/shipping-request-suggestion-modal-body.component';
import { ShippingRequestSuggestionModalFooter } from './partials/shipping-request-suggestion-modal-footer/shipping-request-suggestion-modal-footer.component';
import { ShippingRequestSuggestionrModalHeader } from './partials/shipping-request-suggestion-modal-header/shipping-request-suggestion-modal-header.component';

export const ShippingRequestSuggestionModal: React.FC = () => {
  const { openShippingRequestSuggestionModalIndex } = useSelector((state: RootState) => state.shippingRequest);
  const dispatch = useDispatch();

  return (
    <Modal
      customWidth={SUGGESTION_MODAL_WIDTH}
      opened
      onClose={() => dispatch(setOpenShippingRequestSuggestionModal(null))}
    >
      <ShippingRequestSuggestionrModalHeader />
      <ShippingRequestSuggestionModalBody shippingRequestSuggestion={openShippingRequestSuggestionModalIndex} />
      <ShippingRequestSuggestionModalFooter />
    </Modal>
  );
};
