import Container from 'typedi';
import Cookies from 'universal-cookie';
import { LoginDataParam } from '@app/data/http/auth.params.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { IAuthState } from '../../auth.interfaces';
import { redirect } from '../../utils/utils';
import { hasGabAccessSelector, hasGsbAccessSelector } from '../auth.selectores';
//import { getAzureTokenThunk } from './get-azure-token.thunk';
import { initializeAndGetProfileThunk } from './initialize-and-get-profile.thunk';
import { loginData } from './login.thunk';

const authStorageService: AuthStorageService = Container.get(AuthStorageService);

export const loginAndGetProfileThunk = (login: LoginDataParam) => async (dispatch, getState) => {
  //await dispatch(getAzureTokenThunk());
  await dispatch(loginData(login));
  const { token, ssoAccess }: IAuthState = getState().auth;
  const hasGabAccess = hasGabAccessSelector(getState());
  const hasGsbAccess = hasGsbAccessSelector(getState());
  const gabUrl: string = Container.get('GabUrl');
  // const redirectUrl = login.redirectUrl;
  const cookies = new Cookies();

  if (token) {
    cookies.set('@access_token', token, { domain: '.egerdau.com.br' });
    cookies.set('@sso-access', ssoAccess, { domain: '.egerdau.com.br' });
    authStorageService.login({ accessToken: token });

    if (hasGabAccess) {
      // redirectUrl ? redirect(redirectUrl) : redirect(gabUrl);
      redirect(gabUrl);
    } else {
      if (!hasGabAccess && !hasGsbAccess) {
        dispatch(
          setToast({
            show: true,
            text:
              'Você não tem acesso a nenhum dos sistemas Gerdau Mais, para acessá-los, entre em contato com seu administrador',
            variant: ToastVariant.DANGER,
          }),
        );

        return;
      }

      console.log('CHAMOU O INITIALIZE NO LOGIN');
      await dispatch(initializeAndGetProfileThunk(token));
    }
  }

  return;
};
