import { Color, DesignSystemColor } from '@atomic/obj.constants';
import { IShippingRequestColumns, ITableColumnRegion } from './shipping-request.interfaces';

export const SLICE_NAME = 'shipping-request';
export const RECEIVER_MODAL_WIDTH = '620px';
export const ALERT_QTY_MODAL_WIDTH = '720px';
export const RECEIVER_SHIMMER_HEIGHT = '14px';
export const RECEIVER_SHIMMER_WIDTH = '80%';
export const SUGGESTION_MODAL_WIDTH = '80%';

export enum PlantNames {
  PINDA = '1412',
  MOGI = '1414',
  CHARQ = '1420',
}

export enum TableTDTypes {
  Normal = 'normal',
  Qty = 'qty',
  QtyEdit = 'qtyEdit',
  Obs = 'Obs',
  Button = 'Button',
  SuggestionCheckBox = 'SuggestionCheckBox',
}

export const nameBasedOnPlant = {
  1412: 'Pindamonhangaba',
  1414: 'Mogi das Cruzes',
  1420: 'Charqueadas',
};

export const tableColumnsDefinitions: IShippingRequestColumns[] = [
  { field: 'customerProductCode', title: 'Cód. prod. cliente', width: null, type: TableTDTypes.Normal },
  { field: 'materialDescription', title: 'Descrição material', width: 22, type: TableTDTypes.Normal },
  { field: 'material', title: 'Material', width: null, type: TableTDTypes.Normal },
  { field: 'salesOrder', title: 'Ordem de venda', width: null, type: TableTDTypes.Normal },
  { field: 'salesOrderItem', title: 'Item OV', width: null, type: TableTDTypes.Normal },
  { field: 'purchaseOrder', title: 'Ordem de compra', width: 8, type: TableTDTypes.Normal },
  { field: 'stockQuantity', title: 'Qtd. em estoque (t)', width: null, type: TableTDTypes.Qty },
  { field: 'shipmentQuantity', title: 'Qtd. em remessa (t)', width: null, type: TableTDTypes.Qty },
  { field: 'changeReceiver', title: 'Alterar recebedor', width: null, type: TableTDTypes.Button },
  { field: 'observations', title: 'Observações', width: 24, type: TableTDTypes.Obs },
];

export const tableColumnsDefinitionsGKN: IShippingRequestColumns[] = [
  {
    field: 'customerProductCode',
    title: 'Cód. prod. cliente',
    width: 4,
    type: TableTDTypes.Normal,
  },
  { field: 'materialDescription', title: 'Descrição material', width: 8, type: TableTDTypes.Normal },
  { field: 'salesOrder', title: 'Ordem de venda', width: 4, type: TableTDTypes.Normal },
  { field: 'customerAgreedDate', title: 'Data confirmada', width: 2, type: TableTDTypes.Normal },
  { field: 'heat', title: 'Corrida da OV', width: 2, type: TableTDTypes.Normal },
  {
    field: 'lastHeat',
    title: 'Última corrida faturada do material',
    width: 5,
    type: TableTDTypes.Normal,
    headerColor: Color.BlueLight,
  },
  {
    field: 'hasQuantityLastHeat',
    title: 'Última corrida tem material em produção/estoque?',
    width: 5,
    type: TableTDTypes.Normal,
    headerColor: Color.BlueLight,
  },
  {
    field: 'qtyStockLastHeat',
    title: 'Qtd em estoque da última corrida (t)',
    width: 5,
    type: TableTDTypes.Qty,
    headerColor: Color.BlueLight,
  },
  { field: 'stockQuantity', title: 'Qtd. em estoque sem remessa (t)', width: 4, type: TableTDTypes.Qty },
  {
    field: 'requestedShippingQuantity',
    title: 'Qtd desejada para faturamento (t)',
    headerColor: Color.BlueLightSecondary,
    width: 3,
    type: TableTDTypes.QtyEdit,
  },
  {
    field: 'observations',
    title: 'Observações',
    headerColor: Color.BlueLightSecondary,
    width: 8,
    type: TableTDTypes.Obs,
  },
];

export const tableColumnRegions: ITableColumnRegion[] = [
  { title: 'OV selecionada', colSpan: 5, color: Color.GrayLightSecondary, textColor: Color.Black },
  { title: 'Última corrida faturada', colSpan: 3, color: Color.Gray, textColor: Color.Black },
  { title: 'Qtd. da OV selecionada', colSpan: 1, color: Color.GrayLightSecondary, textColor: Color.Black },
  { title: 'Preencher', colSpan: 2, color: Color.Primary, textColor: Color.White },
];

export const OBS_COLUMN_HEADER = 'observations';

export const SHIPPING_REQUEST_LEFT_TOOLTIP_POSITION = 35;

export const selectedItemTableColumnsDefinitions: IShippingRequestColumns[] = [
  { field: 'customerProductCode', title: 'Cód. prod. cliente', width: 8, type: TableTDTypes.Normal },
  { field: 'materialDescription', title: 'Descrição material', width: 22, type: TableTDTypes.Normal },
  { field: 'salesOrder', title: 'Ordem de venda', width: 8, type: TableTDTypes.Normal },
  { field: 'purchaseOrder', title: 'Ordem de compra', width: 8, type: TableTDTypes.Normal },
  { field: 'customerAgreedDate', title: 'Data confirmada', width: 8, type: TableTDTypes.Normal },
  { field: 'stockQuantity', title: 'Qtd. em estoque (t)', width: 8, type: TableTDTypes.Qty },
  { field: 'shipmentQuantity', title: 'Qtd. em remessa (t)', width: 8, type: TableTDTypes.Qty },
  {
    field: 'stockQuantity',
    title: 'Qtd. da sua solicitação',
    width: 8,
    type: TableTDTypes.Qty,
    headerColor: DesignSystemColor.Primary,
    textColor: DesignSystemColor.White,
  },
];

export const getSuggestionTableColumnsDefinitions = (isMultipleOption: boolean) => {
  const suggestionTableColumnsDefinitions: IShippingRequestColumns[] = [
    { field: 'customerProductCode', title: 'Cód. prod. cliente', width: 8, type: TableTDTypes.Normal },
    { field: 'materialDescription', title: 'Descrição material', width: 22, type: TableTDTypes.Normal },
    { field: 'salesOrder', title: 'Ordem de venda', width: 8, type: TableTDTypes.Normal },
    { field: 'purchaseOrder', title: 'Ordem de compra', width: 8, type: TableTDTypes.Normal },
    { field: 'customerAgreedDate', title: 'Data confirmada', width: 8, type: TableTDTypes.Normal },
    { field: 'stockQuantity', title: 'Qtd. em estoque (t)', width: 8, type: TableTDTypes.Qty },
    { field: 'shipmentQuantity', title: 'Qtd. em remessa (t)', width: 8, type: TableTDTypes.Qty },
    {
      field: 'stockQuantity',
      title: 'Qtd. sugerida',
      width: 8,
      type: TableTDTypes.Qty,
      headerColor: DesignSystemColor.Primary,
      textColor: DesignSystemColor.White,
    },
  ];

  if (isMultipleOption) {
    suggestionTableColumnsDefinitions.push({
      field: 'checkBox',
      title: null,
      width: 6,
      type: TableTDTypes.SuggestionCheckBox,
    });
  }

  return suggestionTableColumnsDefinitions;
};
