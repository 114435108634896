import { addForgotPasswordReducers } from './forgot-password.thunk';
import { addGetTokenReducers } from './get-azure-token.thunk';
import { addLoginReducers } from './login.thunk';
import { addLogoutReducers } from './logout.thunk';
import { addRefreshTokenReducers } from './refresh-token.thunk';
import { addGetProfileReducers } from './user-profile.thunk';
import { addValidateTokenReducers } from './validate-token.thunk';

export const addThunkReducers = builder => {
  addLoginReducers(builder);
  addGetProfileReducers(builder);
  addLogoutReducers(builder);
  addForgotPasswordReducers(builder);
  addGetTokenReducers(builder);
  addValidateTokenReducers(builder);
  addRefreshTokenReducers(builder);
};
