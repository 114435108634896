import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TEST_ID_CLOSE_MODAL } from '@app/constants';
import { LogAnalytics } from '@app/core/analytics';
import { useStore } from '@app/core/global-store.service';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { EventTypes } from '@app/models/analytics.model';
import { CSATModule } from '@app/modules/admin-settings/admin-settings.modules';
import { UserStore, useUserStore } from '@app/providers';
import { postAnswerCsatUser } from '@app/providers/csat/csat-post.thunk';
import { setOpenNpsModal } from '@app/providers/navigation/navigation.store';
import { Button } from '@atomic/atm.button';
import { CheckboxField } from '@atomic/atm.checkbox';
import { FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { NpsPhase } from '../../nps-modal.constats';
import { InpsModalStrings, Strings } from '../../nps.string';
import { setDontSeeAgain } from '../../store/nps-modal.store';
import { NpsModalAdvanceButton } from './nps-modal-advance-button';
import { NpsModalFooterWrapper } from './nps-modal-footer.style';

export const NpsModalFooter: React.FC = () => {
  const { user } = useStore<UserStore>(useUserStore);
  const strings: InpsModalStrings = Strings[user.language];
  const dispatch = useDispatch<AppThunkDispatch>();
  const { dontSeeAgain, phase } = useSelector((state: RootState) => state.nps);
  const { loadingPost } = useSelector((state: RootState) => state.csat);

  const handleSuccess = () => {
    dispatch(setOpenNpsModal(false));
  };

  const npsProgress = phase !== NpsPhase.Success;

  const handleClose = () => {
    if (dontSeeAgain && npsProgress) {
      dispatch(postAnswerCsatUser({ functionality: CSATModule.nps, handleSuccess }));
      LogAnalytics.logEvent(EventTypes.Click, { kind: 'nps-not-reply' });
    } else if (!npsProgress) {
      dispatch(postAnswerCsatUser({ functionality: CSATModule.nps, handleSuccess }));
    } else {
      dispatch(setOpenNpsModal(false));
      LogAnalytics.logEvent(EventTypes.Click, { kind: 'nps-reply-later' });
    }
  };

  return (
    <NpsModalFooterWrapper>
      {npsProgress && (
        <FlexRow mr>
          <CheckboxField
            paddingLeft={Spacing.Large}
            checked={dontSeeAgain}
            onValueChange={() => dispatch(setDontSeeAgain(!dontSeeAgain))}
            id='dontSeeAgain'
          >
            {strings.dontSeeAgainMessage}
          </CheckboxField>
        </FlexRow>
      )}
      <Button
        testid={`${TEST_ID_CLOSE_MODAL}-nps`}
        kind='secondary'
        loading={loadingPost}
        onClick={() => handleClose()}
      >
        {strings?.closeButton}
      </Button>
      {npsProgress && <NpsModalAdvanceButton />}
    </NpsModalFooterWrapper>
  );
};
