import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { NewsModalResponse } from '@app/models/news-modal.model';
import { CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from './constants';

const endpoint = {
  MODAL: '/modal',
  USER: '/user',
  ANSWER: '/answer',
  ADMIN: '/admin',
};

export const NewsModalDatasource = {
  getNews: (): Promise<NewsModalResponse> => {
    const user = Container.get(AuthStorageService).getUser();
    return Container.get(HttpRequestBuilder)
      .get(endpoint.MODAL + endpoint.USER)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        client_id: CXP_KEY_CLIENT_ID,
        client_secret: CXP_KEY_CLIENT_SECRET,
        access_token: user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .params({ identifier: user.uid })
      .execute();
  },
  setViewedNews: (functionality: string): Promise<NewsModalResponse> => {
    const user = Container.get(AuthStorageService).getUser();
    return Container.get(HttpRequestBuilder)
      .post(endpoint.MODAL + endpoint.USER + endpoint.ANSWER)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        client_id: CXP_KEY_CLIENT_ID,
        client_secret: CXP_KEY_CLIENT_SECRET,
        access_token: user.accessToken,
        'Content-Type': 'application/json',
        'sso-access': user.ssoAccess,
      })
      .data({ identifier: user.uid, functionality: functionality })
      .execute();
  },
};
