import Container from 'typedi';
import Cookies from 'universal-cookie';
import { AuthStorageService } from '../../../../../data/storage/auth.storage';
import { IAuthState } from '../../../../auth/auth.interfaces';
import { buildUserData } from '../../../../auth/store/thunk/initialize-and-get-profile.thunk';
import { refreshTokenRequest } from '../../../../auth/store/thunk/refresh-token.thunk';
import { validateToken } from '../../../../auth/store/thunk/validate-token.thunk';
import { setIsFirstPageLoad } from '../notification.store';
import { getNotification } from './get-notification.thunk';

const authStorageService: AuthStorageService = Container.get(AuthStorageService);

export const validateTokenAndGetNotification = () => async (dispatch, getState) => {
  const { token, userInfo, ssoAccess }: IAuthState = getState().auth;

  await dispatch(
    validateToken({
      token: token,
      ssoAccess: ssoAccess,
    }),
  );

  const { isActive, refreshToken }: IAuthState = getState().auth;

  if (isActive) {
    await dispatch(refreshTokenRequest(refreshToken));
    await dispatch(getNotification());
    const cookies = new Cookies();
    cookies.set('@access_token', token, { domain: '.egerdau.com.br' });
    authStorageService.login(buildUserData(token, userInfo, ssoAccess));
    await dispatch(setIsFirstPageLoad(false));
  } else {
    authStorageService.logout();
  }

  return;
};
