import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { AppPath } from '@app/modules/app/route-constants';
import { AuthBox } from '@app/modules/components';
import { BodySecondary, InputLabel } from '@atomic/atm.typography';
import { VSeparator } from '@atomic/obj.grid';
import { setForgotPasswordData } from '../../store/auth.store';
import { forgotPassword } from '../../store/thunk/forgot-password.thunk';
import { ForgotPasswordForm } from './forgot-password-form.component';

export const ForgotPasswordPage: React.FC = () => {
  const email = React.useRef<string>();
  const { forgotPasswordLoading, forgotPasswordData } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppThunkDispatch>();

  const redirect = (url: string) => {
    if (window) {
      window.location.href = url;
    }
  };

  const handleSubmit = (formEmail: string) => {
    email.current = formEmail;
    dispatch(forgotPassword({ username: formEmail, factorType: 'EMAIL' }));
  };

  useEffect(() => {
    if (forgotPasswordData) {
      setInterval(() => {
        dispatch(setForgotPasswordData(null));
        redirect(AppPath.AUTHENTICATION);
      }, 5000);
    }
  }, [forgotPasswordData]);

  return (
    <AuthBox>
      <ForgotPasswordForm onSubmit={handleSubmit} disabled={!!forgotPasswordData} loading={forgotPasswordLoading}>
        {forgotPasswordData && (
          <>
            <InputLabel>Email enviado com sucesso para {email.current}. </InputLabel>
            <BodySecondary>Você será redirecionado em instantes</BodySecondary>
            <VSeparator />
          </>
        )}
      </ForgotPasswordForm>
    </AuthBox>
  );
};
