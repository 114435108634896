import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET, endpoint } from '@app/data/http';
import { FullAnalysisResponse } from '@app/data/http/quote.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export const editComments = async (params?: any) => {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    client_id: CXP_KEY_CLIENT_ID,
    client_secret: CXP_KEY_CLIENT_SECRET,
    Authorization: 'Bearer ' + user.accessToken,
    access_token: user.accessToken,
    'Content-Type': 'application/json',
    Accept: '*/*',
    Language: params.language,
    'sso-access': user.ssoAccess,
  };

  //value: params.editedComment

  const dataPost = { value: params.editedComment };

  // `${endpoint.ANALYSIS}/${params.analysisId}${endpoint.ANALYSIS_PLANT}/${params.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}/${params.attributeKey}${endpoint.ANALYSIS_COMMENTS}/${params.commentKey}`;

  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
    endpoint.ANALYSIS_PLANT
  }/${params.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}/${params.attributeKey}${endpoint.ANALYSIS_COMMENTS}/${
    params.commentKey
  }`;

  const { data } = await axios.patch(url, dataPost, { headers: defaultHeaders });

  return data;
};

export const useEditComment = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(editComments, {
    onSuccess,
    onError,
    onMutate: async data => {
      await queryClient.cancelQueries(['getTechnicalAnalysis']);
      const previousData = queryClient.getQueriesData(['getTechnicalAnalysis']);
      queryClient.setQueriesData(['getTechnicalAnalysis'], (oldQueryData: FullAnalysisResponse) => {
        const selectedPlant = oldQueryData.plants.filter(plant => plant.key === data.plantKey);

        if (selectedPlant[0].attributes[data.ATTRIBUTE_INDEX]?.comments) {
          const com = selectedPlant[0].attributes[data.ATTRIBUTE_INDEX].comments.filter(
            comment => comment.key === data.commentKey,
          );
          com[0].value = data.editedComment;
        }
        return {
          ...oldQueryData,
        };
      });
      return {
        previousData,
      };
    },
  });
};
