import styled from 'styled-components';
import { Border, Color, FontFamily, FontSize, FontWeight, Spacing } from '@atomic/obj.constants';
import { TextAlignProps } from '.';

const TABLE_GUTTER = Spacing.Small;

interface TableStyledProps {
  bordered?: boolean;
  color?: string;
  collapse: boolean;
}

export const TableStyled = styled.table`
  border-style: solid;
  border-width: 0;
  border-color: ${Border.Color};
  width: 100%;
  border-collapse: ${(props: TableStyledProps) => (props.collapse ? 'collapse' : 'separate')};
  border-spacing: 0;
  text-align: center;
  font-family: ${FontFamily.Primary};
  font-size: ${FontSize.Small};
`;

export const TableHeadStyled = styled.thead``;

export const TableHeaderStyled = styled.th`
  background-color: ${(props: TextAlignProps) => (props.color ? props.color : Color.White)};
  color: ${Color.GrayDark};
  padding: ${TABLE_GUTTER} ${Spacing.Small};
  text-align: ${(props: TextAlignProps) => props.textAlign || 'center'};
`;

export const TableColumnStyled = styled.td`
  background-color: ${(props: TextAlignProps) => (props.color ? props.color : 'transparent')};
  border-style: solid;
  border-color: ${Border.Color};
  border-width: 0;
  padding: ${Spacing.XSmall};
  width: auto;
  text-align: ${(props: TextAlignProps) => props.textAlign || 'center'};
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  vertical-align: middle;
  ${(props: TextAlignProps) => props.bordered && `border: 2px solid ${Border.Color};`}
  ${(props: TextAlignProps) => props.width && `width: ${props.width}%;`}
`;

export const TableRowStyled = styled.tr`
  ${(props: TableStyledProps) => props.bordered && `border: 2px solid ${Border.Color};`}
  ${props =>
    props.sticky &&
    `td:first-child {
      position: sticky;
      left: -${Spacing.XSmall};
    }
  `}
  ${(props: TableStyledProps) => props.color && `background: ${props.color}`}
`;
