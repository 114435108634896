import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import {
  FrontierSalesOrderItem,
  InternationalSalesOrderItemSummary,
  ShippedSalesOrderItem,
} from '@app/models/me-road-order-item.model';
import { DocumentsProfileCard } from '@app/models/order-documents.model';
import { AuthStorageService } from '../storage/auth.storage';
import {
  FrontierSalesOrderDto,
  InternationalSalesOrderSummaryDto,
  mapFrontierOrder,
  mapInternationalSalesOrderSummary,
  mapShippedOrder,
  ShippedSalesOrderDto,
} from './me-road-order.dto';
import { mapDocumentsResult } from './order-documents.dto';
import {
  DocumentsOrderParams,
  DocumentsResponse,
  FrontierMeOrderParams,
  OpenMeOrderParams,
  ShippedMeOrderParams,
} from './order-params.dto';

const endpoint = {
  SALES_ORDER: '/sales-orders',
  SALES_ORDER_SUMMARY: '/sales-orders-online-sumary',
  ONLINE_BILLING: '/online-billing',
  INTERNAL_MARKET: '/mi',
  EXTERNAL_MARKET: '/me',
  OPENED: '/open',
  BILLED: '/billed',
  SHIPPED: '/shipped',
  FRONTIER: '/bordering',
  STOCK: '/stock/advanced',
};

export const MERoadOrderDataSource = {
  getInternationalOpenOrderSummary: (params: OpenMeOrderParams): Promise<InternationalSalesOrderItemSummary[]> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.EXTERNAL_MARKET + endpoint.SALES_ORDER_SUMMARY)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: InternationalSalesOrderSummaryDto): Promise<InternationalSalesOrderItemSummary[]> =>
          mapInternationalSalesOrderSummary(res),
      })
      .execute();
  },

  getFrontierOrder: (params: FrontierMeOrderParams): Promise<FrontierSalesOrderItem[]> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.ONLINE_BILLING + endpoint.EXTERNAL_MARKET + endpoint.FRONTIER)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: FrontierSalesOrderDto): Promise<FrontierSalesOrderItem[]> => mapFrontierOrder(res),
      })
      .execute();
  },
  getShippedOrder: (params: ShippedMeOrderParams): Promise<ShippedSalesOrderItem[]> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.ONLINE_BILLING + endpoint.EXTERNAL_MARKET + endpoint.SHIPPED)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: ShippedSalesOrderDto): Promise<ShippedSalesOrderItem[]> => mapShippedOrder(res),
      })
      .execute();
  },

  getDocumentsOrder: (params: DocumentsOrderParams): Promise<DocumentsProfileCard[]> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.SALES_ORDER + endpoint.EXTERNAL_MARKET + endpoint.SHIPPED)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: DocumentsResponse): Promise<DocumentsProfileCard[]> => mapDocumentsResult(res),
      })
      .execute();
  },
};
