import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { endpoint, CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from '@app/data/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export async function getUsersByProfileAllData(params): Promise<any> {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    client_id: CXP_KEY_CLIENT_ID,
    client_secret: CXP_KEY_CLIENT_SECRET,
    Authorization: 'Bearer ' + user.accessToken,
    access_token: user.accessToken,
    Accept: '*/*',
    language: params.language,
    'sso-access': user.ssoAccess,
  };

  const path = params.profile === 'CLIENT' ? `bp=${params.bp}` : `profile=${params.profile}`;

  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.USERS}?${path}`;

  const { data, status } = await axios.get(url, { headers: defaultHeaders });

  if (status === 200) {
    return { data, profile: params.profile };
  } else {
    return null;
  }
}

export const useUsersByProfilesAllData = (onSuccess?, onError?) => {
  return useMutation(getUsersByProfileAllData, {
    onError,
    onSuccess,
    retry: false,
  });
};
