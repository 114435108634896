import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { GetLoginResponse, RefreshTokenResponse } from '@app/models';
import { AuthStorageService } from '../storage/auth.storage';
import { AuthHttpRequestBuilder } from './auth-http-request.builder';
import { LoginDataParam, LoginProfileResponse } from './auth.params.dto';

const endpoint = {
  LOGIN: '/login',
  LOGOUT: '/revoke-session',
  PROFILE: '/profile',
  FORGOT_PASSWORD: '/forgot-password',
  VALIDATE: '/validate',
  REFRESH_TOKEN: '/refresh-token',
};

export interface LogoutResponse {
  eGerdauResponse: {
    invalidated: boolean;
  };
}

export interface ForgotPasswordResponse {
  userName: string;
  factorType: string;
}

export interface ForgotPasswordParameters {
  username: string;
  factorType: string;
}

export const LoginDataSource = {
  login: (params: LoginDataParam): Promise<GetLoginResponse> => {
    return Container.get(AuthHttpRequestBuilder)
      .post(endpoint.LOGIN)
      .data(params)
      .headers({
        access_token: params.access_token,
        'sso-access': params.access_token ? true : false,
        redirect_uri: params.redirectUrl,
      })
      .execute();
  },

  profile: (token: string, ssoAcces: boolean): Promise<LoginProfileResponse> => {
    return Container.get(HttpRequestBuilder)
      .post(endpoint.PROFILE)
      .data('')
      .headers({
        Accept: '*/*',
        Authorization: 'Bearer ' + token,
        access_token: token,
        'Content-Type': 'application/json',
        'sso-access': ssoAcces,
      })
      .execute();
  },

  logout: (oktaUid: string): Promise<LogoutResponse> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(AuthHttpRequestBuilder)
      .delete(`${endpoint.LOGOUT}/${oktaUid}`)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .data('')
      .execute();
  },

  forgotPassword: (params: ForgotPasswordParameters): Promise<ForgotPasswordResponse> => {
    return Container.get(AuthHttpRequestBuilder)
      .post(endpoint.FORGOT_PASSWORD)
      .headers({
        'Content-Type': 'application/json',
      })
      .data(params)
      .execute();
  },

  changePassword: (params: ForgotPasswordParameters): Promise<ForgotPasswordResponse> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(AuthHttpRequestBuilder)
      .post(endpoint.FORGOT_PASSWORD)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        'sso-access': user.ssoAccess,
      })
      .data(params)
      .execute();
  },

  validateToken: (token: string, ssoAccess: boolean): Promise<ForgotPasswordResponse> => {
    return Container.get(AuthHttpRequestBuilder)
      .post(endpoint.VALIDATE)
      .headers({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'sso-access': ssoAccess,
      })
      .data({ token: token })
      .execute();
  },

  refreshToken: (refreshToken: string): Promise<RefreshTokenResponse> => {
    return Container.get(AuthHttpRequestBuilder)
      .post(endpoint.REFRESH_TOKEN)
      .headers({
        'Content-Type': 'application/json',
        'sso-access': true,
      })
      .data({ refreshToken: refreshToken })
      .execute();
  },
};
