import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET, endpoint } from '@app/data/http';
import { PostAssetsParams } from '@app/data/http/quote-assets-params.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export const postAssets = async (params?: PostAssetsParams) => {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    client_id: CXP_KEY_CLIENT_ID,
    client_secret: CXP_KEY_CLIENT_SECRET,
    access_token: user.accessToken,
    'Content-Type': 'application/json',
    Accept: '*/*',
    Language: params.language,
    'sso-access': user.ssoAccess,
  };

  const form = new FormData();

  if (!params.isClientVisible) {
    form.append('client_visible', `${params.isClientVisible ? params.isClientVisible : false}`);
  }
  form.append('file', params.file);

  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
    endpoint.ANALYSIS_ASSETS
  }`;

  await axios.post(url, form, { headers: defaultHeaders });
};

export const usePostAssets = onSuccess => {
  return useMutation(postAssets, {
    onSuccess,
  });
};
