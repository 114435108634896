import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { BaggHttpRequestBuilder, defaultCrmComplainHeaders, endpointRcs } from '@app/data/http';
import { CrmNotesResponseComplain, GetCrmParams, mapData } from '@app/data/http/crm.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export async function getNotes(params?: GetCrmParams): Promise<CrmNotesResponseComplain> {
  const user = Container.get(AuthStorageService).getUser();

  const url = `${Container.get(BaggHttpRequestBuilder).config.baseUrl}${endpointRcs.RCS}/${params.id}${
    endpointRcs.NOTES
  }`;

  const { data, status } = await axios.get(url, {
    headers: { ...defaultCrmComplainHeaders, 'sso-access': user.ssoAccess },
  });

  if (status === 200) {
    return data;
  } else {
    return null;
  }
}

export function useGetNotes(params: GetCrmParams, onError?) {
  return useQuery(['getNotes', params], () => getNotes(params), {
    onError,
    enabled: params.enable,
    select: data => {
      return mapData(data.data);
    },
    refetchOnWindowFocus: false,
  });
}
