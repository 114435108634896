import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { endpoint, CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from '@app/data/http';
import { PostAttributeParams } from '@app/data/http/quote-attributes-params.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export const postNewAttribute = async (params?: PostAttributeParams) => {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    client_id: CXP_KEY_CLIENT_ID,
    client_secret: CXP_KEY_CLIENT_SECRET,
    Authorization: 'Bearer ' + user.accessToken,
    access_token: user.accessToken,
    'Content-Type': 'application/json',
    Accept: '*/*',
    Language: params.language,
    'sso-access': user.ssoAccess,
  };

  const dataPost = {
    label: params.label,
    type: 'text',
    value: params.value,
  };

  const url = params.plantKey
    ? `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
        endpoint.ANALYSIS_PLANT
      }/${params.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}`
    : `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
        endpoint.ANALYSIS_PLANT
      }${endpoint.ANALYSIS_ATTRIBUTE}`;

  const { data } = await axios.post(url, dataPost, { headers: defaultHeaders });

  return data;
};

export const usePostNewAttribute = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(postNewAttribute, {
    onSuccess,
    onError,
    onMutate: async () => {
      await queryClient.cancelQueries(['getTechnicalAnalysis']);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['getTechnicalAnalysis']);
    },
  });
};
