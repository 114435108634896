import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { endpoint, CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from '@app/data/http';
import { GetAssetsResponse } from '@app/data/http/quote-assets.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export async function getExcelDashboard(params): Promise<GetAssetsResponse[]> {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    client_id: CXP_KEY_CLIENT_ID,
    client_secret: CXP_KEY_CLIENT_SECRET,
    access_token: user.accessToken,
    Accept: '*/*',
    Language: params.language,
    'sso-access': user.ssoAccess,
  };

  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}${
    endpoint.DASHBOARD
  }?limit=excel&sort=asc`;

  const { data, status } = await axios.get(url, { headers: defaultHeaders, responseType: 'arraybuffer' });

  if (status === 200) {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = URL.createObjectURL(blob);
    link.download = 'dashboard.xlsx';
    link.click();
  }

  return null;
}

export function useDownloadDashboard(params) {
  return useQuery(['getExcelDashboard', params], () => getExcelDashboard(params), {
    enabled: false,
    refetchOnWindowFocus: false,
  });
}
