import { addDays } from 'date-fns';
import format from 'date-fns/format';
import {
  Attributes,
  AttributesObjectItem,
  AttributesRangeUnit,
  AttributesRangeValue,
  AttributesResponse,
  AttributesTableCol,
  AttributesTableColumnsResponse,
  AttributesTableItem,
  AttributesTableResponse,
  AttributesTableRowResponse,
  AttributesTextValue,
  AttributesValue,
  ClientValueAttributeLabel,
  NluFormMap,
} from '@app/models/chat.model';
import { QuoteAttributeTypes } from '@app/models/quote.model';
import { dateParser } from '@app/utils/date-parser';
import { EditConversationData, EditConversationParams } from './quote-params.dto';

export const mapChatAttributes = (attributesResponse: AttributesResponse[]): Attributes[] => {
  return attributesResponse.map(attribute => ({
    key: attribute.key,
    label: attribute.label,
    type: attribute.type,
    value: mapAttributesValue(attribute.value, attribute.type),
    rangedValue: attribute.type === QuoteAttributeTypes.Range ? (attribute.value as AttributesRangeValue) : null,
    tableData: mapAttributesTableRows(attribute.value && (attribute.value as AttributesTableResponse).rows),
    unit: mapAttributesUnit(attribute),
    permissions: attribute.permissions,
    allowed: attribute.allowed,
    modifiedBy: attribute.creation?.modifiedBy,
    creation: attribute.creation?.origin,
    detail: attribute.detail,
  }));
};

export const mapAttributesTableCols = (attributesTableCols: AttributesTableColumnsResponse[]): AttributesTableCol[] =>
  attributesTableCols?.map(col => ({
    key: col.key,
    label: col.label,
    type: col.type,
  }));

export const mapAttributesTableRows = (attributesTableRows: AttributesTableRowResponse[][]): AttributesTableItem[] =>
  attributesTableRows &&
  attributesTableRows?.map(row =>
    row.reduce((accumulator, currentValue) => {
      accumulator[currentValue.columnRef] = currentValue.value;
      return accumulator;
    }, {}),
  );

const mapAttributesValue = (value: AttributesValue, type: string) => {
  let min = null;
  let max = null;
  switch (type) {
    case QuoteAttributeTypes.Table:
      return '';
    case QuoteAttributeTypes.Number:
      return value.toString();
    case QuoteAttributeTypes.Range:
      min = (value as AttributesRangeValue).min;
      max = (value as AttributesRangeValue).max;
      return !isNaN(max) ? `${min} a ${max}` : `${min} a `;
    case QuoteAttributeTypes.TextArray:
      return (value as AttributesTextValue[]).map(item => item.label).join(', ');
    case QuoteAttributeTypes.Boolean:
      return value as boolean;
    case QuoteAttributeTypes.Date:
      return value ? dateParser({ unformattedDate: value.toString(), hideHours: true, utc: true }) : '';
    case QuoteAttributeTypes.Object:
      return value ? { name: (value as AttributesObjectItem).name, url: (value as AttributesObjectItem).url } : '';
    default:
      if (((value as AttributesTextValue).label as ClientValueAttributeLabel).NAME) {
        return ((value as AttributesTextValue).label as ClientValueAttributeLabel).NAME;
      }
      return (value as AttributesTextValue).label as string;
  }
};

const mapAttributesUnit = (attr: AttributesResponse) => {
  if (attr?.unit) {
    return attr.type === QuoteAttributeTypes.Range
      ? (attr.unit.value as AttributesRangeUnit).min
      : (attr.unit.value as string);
  } else {
    return null;
  }
};

export const mapEditConversationParams = (data: EditConversationData): EditConversationParams => {
  const params: EditConversationParams = {
    conversation_id: data.conversationId,
    type: data.type,
  };

  switch (data.type) {
    case QuoteAttributeTypes.Options:
      params.value = { key: data.value.toString() };
      break;
    case QuoteAttributeTypes.TextArray:
      params.value = data.value.split(',');
      break;
    case QuoteAttributeTypes.Range:
      params.value = { min: +data.value.split(' -')[0], max: +data.value.split('- ')[1] };
      params.unit = { min: data.unit, max: data.unit };
      break;
    case QuoteAttributeTypes.Table:
      params.value = { rows: data.value };
      break;
    case QuoteAttributeTypes.Date:
      params.value = format(new Date(data.value), 'yyyy/MM/dd');
      break;
    default:
      if (data.unit) {
        params.unit = data.unit;
      }
      params.value = data.value.toString();
  }
  return params;
};

//, key: string, value: any, unit?: string)

function formatValues(type: string, key: string, value: any, unit?: string) {
  const arrayKeys = [];

  switch (type) {
    case 'range':
      return `${value.min ? value.min : '-'} a ${value.max ? value.max : '-'} ${unit || ''}`;
    case 'table':
      if (
        key === 'CHEMICAL_COMPOSITION' ||
        key === 'MICRO_INCLUSION' ||
        key === 'HARDENABILITY' ||
        key === 'MACRO_ETCH'
      ) {
        return value;
      }
      break;
    case 'textArray':
      value.forEach(item => {
        arrayKeys.push(item.key);
      });
      return arrayKeys;
    case 'object':
      return value.url;
    case 'date':
      return format(addDays(new Date(value), 1), 'yyyy-MM-dd HH:ii');
    case 'number':
      return `${value} ${unit || ''}`;
    case 'text':
      return value.label;
    default:
      return value.label;
  }
}

const attributesKeysPermission = [
  'CLIENT',
  'CLIENT_CODE',
  'CORPORATE_CODE',
  'FLOW',
  'CHECK_FILE',
  'PRODUCT',
  'AUSTENITE_GRAIN_SIZE',
  'STEEL_NAME',
  'GERDAU_PROCESS',
  'HEAT_TREATMENT',
  'REDUCTION_RATE',
  'SPECIFIC_SPECIFICATION',
  'SPECIFICATION_DATE',
  'SPECIFICATION_REVISION',
  'SPECIFIC_SPECIFICATION_NAME',
  'SHAPE',
  'HARDNESS',
  'HARDENABILITY',
  'CHEMICAL_COMPOSITION',
  'LENGTH',
  'MICRO_INCLUSION',
  'COLOR',
  'PLANTS_REVIEW',
  'SURFACE_FINISH',
  'BENDING',
  'FINAL_APPLICATION',
  'CASTING',
  'MACRO_ETCH',
  'IDEAL_CRITICAL_DIAMETER',
  'DIAMETER',
];

export const mapNluForm = res => {
  return res.map((item: NluFormMap) =>
    item.key && attributesKeysPermission.includes(item.key)
      ? {
          key: item.key,
          type: item.type,
          visible: item.permissions.isVisible,
          creation: item.creation.origin,
          label: item.label,
          unit: item.unit?.value,
          value: formatValues(item.type, item.key, item.value, item.unit?.value),
        }
      : null,
  );
};

export const chatFlow = {
  pt: {
    flow: [
      {
        key: 'RECURRENT_PRODUCT',
        fields: [
          {
            label: 'Data da solicitação do cliente',
            typeField: 'date',
            question: 'Qual a data que o cliente te enviou os dados desta consulta ?',
            detail: 'Considerar a data que o cliente te contatou informando os dados para cotação.',
            key: 'WHEN_CLIENT_DATA',
            mandatory: true,
            display: true,
          },
          {
            label: 'Revisão de Norma',
            question: 'Qual o tipo de norma para esta revisão?',
            typeField: 'radioButton',
            key: 'STANDARD',
            mandatory: true,
            display: true,
            options: [
              {
                label: 'REVISÃO DE NORMA ESPECÍFICA',
                key: 'SPECIFIC_SPECIFICATION',
              },
              {
                label: 'REVISÃO DE NORMA GERAL',
                key: 'GENERAL_SPECIFICATION',
              },
            ],
          },
          {
            question: 'Deseja anexar arquivo?',
            typeField: 'radioButton',
            key: 'CHECK_FILE',
            display: true,
            mandatory: true,
            conditionalField: true,
            options: [
              {
                label: 'Sim',
                key: 'YES',
              },
              {
                label: 'Não',
                key: 'NO',
              },
            ],
          },
          {
            label: 'Norma anexada',
            typeField: 'file',
            question: 'Por favor, submeta a norma técnica específica em PDF para eu analisar.',
            key: 'SPECIFIC_SPECIFICATION_NAME',
            display: false,
            conditional: {
              key: 'CHECK_FILE',
              conditionalValue: 'YES',
            },
          },
          {
            label: 'Aço',
            typeField: 'text',
            question: 'Qual o nome do aço?',
            key: 'STEEL_NAME',
            display: false,
            mandatory: true,
            conditional: {
              key: 'CHEMICAL_COMPOSITION',
              conditionalValue: 'NO',
            },
          },
          {
            label: 'Norma',
            typeField: 'text',
            question: 'Qual é a norma?',
            key: 'SPECIFIC_SPECIFICATION',
            display: false,
            mandatory: true,
            conditional: {
              key: 'CHECK_FILE',
              conditionalValue: 'NO',
            },
          },
          {
            label: 'Revisão da norma',
            typeField: 'text',
            question: 'Identifique a revisão dessa norma:',
            key: 'SPECIFICATION_REVISION',
            display: false,
            mandatory: true,
            conditional: {
              key: 'CHECK_FILE',
              conditionalValue: 'NO',
            },
          },
          {
            label: 'Data da norma',
            typeField: 'date',
            question: 'Qual é a data de revisão da norma?',
            detail:
              'Atenção para não confundir com a data de emissão! A data de revisão é a data mais recente no documento ou data da assinatura.',
            key: 'SPECIFICATION_DATE',
            display: false,
            mandatory: true,
            conditional: {
              key: 'CHECK_FILE',
              conditionalValue: 'NO',
            },
          },
          {
            label: 'Produto',
            typeField: 'checkbox',
            question: 'Qual o tipo de produto?',
            detail: 'Você pode clicar em mais de uma opção.',
            key: 'PRODUCT',
            display: false,
            mandatory: true,
            conditional: {
              key: 'CHECK_FILE',
              conditionalValue: 'NO',
            },
            options: [
              {
                label: 'Arame',
                key: 'ARAME',
              },
              {
                label: 'Barra',
                key: 'BARRA',
              },
              {
                label: 'Bloco',
                key: 'BLOCO',
              },
              {
                label: 'Fio-Máquina',
                key: 'FIO_MAQUINA',
              },
              {
                label: 'Lingote',
                key: 'LINGOTE',
              },
              {
                label: 'Tarugo',
                key: 'TARUGO',
              },
            ],
          },
        ],
      },
    ],
  },
  in: {
    flow: [
      {
        key: 'RECURRENT_PRODUCT',
        fields: [
          {
            label: 'Sending quote data (Customer)',
            typeField: 'date',
            question: 'What is the date the client sent you the data for this query?',
            detail: 'Consider the date that the customer contacted you providing the quote information.',
            key: 'WHEN_CLIENT_DATA',
            mandatory: true,
            display: true,
          },
          {
            label: 'Standard review',
            question: 'What is the type of standard for this review?',
            typeField: 'radioButton',
            key: 'STANDARD',
            mandatory: true,
            display: true,
            options: [
              {
                label: 'REVISION OF SPECIFIC STANDARD',
                key: 'SPECIFIC_SPECIFICATION',
              },
              {
                label: 'GENERAL STANDARD REVIEW',
                key: 'GENERAL_SPECIFICATION',
              },
            ],
          },
          {
            question: 'Do you want to attach file?',
            typeField: 'radioButton',
            key: 'CHECK_FILE',
            display: true,
            mandatory: true,
            conditionalField: true,
            options: [
              {
                label: 'Yes',
                key: 'YES',
              },
              {
                label: 'No',
                key: 'NO',
              },
            ],
          },
          {
            label: 'Attached standard',
            typeField: 'file',
            question: 'Please submit the document in PDF for me to review.',
            key: 'SPECIFIC_SPECIFICATION_NAME',
            display: false,
            conditional: {
              key: 'CHECK_FILE',
              conditionalValue: 'YES',
            },
          },
          {
            label: 'Steel name',
            typeField: 'text',
            question: 'What is the name of the steel?',
            key: 'STEEL_NAME',
            display: false,
            mandatory: true,
            conditional: {
              key: 'CHEMICAL_COMPOSITION',
              conditionalValue: 'NO',
            },
          },
          {
            label: 'Standard',
            typeField: 'text',
            question: 'What is the standard?',
            key: 'SPECIFIC_SPECIFICATION',
            display: false,
            mandatory: true,
            conditional: {
              key: 'CHECK_FILE',
              conditionalValue: 'NO',
            },
          },
          {
            label: 'Standard review',
            typeField: 'text',
            question: 'Indicate the revision of the standard:',
            key: 'SPECIFICATION_REVISION',
            display: false,
            mandatory: true,
            conditional: {
              key: 'CHECK_FILE',
              conditionalValue: 'NO',
            },
          },
          {
            label: 'Standard date',
            typeField: 'date',
            question: 'What is the revision date of the standard?',
            detail:
              'Be careful not to confuse it with the issue date! The revision date is the latest date on the document or date of signature.',
            key: 'SPECIFICATION_DATE',
            display: false,
            mandatory: true,
            conditional: {
              key: 'CHECK_FILE',
              conditionalValue: 'NO',
            },
          },
          {
            label: 'Product',
            typeField: 'checkbox',
            question: 'What type of product?',
            detail: 'You can click on more than one option.',
            key: 'PRODUCT',
            display: false,
            mandatory: true,
            conditional: {
              key: 'CHECK_FILE',
              conditionalValue: 'NO',
            },
            options: [
              {
                label: 'Wire',
                key: 'ARAME',
              },
              {
                label: 'Bar',
                key: 'BARRA',
              },
              {
                label: 'Bloom',
                key: 'BLOCO',
              },
              {
                label: 'Wire rod',
                key: 'FIO_MAQUINA',
              },
              {
                label: 'Ingot',
                key: 'LINGOTE',
              },
              {
                label: 'Billet',
                key: 'TARUGO',
              },
            ],
          },
        ],
      },
    ],
  },
  es: {
    flow: [
      {
        key: 'RECURRENT_PRODUCT',
        fields: [
          {
            label: 'Envío de datos de cotización (Cliente)',
            typeField: 'date',
            question: '¿Cuál es la fecha en que el cliente le envió los datos para esta consulta?',
            detail:
              'Considere la fecha en que el cliente se comunicó con usted proporcionándole la información de la cotización.',
            key: 'WHEN_CLIENT_DATA',
            mandatory: true,
            display: true,
          },
          {
            label: 'Revisión de estándar',
            question: '¿Cuál es el tipo de estándar para esta revisión?',
            typeField: 'radioButton',
            key: 'STANDARD',
            mandatory: true,
            display: true,
            options: [
              {
                label: 'REVISIÓN DE NORMA ESPECÍFICA',
                key: 'SPECIFIC_SPECIFICATION',
              },
              {
                label: 'REVISIÓN ESTÁNDAR GENERAL',
                key: 'GENERAL_SPECIFICATION',
              },
            ],
          },
          {
            question: '¿Quieres adjuntar documento?',
            typeField: 'radioButton',
            key: 'CHECK_FILE',
            display: true,
            mandatory: true,
            conditionalField: true,
            options: [
              {
                label: 'Sí',
                key: 'YES',
              },
              {
                label: 'No',
                key: 'NO',
              },
            ],
          },
          {
            label: 'Estándar adjunta',
            typeField: 'file',
            question: 'Envíe el documento en PDF para que lo revise.',
            key: 'SPECIFIC_SPECIFICATION_NAME',
            display: false,
            conditional: {
              key: 'CHECK_FILE',
              conditionalValue: 'YES',
            },
          },
          {
            label: 'Acero',
            typeField: 'text',
            question: '¿Cómo se llama el acero?',
            key: 'STEEL_NAME',
            display: false,
            mandatory: true,
            conditional: {
              key: 'CHEMICAL_COMPOSITION',
              conditionalValue: 'NO',
            },
          },
          {
            label: 'Estándar',
            typeField: 'text',
            question: '¿Cuál es la estándar?',
            key: 'SPECIFIC_SPECIFICATION',
            display: false,
            mandatory: true,
            conditional: {
              key: 'CHECK_FILE',
              conditionalValue: 'NO',
            },
          },
          {
            label: 'Revisión de estándar',
            typeField: 'text',
            question: 'Indique la revisión del documento (si corresponde) o ingrese no aplicable:',
            key: 'SPECIFICATION_REVISION',
            display: false,
            mandatory: true,
            conditional: {
              key: 'CHECK_FILE',
              conditionalValue: 'NO',
            },
          },
          {
            label: 'Especificación Fecha',
            typeField: 'date',
            question: '¿Cuál es la fecha de revisión del documento?',
            detail:
              '¡Tenga cuidado de no confundirlo con la fecha de emisión! La fecha de revisión es la última fecha del documento o la fecha de la firma.',
            key: 'SPECIFICATION_DATE',
            display: false,
            mandatory: true,
            conditional: {
              key: 'CHECK_FILE',
              conditionalValue: 'NO',
            },
          },
          {
            label: 'Producto',
            typeField: 'checkbox',
            question: '¿Cuál es el tipo de producto?',
            detail: 'Puede hacer clic en más de una opción.',
            key: 'PRODUCT',
            display: false,
            mandatory: true,
            conditional: {
              key: 'CHECK_FILE',
              conditionalValue: 'NO',
            },
            options: [
              {
                label: 'Alambre',
                key: 'ARAME',
              },
              {
                label: 'Barra',
                key: 'BARRA',
              },
              {
                label: 'Bloco',
                key: 'BLOCO',
              },
              {
                label: 'Alambróm en rollo',
                key: 'FIO_MAQUINA',
              },
              {
                label: 'Lingote',
                key: 'LINGOTE',
              },
              {
                label: 'Palanquilla',
                key: 'TARUGO',
              },
            ],
          },
        ],
      },
    ],
  },
};
