import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { endpoint, CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from '@app/data/http';
import { EditSequence } from '@app/data/http/quote-plant-params.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export const editAttributes = async (params?: EditSequence) => {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    client_id: CXP_KEY_CLIENT_ID,
    client_secret: CXP_KEY_CLIENT_SECRET,
    Authorization: 'Bearer ' + user.accessToken,
    access_token: user.accessToken,
    'Content-Type': 'application/json',
    Accept: '*/*',
    language: params.language,
    'sso-access': user.ssoAccess,
  };

  const data = { attributes_sequence: params.attributesSequence };

  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
    endpoint.ANALYSIS_PLANT
  }`;

  const { data: dataResponse } = await axios.patch(url, data, { headers: defaultHeaders });

  return dataResponse;
};

export const useEditAttributesSequence = (onSuccess?, onError?) => {
  const queryClient = useQueryClient();
  return useMutation(editAttributes, {
    onSuccess,
    onError,
    onMutate: async data => {
      await queryClient.cancelQueries(['getTechnicalAnalysis']);
      const previousData = queryClient.getQueriesData(['getTechnicalAnalysis']);

      queryClient.setQueriesData(['getTechnicalAnalysis'], (oldQueryData: any) => {
        oldQueryData.plants.map(plant => {
          const { attributes } = plant;

          const attrNewOrder = attributes.map(attr => {
            const newSequence = data.attributesSequence.find(el => el.key === attr.key);

            if (newSequence) {
              attr.sequence = newSequence.sequence;
            }

            return attr;
          });
          attrNewOrder.sort((a, b) => a.sequence - b.sequence);

          plant.attributes = attrNewOrder;

          return plant;
        });

        return {
          ...oldQueryData,
        };
      });
      return {
        previousData,
      };
    },
  });
};
