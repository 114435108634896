import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { QuoteStrings } from '@app/modules/quote/quote.string';
import { H4, InputLabel } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { rangeLimits } from '../../chat.contants';
import { ChatResultAttributeWrapper } from './chat-result.component.style';

interface CompositionItems {
  element: string;
  value: {
    min?: any;
    max?: any;
  };
  unit: string;
}
interface CompositionTableProps {
  attrKey: string;
  attribute: {
    type: string;
    creation: string;
    visible: boolean;
    label: string;
    value: CompositionItems[];
  };
}

export const CompositionTable: React.FC<CompositionTableProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const formatStringMaxValue = (val: string) => {
    if (val) {
      const newMask = val.toString().replace(rangeLimits.maxString, '-');
      return newMask;
    }
    return val;
  };
  const formatStringMinValue = (val: number) => {
    if (val === 0) {
      const newMask = val.toString().replace(rangeLimits.minString, '-');
      return newMask;
    }
    return val;
  };

  return props.attribute?.visible ? (
    <ChatResultAttributeWrapper
      data-testid={`analysisResume${props.attrKey}`}
      modified={props.attribute?.creation === 'NLU'}
    >
      <Hbox.Item>
        <InputLabel>
          {props.attribute?.label}{' '}
          {props.attribute?.creation === 'NLU' && (
            <span>{` ${QuoteStrings[userInfo.language].defaultMessageGerdau.chatResultNormAdvice}`}</span>
          )}
        </InputLabel>
      </Hbox.Item>
      <Table>
        <TR>
          <TD />
          <TD>
            <H4>Min</H4>
          </TD>
          <TD>
            <H4>Max</H4>
          </TD>
          <TD>
            <H4>Unit</H4>
          </TD>
        </TR>
        {props.attribute.value.map(item => (
          <TR key={item.element}>
            <TD>
              <H4>{item.element}</H4>
            </TD>
            <TD>{formatStringMinValue(item.value.min)}</TD>
            <TD>{formatStringMaxValue(item.value.max)}</TD>
            <TD>{item.unit}</TD>
          </TR>
        ))}
      </Table>
      <VSeparator />
    </ChatResultAttributeWrapper>
  ) : (
    <></>
  );
};
