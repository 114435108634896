import { Language } from '@app/models';
import { IAuthState } from '../auth.interfaces';

export const initialState: IAuthState = {
  token: '',
  tokenType: '',
  tokenExpiresIn: 0,
  refreshToken: '',
  applications: '',
  userInfo: { language: Language.por },
  loading: false,
  loadingProfile: false,
  loadingValidateToken: false,
  forgotPasswordLoading: false,
  forgotPasswordData: null,
  ssoAccess: false,
  isActive: false,
};
