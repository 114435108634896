export const SLICE_NAME = 'preventive-stop-modal';

export const bpsME = ['0100121451', '0100058722', '0100046630', '0100021834', '0150204546'];

export const scheduledDays = [
  { startDate: new Date('2023-10-18T00:00:00'), endDate: new Date('2023-10-18T23:59:59') },
  { startDate: new Date('2023-10-25T00:00:00'), endDate: new Date('2023-10-25T23:59:59') },
  { startDate: new Date('2023-11-01T00:00:00'), endDate: new Date('2023-11-01T23:59:59') },
  { startDate: new Date('2023-11-08T00:00:00'), endDate: new Date('2023-11-08T23:59:59') },
];

export const warningScheduledDays = [
  { startDate: new Date('2023-12-04T00:00:00'), endDate: new Date('2023-12-04T23:59:59') },
  { startDate: new Date('2023-12-11T00:00:00'), endDate: new Date('2023-12-11T23:59:59') },
  { startDate: new Date('2023-12-18T00:00:00'), endDate: new Date('2023-12-18T23:59:59') },
  { startDate: new Date('2023-12-25T00:00:00'), endDate: new Date('2023-12-25T23:59:59') },
  { startDate: new Date('2024-01-01T00:00:00'), endDate: new Date('2024-01-01T23:59:59') },
  { startDate: new Date('2024-01-08T00:00:00'), endDate: new Date('2024-01-08T23:59:59') },
  { startDate: new Date('2024-01-15T00:00:00'), endDate: new Date('2024-01-15T23:59:59') },
  { startDate: new Date('2024-01-22T00:00:00'), endDate: new Date('2024-01-22T23:59:59') },
  { startDate: new Date('2024-01-29T00:00:00'), endDate: new Date('2024-01-29T23:59:59') },
  { startDate: new Date('2024-02-05T00:00:00'), endDate: new Date('2024-02-05T23:59:59') },
  { startDate: new Date('2024-02-12T00:00:00'), endDate: new Date('2024-02-12T23:59:59') },
];
