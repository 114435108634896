import Container from 'typedi';
import Cookies from 'universal-cookie';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { IAuthState } from '../../auth.interfaces';
import { buildUserData } from './initialize-and-get-profile.thunk';
import { refreshTokenRequest } from './refresh-token.thunk';

const authStorageService: AuthStorageService = Container.get(AuthStorageService);

export const refreshAndUpdateTokenThunk = (refreshToken: string) => async (dispatch, getState) => {
  await dispatch(refreshTokenRequest(refreshToken));
  const { token, userInfo, ssoAccess }: IAuthState = getState().auth;
  const cookies = new Cookies();
  cookies.set('@access_token', token, { domain: '.egerdau.com.br' });
  authStorageService.login(buildUserData(token, userInfo, ssoAccess));
  return;
};
