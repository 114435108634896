import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { endpointCrm, defaultCrmHeaders } from '@app/data/http';
import {
  CrmManufactoringConsultationResponse,
  GetCrmParams,
  mapManufacturingConsultationData,
} from '@app/data/http/crm.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export async function getManufacturingConsultation(
  params?: GetCrmParams,
): Promise<CrmManufactoringConsultationResponse> {
  const user = Container.get(AuthStorageService).getUser();
  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpointCrm.CRM}/${params.id}${
    endpointCrm.MANUFACTURING_CONSULTATION
  }`;

  const { data, status } = await axios.get(url, { headers: { ...defaultCrmHeaders, 'sso-access': user.ssoAccess } });

  if (status === 200) {
    return data;
  } else {
    return null;
  }
}

export function useGetManufacturingConsultation(params: GetCrmParams, onError?) {
  return useQuery(['getManufacturingConsultation', params], () => getManufacturingConsultation(params), {
    onError,
    enabled: params.enable,
    select: data => {
      return mapManufacturingConsultationData(data.data);
    },
    refetchOnWindowFocus: false,
  });
}
