import React, { useState } from 'react';
import Container from 'typedi';
import { useStore } from '@app/core/global-store.service';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { useChangePasswordRequest } from '@app/domain';
import { UserStore, useUserStore } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { Body, H2 } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { Col, Grid, Root, Row } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { ChangePasswordForm, ChangePasswordFormData } from './change-password-form.component';

const authStorageService: AuthStorageService = Container.get(AuthStorageService);

export const ChangePasswordPage: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const onSuccess = () => {
    setShowSuccessMessage(true);
  };

  const onClick = () => {
    authStorageService.logout();
  };
  const { performRequest, error, data, loading } = useChangePasswordRequest(onSuccess);

  const { user } = useStore<UserStore>(useUserStore);

  const handleSubmit = (formData: ChangePasswordFormData) => {
    setErrorMessage(null);
    if (formData.newPassword === formData.newPasswordConfirmation) {
      performRequest({
        oldPassword: formData.oldPassword,
        newPassword: formData.newPassword,
        uid: user.uid,
      });
    } else {
      setErrorMessage('Você deve digitar a mesma senha duas vezes para confirmá-la.');
    }
  };

  const successMessage = 'Senha alterada com sucesso!';

  return (
    <Root>
      <Grid fluid>
        <Row>
          <Col xs={12} md={5} lg={4}>
            <ChangePasswordForm onSubmit={handleSubmit} loading={loading} />
            {!!data && !error && <Body>{successMessage}</Body>}
            {(!!errorMessage || !!error) && (
              <Body color={Color.Alert}>{errorMessage || 'Ocorreu um erro, tente novamente mais tarde'}</Body>
            )}
          </Col>
        </Row>
      </Grid>

      <Modal small opened={showSuccessMessage} onClose={onClick}>
        <Grid fluid>
          <Row>
            <H2>Confirmação</H2>
          </Row>
          <Row>
            <Body>{successMessage}</Body>
          </Row>
          <Row mt mb>
            <FlexRow fullWidth hAlign='center'>
              <Button onClick={onClick}>OK</Button>
            </FlexRow>
          </Row>
        </Grid>
      </Modal>
    </Root>
  );
};
